import { FlexLayoutModule } from '@angular/flex-layout';
import { TreeViewComponent } from './tree-view/tree-view.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DemoMaterialModule } from './../../material-module';

import { MatTreeModule, MatInputModule } from '@angular/material';
import { TreePermissionComponent } from './tree-permission/tree-permission.component';
import { TreePickerComponent } from './tree-picker/tree-picker.component';
import { TreeRadioPickerComponent } from './tree-radio-picker/tree-radio-picker.component';

@NgModule({
    declarations: [
        TreeViewComponent,
        TreePickerComponent,
        TreePermissionComponent,
        TreeRadioPickerComponent
    ],
    imports:[
        CommonModule,
        DemoMaterialModule,
        FlexLayoutModule,
        MatTreeModule,
        MatInputModule
    ],
    exports: [
        TreeViewComponent,
        TreePickerComponent,
        TreePermissionComponent,
        TreeRadioPickerComponent,
    ]
})

export class PickersModule {}
