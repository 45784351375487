import { ConectorKnowService } from './../../../services/conector-know.service';
import { Pagination } from './../../shared/pagination';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estadisticas-activos',
  templateUrl: './estadisticas-activos.component.html',
  styleUrls: ['./estadisticas-activos.component.css']
})
export class EstadisticasActivosComponent implements OnInit {

  pagination = new Pagination();

  records = [];

  constructor(private know: ConectorKnowService) { }

  ngOnInit() {

    this.know.users_active().subscribe( x => {
      console.info(x);
      this.records = x.logs;
    })
  }

}
