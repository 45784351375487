import { Pagination } from './../../shared/pagination';
import { AuthenticationService } from './../../../services/authentication.service';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ModalChildrenComponent } from '../../manager/modals/modal-children/modal-children.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-relations',
  templateUrl: './relations.component.html',
  styleUrls: ['./relations.component.css']
})
export class RelationsComponent implements OnInit {

  pagination = new Pagination();
  isEditable = false;
  relationsForm: FormGroup;
  relations = [];
  length =  0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 15];
  selected = null;

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private service: AuthenticationService,
    private conector: ConectorJsonService,
    public dialog: MatDialog) {

      this.relationsForm = this.fb.group({
        relation: [null, Validators.required],
        description: [null],
        active: [true]
      });

      this.service.setLanguage().then( k => this.translate.setDefaultLang(k));

  }

  async ngOnInit() {
    this.conector.relations_get().subscribe( (res: any) => {
      const { ok, data, msg }  = res;
      this.length = msg;
      this.relations = data;
    });
  }

  updateRows($events) {

    const { pageIndex, pageSize } = $events;

    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.relations = [];

    this.conector.relations_get(this.pageIndex, this.pageSize).subscribe( (x: any) => {
      this.relations = x.data;
    });

  }

  onClick(formdata) {

    formdata.active = true;

    if (this.isEditable === false) {

      this.conector.relations_post(formdata).subscribe( async ({ ok, msg }: any) => {

        if(ok === true) {

          this.conector.relations_get().subscribe( (x: any) => {
            this.relations = x.data;
          });

          this.relationsForm.reset();

          const msg = await this.translate.get("success").toPromise();

          Swal.fire({ position: 'center', icon: 'success', title: msg, showConfirmButton: false, timer: 1000 });

        } else {

          Swal.fire('Error', msg, 'error');
        }


      });

    } else {

      Object.keys(formdata).forEach( key => {
        this.selected[key] = formdata[key];
      });

      this.conector.relations_put(this.selected).subscribe( async (d) => {

        this.relationsForm.reset();
        this.isEditable = false;

        const msg = await this.translate.get("success").toPromise();
        Swal.fire({ position: 'center', icon: 'success', title: msg, showConfirmButton: false, timer: 1000 });

      });

    }

  }

  editRow(row) {
    this.isEditable = true;
    this.selected = row;
    Object.keys(row).forEach(key => {
      if (this.relationsForm.get(key)) {
        this.relationsForm.controls[key].setValue(row[key]);
      }
    });
  }

  deleteRow(row) {
    this.conector.relations_delete(row.id).subscribe( (d: any) => {
      this.conector.relations_get(this.pageIndex, this.pageSize).subscribe( (x: any) => {
        this.relations = x.data;
      });
    });
  }

}
