import { AuthenticationService } from './../../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ConectorJsonService } from './../../../../services/conector-json.service';
import { Component, OnInit } from '@angular/core';
import { Validators, AbstractControl, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  userForm: FormGroup;

  constructor(
    private conector: ConectorJsonService,
    private fb: FormBuilder,
    private router: Router,
    public translate: TranslateService,
    private auth: AuthenticationService
    ) {

    this.userForm = this.fb.group({
      name: [null, Validators.required],
      firstname: [null, Validators.required],
      lastname: [null],
      phone: [null],
      require2AF: [false],
      email: [ null, [Validators.required, Validators.email]],
      password: [ null, Validators.required],
      repeat: [ null, Validators.required]
    }, {
      validators: this.passwordConfirming
    });

    this.auth.setLanguage().then( k => this.translate.setDefaultLang(k));

  }

  ngOnInit() {

  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('repeat').value) {
        return {invalid: true};
    }
  }

  submit(formdata) {

    formdata.role = 3;
    // formdata.id = 0;
    // formdata.company = 0;
    // formdata.active = true;
    // formdata.tycos = false;

    console.log(formdata);

    this.conector.users_post(formdata).subscribe( (d: any) => {
      const { ok, msg, data} = d;
      if (ok === true) {
        Swal.fire({
          title: 'Usuario registrado.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
          reverseButtons: true
        }).then((result) => {
          this.router.navigateByUrl('/manager/users');
        });
      } else {
        Swal.fire('Error', msg, 'error');
      }
    });

  }
}
