import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Chart } from 'angular-highcharts';

@Component({
    selector: 'app-survey-home',
    templateUrl: './survey-home.component.html',
    styleUrls: ['./survey-home.component.css']
  })
  export class SurveyHomeComponent implements OnInit, AfterViewInit {

    chartEncuestas : Chart;

    ngOnInit(): void {
        
    }

    async ngAfterViewInit() {

      this.chartEncuestas = new Chart({
        chart: { type: 'column' },
        title: { text: '' },
        xAxis: { type: 'category' },
        yAxis: { min: 0, title: { text: '# Encuestas' } },
        credits: { enabled: false },
        legend: { enabled: false },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        series: [{
            name: 'Asignadas', color: '#05AFF2',
            type: undefined,
            data: [{name:'P1', y:80}, {name:'P2', y:70}]
        }, {
            name: 'Contestadas', color: '#04D98B',
            type: undefined,
            data: [{name:'P1', y:70}, {name:'P2', y:65}]
        }]
      });

    }

  }