import { FormGroup, FormBuilder } from '@angular/forms';
import { ResponseKnow } from './../../../interfaces/ResponseHandler';
import { ConectorKnowService } from './../../../services/conector-know.service';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import FileSaver from 'file-saver';

@Component({
  selector: 'app-estadisticas-encuestas',
  templateUrl: './estadisticas-encuestas.component.html',
  styleUrls: ['./estadisticas-encuestas.component.css']
})
export class EstadisticasEncuestasComponent implements OnInit {

  areas = [];
  records = [];

  encuestasFrm: FormGroup;

  constructor(private know: ConectorKnowService, private fb: FormBuilder) {
    this.encuestasFrm = this.fb.group({
      area: null,
      inicio: null,
      final:  null
    });
  }

  ngOnInit() {
    this.know.areas_list().subscribe( (res:ResponseKnow) => {
      this.areas = res.structure;
    });

    this.encuestasFrm.valueChanges.subscribe( d => {
      if(d.inicio != null && d.final != null) {
        this.know.report_questions(this.encuestasFrm.value).subscribe( res => {
          console.info('Listening', res);
          this.records = res.questions;
        })
      }
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.info(changes);
  }

  onChangeArea(ev) {
    console.info(ev);
  }

  onClick(row) {

    console.info('Export', row);

    this.encuestasFrm.value.id = row.id;

    this.know.exportar_encuestas(this.encuestasFrm.value).then( rs => {
      FileSaver( rs, 'reporte.xlsx');
    });

  }

}
