import { Component, OnInit, Inject } from '@angular/core';
import Swal from 'sweetalert2';
import { ConectorJsonService } from '../../../../services/conector-json.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-group-handlers',
  templateUrl: './group-handlers.component.html',
  styleUrls: ['./group-handlers.component.css']
})
export class GroupHandlersComponent implements OnInit {

  category = null;
  questions = [];
  records = [];
  option = 0;
  question = null;
  constructor(private conector: ConectorJsonService,  @Inject(MAT_DIALOG_DATA) public data) {
    if ( data.row == null) {

    } else {
      localStorage.setItem('questions', JSON.stringify(data.row));
      this.category = data.row;
    }
  }

  ngOnInit() {

    this.category = JSON.parse(localStorage.getItem('questions'));

    this.conector.handlers_get().subscribe( (d: any) => {
      const {ok, msg, data} = d;
      this.questions = data;
    });

    this.reset();

  }

  select(row) {
    this.option = row;
  }

  onClick() {

    const model = {
      group: this.category.id,
      handler: this.option
    };

    this.conector.group_handlers_post(model).subscribe( (d: any) => {

      const { ok, msg, data } = d;

      if (ok === false) {
        Swal.fire('Error', msg, 'info');
      } else {
        this.option = null;
        this.reset();
      }

    });

  }

  reset() {
    console.info('c&a', this.category);
    this.conector.groups_handlers_get(this.category.id).subscribe( (d: any) => {
      const {ok, msg, data} = d;
      this.records = data;
    });
  }

  delRow(row) {

    Swal.fire({
      title: 'Confirmar Eliminación',
      text: 'Esta acción es irreversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceder',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.conector.group_handlers_del(row.id).subscribe( (d: any) => {
          console.info('Del&', d);
          this.reset();
        });
      }
    });
    console.info('op&', row);
  }

}
