import { Subscription, interval } from 'rxjs';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  @Input() question: any = {};
  @Output() save: EventEmitter<any> = new EventEmitter()
  @Input() hasTimer: Boolean = false;

  private subscription: Subscription;
  isOther = false;
  timer: Date;
  passed = 0;
  percent = 0;
  color = "primary";
  options = {
    idpregunta: null,
    idrespuesta: null,
    text: null
  }

  constructor() { }

  ngOnInit() {

  }

  initialize() {

  }

  ngOnChanges(changes: SimpleChanges): void {

    if( changes.question.currentValue != null) {

      if( changes.question.currentValue.addTimer == true || changes.question.currentValue.addTimer == "true") {

        this.set_timer(changes.question.currentValue.timeLimit);

      }
    }

  }

  set_timer(ev) {

    if( ev == 0 ){
      return;
    }

    this.timer = new Date(moment().add(ev, 'seconds').toDate());

    this.subscription = interval(1000).subscribe (x => {

      if(x >= ev ) return;

      this.passed = Number( Number( (x + 1)  / ev * 100).toFixed(0) );

      if( this.passed < 30 ) {
        this.color = "ok-bar";
      }

      if( this.passed >= 30 && this.passed < 59 ) {
        this.color = "warn-bar";
      }

      if( this.passed >= 60 ) {
        this.color = "danger-bar";
      }

    });

  }

  validateOther(ev) {

    const selected = this.question.answers.find( x => x.id == ev.value);

    this.isOther = selected.name.includes('Otro') ? true : false;

  }

  saveAction() {

    // Reset
    this.passed = 0;
    this.color = "ok-bar";

    if(this.subscription) {
      this.subscription.unsubscribe();
    }

    // Validate date
    this.options.idrespuesta = (this.question.type == 2) ? moment(this.options.idrespuesta).format("YYYY-MM-DD").toString() : this.options.idrespuesta;

    // Valida si trae 'otro'
    this.options.idrespuesta = (this.isOther == true) ? this.options.idrespuesta + "|" + this.options.text : this.options.idrespuesta;

    // Assigne question ID
    this.options.idpregunta = this.question.id;

    // Send to parent
    this.save.emit({ idpregunta: this.options.idpregunta, idrespuesta: this.options.idrespuesta });

    // Reset variables
    this.options = { idpregunta: null, idrespuesta: null, text: null }
    this.question = {};
    this.isOther = false;

  }

  execute() {
    if(this.question.addTimer == true && this.question.timeLimit > 0) {
      this.saveAction();
    }
  }

}
