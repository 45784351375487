import { ConectorJsonService } from './../../../../services/conector-json.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import {Md5} from 'md5-typescript';
import Swal from 'sweetalert2';
import { GenericCatalogsService } from '../../../../services/GenericCatalogs.service';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {

  eform: FormGroup;
  activeList: [];
  r2afList: [];

  constructor(
    private conector: ConectorJsonService,
    private generic: GenericCatalogsService, 
    private fb: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data) {
    
  }

  ngOnInit() {

    this.eform = this.fb.group({
      id: [this.data.id, Validators.required],
      name: [this.data.name, Validators.required],
      firstname: [this.data.firstname, Validators.required],
      lastname: [this.data.lastname, Validators.required],
      phone: [this.data.phone, [Validators.required]],
      active: [this.data.active, Validators.required],
      require2AF: [this.data.require2AF, Validators.required],
      email: [ this.data.email, [Validators.required, Validators.email]],
    });

    this.generic.ct_SiNo().subscribe((response: any)=> {
      const {ok, data, msg} = response;
      this.activeList = data;
      this.r2afList = data;
    });
  }

  submit(formdata) {

    this.conector.users_put(formdata).subscribe( (d: any) => {

      Swal.fire({
        title: 'Actualización correcta.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      }).then((result) => this.dialog.closeAll() );

    });

  }

  set() {
    this.eform.controls['active'].setValue(true);
  }

}
