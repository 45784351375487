import { Pagination } from '../../../models/pagination';
import { ConnectorJobProfileService } from '../../../services/job-profile.service';
import { MatDialog } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { CreateJobProfileComponent } from '../modals/create-jobprofile/create-jobprofile.component';
import { ConfigurateJobProfileComponent } from '../modals/configurate-jobprofile/configurate-jobprofile.component';
import { JobProfileAnalisisRiesgoComponent } from '../modals/jobprofile-analisisriesgo/jobprofile-analisisriesgo.component';
import { Router } from '@angular/router';
import { JobprofileSurveysComponent } from '../modals/jobprofile-surveys/jobprofile-surveys.component';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-jobprofile',
  templateUrl: './jobprofile.component.html',
  styleUrls: ['./jobprofile.component.css']
})
export class JobProfileComponent implements OnInit {

  selectedIndex = 0;
  profiles = [];
  pagination = new Pagination();
  jobSubmodules = [];
  jobSelected = { id: 0 }

  displayedColumns: string[] = ['puesto', 'nivel', 'tieneseleccion', 'config', 'code', 'edit', 'delete'];

  @ViewChild(JobprofileSurveysComponent, { static: true }) jpc: JobprofileSurveysComponent;

  constructor(private conector: ConnectorJobProfileService, private route: Router, public dialog: MatDialog) {
    this.pagination.length = 10;
  }

  ngOnInit(): void {
    this.conector.jobprofile_get(this.pagination.init, this.pagination.limit).subscribe((response: any) => {
      const { data, msg } = response;
      this.profiles = data;
      this.pagination.length = msg;
    });
  }

  changePage($event) {

    this.pagination.length = 0;
    this.profiles = [];

    this.pagination.changePage($event);

    this.conector.jobprofile_get(this.pagination.init, this.pagination.limit).subscribe((d: any) => {
      const { msg, data } = d;
      this.pagination.length = msg;
      this.profiles = data;
    });
  }

  AddJobProfile(row) {

    row.token = row.token == null ? uuid() : row.token;

    row.analisisRiesgo = row.analisisRiesgo == null ? false : row.analisisRiesgo;

    const dialogRef = this.dialog.open(CreateJobProfileComponent, { data: row, width: '800px' });

    dialogRef.afterClosed().subscribe(() => {
      this.conector.jobprofile_get().subscribe((response: any) => {
        const { data } = response;
        this.profiles = data;
      });
    });

  }

  ConfigJobProfile(row) {
    this.dialog.open(ConfigurateJobProfileComponent, { data: row, width: '900px' });
  }

  ConfigJobPerfiles(e) {
    this.route.navigateByUrl('/manager/config-profiles');
  }

  JobProfileAnalisisRiesgo(row) {
    this.dialog.open(JobProfileAnalisisRiesgoComponent, { data: row, width: '900px' });
  }

  EraseJobProfile(row) {
    Swal.fire({
      title: 'Advertencia',
      icon: 'warning',
      text: 'Esta acción es irreversible',
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
    }).then((d: any) => {
      if (d.value === true) {
        this.conector.jobprofile_delete(row.id).subscribe((x: any) => {
          const { ok, msg } = x;
          if (ok === true) {
            Swal.fire('Exito', 'Eliminación realizada.', 'success');
            this.conector.jobprofile_get().subscribe((response: any) => {
              this.profiles = response.data;
            });
          } else {
            Swal.fire('Error', msg, 'error');
          }
        });
      }
    });
  }

  showDirectCode(e) {

    var protocol = window.location.port ?  `:${window.location.port}` : '';

    if(e.token){

      const linkTo= `${window.location.protocol}//${window.location.hostname}${protocol}/to-do/${e.token}`;

      Swal.fire({
        title: 'Enlace directo',
        icon: 'info',
        html: `<div style="font-size:12px">Comparte este enlace para acceder directamente a la evaluación <br/> ${linkTo}</div>`
      });

    } else {
      Swal.fire('Error', 'Aun no cuentas con un token, generarlo para obtener un link', 'error');
    }

  }

  configJobSurveys(row) {
    this.jobSelected = row;
    this.conector.get_job_surveys({ modulo: localStorage.getItem('module'), puesto: row.id}).subscribe ( (e:any) => {
      this.jobSubmodules = e.data;
      this.jpc.openModal = true;
    });
  }

  updateEvaluations(e) {

    //Asignar puesto
    e.forEach( x => x.puesto = this.jobSelected.id);

    //Actualizar
    this.conector.put_job_surveys(e).subscribe( (k: any) => {
      this.jobSubmodules = [];
      this.jpc.openModal = false;
    });

  }

}