import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
  render: string;
  permission: Array<any>;
}

const MENUITEMS = [
  {
    state: 'home',
    name: 'Home',
    type: 'link',
    render: 'material',
    icon: 'dashboard',
    permission: []
  }
];

@Injectable()
export class SupervisorItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}

