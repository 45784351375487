import { Pagination } from './../../../models/pagination';
import { ConnectorCollaboratorService } from '../../../services/Collaborator/conector-collaborator.service';
import { CreateCollaboratorComponent } from '../../general/create-collaborator/create-collaborator.component';
import { SubmoduleCollaboratorComponent } from '../modals/submodule-collaborator/submodule-collaborator.component';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatTabChangeEvent, MatDialog, MatPaginator } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { CreateManyCollaboratorComponent } from '../modals/create-many-collaborator/create-many-collaborator.component';
import { ImportCollaboratorComponent } from '../modals/import-collaborator/import-collaborator.component';
import { Router } from '@angular/router';
import { ConnectorJobProfileService } from '../../../services/job-profile.service';

@Component({
  selector: 'app-collaborator',
  templateUrl: './collaborator.component.html',
  styleUrls: ['./collaborator.component.css']
})
export class CollaboratorComponent implements OnInit {

  selectedIndex = 0;
  collaborators = [];
  jobProfileList = [];
  pagination = new Pagination();
  collaboratorForm: FormGroup;

  displayedColumns: string[] = ['nickname', 'name', 'firstname', 'lastname', 'email', 'job', 'edit', 'asign', 'send', 'delete'];
  
  constructor(
    private conector: ConnectorCollaboratorService, 
    private job: ConnectorJobProfileService,
    public router: Router,
    private fb: FormBuilder, 
    public dialog: MatDialog) { 

    this.pagination.length = 10;
    this.pagination.pageSize = 10;
    this.pagination.limit = 10;
  }

  ngOnInit() {
        
    this.collaboratorForm = this.fb.group({
        jobProfile: [0],
        nickname: [null],
        name: [null],
        firstname: [null],
        lastname: [null],
      });

    this.conector.collaborator_get(this.collaboratorForm.value, this.pagination.init, this.pagination.limit).subscribe( (response: any) => {
      const {data, msg} = response;
      this.collaborators = data;
      this.pagination.length = msg;
      this.pagination.pageSize = 10;
      this.pagination.limit = 10;
    });

    this.job.jobprofile_all().subscribe((response: any)=> {
      const { ok, data, msg } = response;

      data.push({id: 0, label: "-Seleccionar puesto-"});
      data.sort(function(a, b) { return a.id - b.id; });

      this.jobProfileList = data;
    });

  }

  SearchCollaborators(formdata) {

    this.conector.collaborator_get(formdata, this.pagination.init, this.pagination.limit).subscribe( (response: any) => {
      const {data, msg} = response;
      this.collaborators = data;
      this.pagination.length = msg;
      this.pagination.pageSize = 10;
      this.pagination.limit = 10;
    });
  }

  changePage($event) {

    this.pagination.length = 0;
    this.collaborators = [];

    this.pagination.changePage($event);

    this.conector.collaborator_get(this.collaboratorForm.value, this.pagination.init, this.pagination.limit).subscribe( (d: any) => {
      const { msg, data} = d;
      this.pagination.length = msg;
      this.collaborators = data;
      this.pagination.pageSize = 10;
      this.pagination.limit = 10;
    });

  }

  SendEvaluation(row) {
    
    const dialogRef = this.dialog.open(SubmoduleCollaboratorComponent, { data : row, width : '600px' });

    dialogRef.afterClosed().subscribe(res => {
      this.conector.collaborator_get(this.collaboratorForm).subscribe( (response: any) => {
        const {ok, data, msg} = response;
        this.collaborators = data;
      });
    });
  }

  AsignEvaluation(row) {
    
  }

  AddCollaborator(row) {

    if (row.id == undefined) row.id = 0;

    this.router.navigateByUrl("/manager/collaborator/" + row.id);
  }

  AddManyCollaborators() {

    const dialogRef = this.dialog.open(CreateManyCollaboratorComponent, { data : null, width : '800px' });

    dialogRef.afterClosed().subscribe(res => {
      this.conector.collaborator_get(this.collaboratorForm).subscribe( (response: any) => {
        const {ok, data, msg} = response;
        this.collaborators = data;
        this.pagination.length = msg;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
    });

  }

  ImportCollaborators() {

    const dialogRef = this.dialog.open(ImportCollaboratorComponent, { data : null, width : '800px' });

    dialogRef.afterClosed().subscribe(res => {
      this.conector.collaborator_get(this.collaboratorForm).subscribe( (response: any) => {
        const {ok, data, msg} = response;
        this.pagination.length = msg;
        this.collaborators = data;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
    });

  }

  EraseCollaborator(row) {
    Swal.fire({
      title: 'Advertencia',
      icon: 'warning',
      text: 'Esta acción es irreversible',
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
    }).then( (d: any) => {
      if (d.value === true) {
        this.conector.collaborator_delete(row.id).subscribe( (x: any) => {
          const { ok, msg} = x;
          if (ok === true) {
            Swal.fire('Exito', 'Eliminación realizada.', 'success').then( (d: any) => {
              if (d.value === true) {
                this.conector.collaborator_get(this.collaboratorForm.value, this.pagination.init, this.pagination.limit).subscribe( (response: any) => {
                  const {ok, data, msg} = response;
                  this.collaborators = data;
                  this.pagination.length = msg;
                  this.pagination.pageSize = 10;
                  this.pagination.limit = 10;
                });
              }
            });
            
          } else {
            Swal.fire('Error', msg, 'error');
          }
        });
      }
    });
  }
}