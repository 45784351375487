import { ResponseHandler } from './../../../interfaces/ResponseHandler';
import { SharedCommunicationService } from './../../../services/shared-communication.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './../../../services/authentication.service';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { Pagination } from './../../../models/pagination';
import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-handler-home',
  templateUrl: './handler-home.component.html',
  styleUrls: ['./handler-home.component.css']
})
export class HandlerHomeComponent implements OnInit {

  pagination = new Pagination();
  dataSource: Object;
  chartist = [];
  records = [];

  height = 400;
  type = 'pie2d';
  dataFormat = 'json';

  datas = {
    chart: {
      caption: '',
      plottooltext: '',
      showlegend: '1',
      showpercentvalues: '',
      legendposition: 'right',
      usedataplotcolorforlabels: '',
      theme: 'fusion'
    },
    data: []
  };


  public chartStatus: any = {
    chart: {
      type: 'pie'
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    credits:{
      enabled: false
    },
    series: [{
      name: 'Brands',
      data: [{
        name: 1,
        y: 2
      }]
    }]
  };

  constructor(private conector: ConectorJsonService, private auth: AuthenticationService, private translate: TranslateService, private shared: SharedCommunicationService) {

    this.dataSource = this.datas;

    this.auth.setLanguage().then( k => this.translate.setDefaultLang(k) );

  }

  ngOnInit() {

    this.conector.handler_chart().subscribe( (d: ResponseHandler) => {

      const { ok, data, msg} = d;

      const res_translate = [];

      const series_mk = [];

      data.forEach( async (item) => {

        this.translate.get(item.label).subscribe( k => {
          item.label = k;
          res_translate.push(item);
        });

        this.translate.get(item.label).subscribe( px => {
          series_mk.push({ name: px, y: item.value });
        });

      });

      this.chartist = res_translate;
      this.chartStatus.series[0].data = series_mk;
      Highcharts.chart('status', this.chartStatus);

    });

    this.reload();
  }

  reload() {
    this.records = [];
    this.conector.handler_tickets(this.pagination.init, this.pagination.limit).subscribe( (d: any) => {
      const { ok, data, msg} = d;
      this.pagination.length = msg;
      this.records = data;
    });
  }

  changePage($event) {

    const { pageIndex, pageSize } = $event;

    this.pagination.pageSize = pageSize;
    this.pagination.init = pageIndex;
    this.records = [];

    this.conector.handler_tickets(this.pagination.init, this.pagination.pageSize).subscribe( (d: any) => {
      const { ok, data, msg} = d;
      this.pagination.length = msg;
      this.records = data;
    });

  }

  getHumanDate(row) {
    return this.shared.dateToString(row, true);
  }

}
