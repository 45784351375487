import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './../../../services/authentication.service';
import { Pagination } from './../../shared/pagination';
import { ModalChildrenComponent } from './../modals/modal-children/modal-children.component';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

  pagination = new Pagination();
  categoryForm: FormGroup;
  masterGroups = [];
  length =  0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 15];
  isEditable = false;
  selected = null;

  constructor(private fb: FormBuilder, private conector: ConectorJsonService, public dialog: MatDialog, private auth: AuthenticationService, private translate: TranslateService) {

    this.pagination.length = 10;

    this.categoryForm = this.fb.group({
      parent: [0, [Validators.required]],
      name: [null, Validators.required],
      description: [null],
      visible: [true, Validators.required]
    });

    this.auth.setLanguage().then( k => this.translate.setDefaultLang(k) );

  }

  async ngOnInit() {
    this.conector.groups_get(this.pagination.init, this.pagination.pageSize).subscribe( (res: any) => {
      const { ok, data, msg }  = res;
      this.pagination.length = msg;
      this.masterGroups = data;
    });
  }

  openDialog(row): void {
    const dialogRef = this.dialog.open(ModalChildrenComponent, {
      data: { row }
    });

    dialogRef.afterClosed().subscribe(res => {
      console.info('&modal&', res);
    });
  }

  updateRows($events) {
    const { pageIndex, pageSize } = $events;

    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.masterGroups = [];

    this.conector.groups_get(this.pageIndex, this.pageSize).subscribe( (x: any) => {
      this.masterGroups = x.data;
    });
  }

  onClick(formdata) {
    
    if (this.isEditable === false) {
      this.conector.groups_post(formdata).subscribe( (d: any) => {
        this.length++;
        this.categoryForm.reset();

        this.categoryForm.controls["parent"].setValue(0);
        this.categoryForm.controls["visible"].setValue(true);

        this.masterGroups.unshift(d.data);
        Swal.fire({ position: 'center', icon: 'success', title: 'Process completed', showConfirmButton: false, timer: 1000 });
      });
    } else {

      Object.keys(formdata).forEach( key => {
        this.selected[key] = formdata[key];
      });

      this.conector.groups_put(this.selected).subscribe( (d) => {
        this.categoryForm.reset();
        this.isEditable = false;
        Swal.fire({ position: 'center', icon: 'success', title: 'Process completed', showConfirmButton: false, timer: 1000 });
      });
    }
  }

  editRow(row) {
    this.isEditable = true;
    this.selected = row;
    Object.keys(this.categoryForm.controls).forEach(key => {
      this.categoryForm.get(key).setValue( row[key] );
    });
  }

  deleteRow(row) {

    Swal.fire({
      title: 'Advertencia',
      icon: 'warning',
      text: 'Esta acción es irreversible',
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
    }).then( (d: any) => {
      if (d.value === true) {
        this.conector.groups_delete(row.id).subscribe( (x: any) => {
          const { ok, msg} = x;
          if (ok === true) {
            Swal.fire('Exito', 'Eliminación realizada.', 'success');
            this.conector.groups_get(this.pagination.init, this.pagination.pageSize).subscribe( (res: any) => {
              const { ok, data, msg }  = res;
              this.pagination.length = msg;
              this.masterGroups = data;
            });
          } else {
            Swal.fire('Error', msg, 'error');
          }
        });
      }
    });
  }

}
