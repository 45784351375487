import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn, FormArray, FormArrayName } from '@angular/forms';
import { MatTabChangeEvent, MatDialog, MatStepper, MatTreeFlattener, MatTreeFlatDataSource, MatPaginator } from '@angular/material';
import { Component, Injectable, OnInit, Pipe, PipeTransform, ViewChild  } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { ConnectorEthicsSurveyService } from '../../../../services/ethics-survey/conector-ethics-survey.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UsersSurvey,configModelType1 ,configModelType2 , topicsSurvey, seriesSurveyConfig, questionsSurveyConfig, answerQuestionsSurveyConfig, ownQuestionsSurveyConfig, questionsTypeSurveyConfig} from '../../ViewModel/config-model/config-model.component';

import {FlatTreeControl} from '@angular/cdk/tree';
import {CollectionViewer, SelectionChange, SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, merge} from 'rxjs';
import {map} from 'rxjs/operators';
import { ConectorJsonService } from '../../../../services/conector-json.service';
import { ResponseHandler } from '../../../../interfaces/ResponseHandler';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

// arbol 
interface FoodNode {
  name: string;
  id: string;
  count?: number;
  subs?: FoodNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  id: string;
  count: number;
  level: number;
}
// fin 
@Component({
  selector: 'app-create-config',
  templateUrl: './create-config.component.html',
  styleUrls: ['./create-config.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
  
})

export class CreateConfigComponent implements OnInit {
// arbol

  displayedColumns: string[] = ['name', 'count'];
  
  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.subs && node.subs.length > 0,
      name: node.name,
      id: node.id,
      count: node.count,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
      node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this.transformer, node => node.level, 
      node => node.expandable, node => node.subs);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  
  // --------
  title = '% module'; // Titulo inicial
  isShownEngagement: boolean = false ; // var control view div hiden
  stepControl1: boolean = true ; // var control view div hiden
  stepControl2: boolean = false ; // var control view div hiden
  stepControl3: boolean = false ; // var control view div hiden
  stepControl4: boolean = false ; // var control view div hiden
  stepControl5: boolean = false ; // var control view div hiden
  stepControl6: boolean = false ; // var control view div hiden
  stepControl7: boolean = false ; // var control view div hiden
  isDivEdit: boolean = false ; // var control view div hiden

  // ----- valida submit

  submitNom035Valid : boolean = false;
  submitValid : boolean = false;
  stateSubmit : boolean =  true;

  ///

  listUsers = [] ; // lista users
  listGroupForm = [] ; // lista Grupos
  listUsersForm = [] ; // lista Grupos


  // stips
  isLinear = true;
  // stips 1
  firstFormGroup: FormGroup;
  // stips 2
  secondFormGroup: FormGroup;
  // stips 3
  thirdFormGroup : FormGroup;
  // stips 4
  quarterFormGroup : FormGroup;
  // stips 4 2
  quarterFormGroupType2 : FormGroup;
    // series
    seriesCategory = '0';
    // Preguntas 
    questionsSeriesCategory = '0';
    questionsSeries = '0';
  // stips 5
  fifthFormGroup : FormGroup;
  // stips 6
  sixthFormGroup : FormGroup;


  
  constructor( 
    public dialog: MatDialog,   
    private _formBuilder: FormBuilder ,
    private route: Router, 
    private conector: ConnectorEthicsSurveyService, 
    private conectorCategory: ConectorJsonService, 
    private fb: FormBuilder,
    public datepipe: DatePipe) { 
    // arbol

    this.conector.tree_category().subscribe( (k: ResponseHandler) => {

      this.dataSource.data = k.data;


    });
    //------
    
    // stip 1
    this.firstFormGroup = this._formBuilder.group( {
      stepFirstType :  [''],
      stepSecondEngagement : ['']
    });
    this.secondFormGroup = this._formBuilder.group({
      nameProyect: ['', Validators.required ],
      nameCompany: ['',Validators.required],
      startDate: ['',Validators.required],
      endDate: ['',Validators.required],
      description: ['',Validators.required],
      random: [''],
    });
    this.thirdFormGroup = this._formBuilder.group( {
      item :  ['',Validators.required]
    });
    // stip 4
    this.quarterFormGroup = this._formBuilder.group({
      questions: this._formBuilder.array(
        [this._formBuilder.group(
          {
            question : [''], 
            answer : ['']
            // question : ['',Validators.required], 
            // answer : ['',Validators.required]
          }
        )
        ]
        ),
    });
      // Condicion si es subtype 2
      this.quarterFormGroupType2 = this._formBuilder.group({
        categorys : this._formBuilder.array([this._formBuilder.group({
          id : [''], 
          name : [''], 
          status : ['']
        })]),
        newCategory :  [''],

        series: this._formBuilder.array([this._formBuilder.group({
          id : [''], 
          idCategory : [''], 
          name : [''], 
          status : [''],
          idSeries : [''],
        })]),
        newSerie :  [''],
        newSerieCategory :  [''],
        newSeriesQuestions :  [''],
        newQuestionsCategory:  [''],


        questions : this._formBuilder.array([this._formBuilder.group({
          id : [''],
          name : [''],
          status : [''],
          idSeries : [''],
          idCatQuestions : [''],
          idFactors : [''],
          GroupFactor : [''],
          idrelacion : [''],
        })]),
        newNameQuestions : [''],

        questionsTypes: this._formBuilder.array([this._formBuilder.group({
          id : [''],
          typeid : [''],
          section1 : [''],
          section2 : [''],
          section3 : [''],
          description : [''],
          orderlist : [''],
          active : [''],
        })]),
        newQuestionsType : [''],
        
      });
    // stip 5
    this.fifthFormGroup = this._formBuilder.group({
      questions: this._formBuilder.array([this._formBuilder.group({id : [''], question : [''], answer : ['']})]),
    });
    // stip 6
    this.sixthFormGroup = this._formBuilder.group({
    });
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngOnInit() {
    this.categorySurveyGet();
    this.seriesSurveyGet();
    this.qestionsSurveyGet();
   this.questionsTypeGet();
  }
  
  // submit form entity
  submit() {

    this.stateSubmit = false;

    //debugger;
    // NOM035 
    if(this.firstFormGroup.get('stepFirstType').value == '2' ){

      if(this.submitNom035Valid == false){

        const EntityConfig = new configModelType2();
        EntityConfig.id	= 0,
        
        EntityConfig.nameProyect	= this.secondFormGroup.get('nameProyect').value;
        EntityConfig.nameCompany	= this.secondFormGroup.get('nameCompany').value;
        EntityConfig.idModule	= 1,
        EntityConfig.idUser	= "oscar",
        EntityConfig.idAccess	= "1",
        EntityConfig.startDate	= this.secondFormGroup.get('startDate').value;
        EntityConfig.endDate	= this.secondFormGroup.get('endDate').value;
  
        var f = new Date();
  
        EntityConfig.createDate =  this.datepipe.transform(f, 'yyyy-MM-dd');
  
        EntityConfig.idAccount	= "oscar";
  
        EntityConfig.type	 = this.firstFormGroup.get('stepFirstType').value;
        EntityConfig.subType= "0";
        EntityConfig.engagement = "0";
  
        EntityConfig.description	= this.secondFormGroup.get('description').value;
        EntityConfig.predetermined	= "1";
        EntityConfig.idLanguaje	= "1";
        EntityConfig.status	= "1";
        // USUARIOS 
        let UsersSurveyArrayModel =  [new UsersSurvey()];

        let groupCheckboxUsers = document.getElementsByName('groupCheckbox[]');



        // for(var i=0; i< groupCheckboxUsers.length; i++) {
          
        //   let inputchecked = (<HTMLInputElement>groupCheckboxUsers[i]).checked;
        //   let inputValue = (<HTMLInputElement>groupCheckboxUsers[i]).value;

        //   if (inputchecked != undefined && inputValue != undefined ) {
            
            
        //     if (inputchecked == true) {
              

        //       const UsersSurvey_ = new UsersSurvey();
        //       UsersSurvey_.id = inputValue;
        //       UsersSurvey_.idGroup = "1";
    
        //       UsersSurveyArrayModel.push(UsersSurvey_);

        //     } 

        //   } 

        // }

  
        for (var i = 0; i < this.listUsersForm.length; i++) {
          let item = this.listUsersForm[i];

          if (item.status == '1') {

            const UsersSurvey_ = new UsersSurvey();
            UsersSurvey_.id = item.id;
            UsersSurvey_.idGroup = item.idGroup;
    
            UsersSurveyArrayModel.push(UsersSurvey_);

          } 

        }
        EntityConfig.ListUsersSurvey = UsersSurveyArrayModel;
        //return false;
  
        if(this.secondFormGroup.get('random').value == "True"){
          EntityConfig.random	= "True";
        }else{
          EntityConfig.random	= "False";
        }
  
        this.conector.postNom035(EntityConfig).subscribe( 
          (response: any) => {
            const {ok, data, msg} = response;
            if (ok === false) {
              var MsgError  = "";
              for(var k in data) {
                MsgError = MsgError +  "Error en " +  data[k].cam + " ( " + data[k].msg + " ) \n";
              }
              return Swal.fire('Error', MsgError, 'error')
            } else {
              
              this.submitNom035Valid = true;
              this.stateSubmit = true;

              Swal.fire('Buen trabajo!', 'Configuración Guardada', 'success');

              this.route.navigate(['manager/Survey']);

            }
          },
          (err) => {
            this.stateSubmit = true;

            return Swal.fire('Error', err.status + ' ' + err.statusText, 'error')
          }
        );
  

      }else{
        this.stateSubmit = true;

        return Swal.fire('Error', "Esta Configuración ya se Guardo ", 'error')

      };
  
    }else{

      if(this.submitValid == false) {

        const EntityConfig = new configModelType1();

        // -----------------------
        // DATOS BASICOS
        // -----------------------

        EntityConfig.id	= 0,
        
        EntityConfig.nameProyect	= this.secondFormGroup.get('nameProyect').value;
        EntityConfig.nameCompany	= this.secondFormGroup.get('nameCompany').value;
        EntityConfig.idModule	= 1,
        EntityConfig.idUser	= "",
        EntityConfig.idAccess	= "1",
        EntityConfig.startDate	= this.secondFormGroup.get('startDate').value;
        EntityConfig.endDate	= this.secondFormGroup.get('endDate').value;

        var f = new Date();
        EntityConfig.createDate =  this.datepipe.transform(f, 'yyyy-MM-dd');

        EntityConfig.idAccount	= "";

        EntityConfig.type	 = this.firstFormGroup.get('stepFirstType').value;
        EntityConfig.subType= this.firstFormGroup.get('stepSecondEngagement').value;
        EntityConfig.engagement = this.firstFormGroup.get('stepSecondEngagement').value;

        EntityConfig.description	= this.secondFormGroup.get('description').value;
        EntityConfig.predetermined	= "1";
        EntityConfig.idLanguaje	= "1";
        EntityConfig.status	= "1";

        if(this.secondFormGroup.get('random').value == "True"){
          EntityConfig.random	= "True";
        }else{
          EntityConfig.random	= "False";
        }

        // -----------------------
        // USUARIOS 
        // -----------------------

        let UsersSurveyArrayModel =  [new UsersSurvey()];

        // var checkbox = document.getElementsByName('groupCheckbox[]');

        // for(var i=0; i< checkbox.length; i++) {

        //   alert('2 -1');

        //   var inputchecked = (<HTMLInputElement>checkbox[i]).checked;
        //   var inputValue = (<HTMLInputElement>checkbox[i]).value;

        //   if (inputchecked != undefined && inputValue != undefined ) {
        //     alert('3 -1');
        //     if (inputchecked == true) {
        //     alert('4 -1');


        //       const UsersSurvey_ = new UsersSurvey();
        //       UsersSurvey_.id = inputValue;
        //       UsersSurvey_.idGroup = "1";
    
        //       UsersSurveyArrayModel.push(UsersSurvey_);

        //     } 
        //   } 
        // }
      


        for (var i = 0; i < this.listUsersForm.length; i++) {
          let item = this.listUsersForm[i];
          
          if (item.status == '1') {

            const UsersSurvey_ = new UsersSurvey();
            UsersSurvey_.id = item.id;
            UsersSurvey_.idGroup = item.idGroup;
    
            UsersSurveyArrayModel.push(UsersSurvey_);

          } 
       
        }
        EntityConfig.ListUsersSurvey = UsersSurveyArrayModel;

        // -----------------------
        // Categorias
        // -----------------------
        let categoryModel   =  this.quarterFormGroupType2.get('categorys') as FormArray;
        let categoryArrayModel =  [new topicsSurvey()];

        for (var i = 0; i < categoryModel.value.length; i++) {
          
          let item = categoryModel.value[i];
          if(item.id != ""){
            const categorSuvery = new topicsSurvey();
            categorSuvery.id = item.id;
            categorSuvery.idSurveyConfig = 1;
            categorSuvery.idCategory = 1;
            categorSuvery.name = item.name;
            categorSuvery.description = item.name;
            categorSuvery.active = item.status;
            categorSuvery.orderlist = i;
            categoryArrayModel.push(categorSuvery);
          }
        }
        categoryArrayModel.splice(0,1);
        EntityConfig.category = categoryArrayModel;
        
        // -----------------------
        // Series
        // -----------------------
        let seriesModel   =  this.quarterFormGroupType2.get('series') as FormArray;
        let seriesArrayModel =  [new seriesSurveyConfig()];

        for (var i = 0; i < seriesModel.value.length; i++) {
          let item = seriesModel.value[i];
          if(item.id != ""){
            const seriesSurvey = new seriesSurveyConfig();
            
            seriesSurvey.id = item.id;
            seriesSurvey.idSurveyConfig = 1;
            seriesSurvey.name = item.name;
            seriesSurvey.description = item.name;
            seriesSurvey.idTopic = item.idCategory;
            seriesSurvey.active = 1;
            seriesSurvey.orderList = i;
            seriesSurvey.change = 0;
            seriesSurvey.idSeries = item.idSeries;

            seriesArrayModel.push(seriesSurvey);
          }
        }
        seriesArrayModel.splice(0,1);
        EntityConfig.series = seriesArrayModel;
        
        // -----------------------
        // preguntas
        // -----------------------
        let questionsModel   =  this.quarterFormGroupType2.get('questions') as FormArray;
        let questionsArrayModel =  [new questionsSurveyConfig()];

        for (var i = 0; i < questionsModel.value.length; i++) {
          let item = questionsModel.value[i];
          if(item.id != ""){
            const questionsSurvey = new questionsSurveyConfig();

            questionsSurvey.idquestion =  item.id;
            questionsSurvey.idserie =  item.idSeries;
            questionsSurvey.idtype =  1; // consultar
            questionsSurvey.name =  item.name;
            questionsSurvey.active =  1;
            questionsSurvey.orderlist =  i;
            questionsSurvey.idFactors =  item.idFactors;
            questionsSurvey.GroupFactor =  item.GroupFactor;
            questionsSurvey.idrelacion =  item.idrelacion;
            questionsSurvey.change =  1;

            questionsArrayModel.push(questionsSurvey);
          }
        }
        questionsArrayModel.splice(0,1);
        EntityConfig.questions = questionsArrayModel;
        
        // -----------------------
        // tipo de respuesta
        // -----------------------
        let questionsTypesModel   =  this.quarterFormGroupType2.get('questionsTypes') as FormArray;
        let questionsTypesArrayModel = [new questionsTypeSurveyConfig()];
        for (var i = 0; i < questionsTypesModel.value.length; i++) {
          let item = questionsTypesModel.value[i];
          if(item.id != ""){
            const questionsTypeSurvey = new questionsTypeSurveyConfig();
            questionsTypeSurvey.id = item.id;
            questionsTypeSurvey.typeid = item.typeid;
            questionsTypeSurvey.section1 = item.section1 ;
            questionsTypeSurvey.section2 = item.section2 ;
            questionsTypeSurvey.section3 = item.section3 ;
            questionsTypeSurvey.description = item.description ;
            questionsTypeSurvey.orderlist = item.orderlist ;
            questionsTypeSurvey.active = item.active ;
            questionsTypesArrayModel.push(questionsTypeSurvey);
          }
        }
        questionsTypesArrayModel.splice(0,1);
        EntityConfig.typeAnswer = questionsTypesArrayModel;
        
        // -----------------------
        //respuestas  engagement
        // -----------------------
        
        let answerQuestionsModel   =  this.fifthFormGroup.get('questions') as FormArray;
        let answerQuestionsArrayModel =  [new answerQuestionsSurveyConfig()];
        
        if (EntityConfig.subType != "3") {

          for (var i = 0; i < answerQuestionsModel.value.length; i++) {
          
            let item = answerQuestionsModel.value[i];
            if(item.id != ""){
              const  answerQuestionsSurvey = new answerQuestionsSurveyConfig();
              if(item.id == "-1"){
                answerQuestionsSurvey.id =  item.id;
                answerQuestionsSurvey.answer = item.question+"||"+item.answer;
              }else{
                answerQuestionsSurvey.id =  item.id;
                answerQuestionsSurvey.answer =  item.answer;
              }
              answerQuestionsArrayModel.push(answerQuestionsSurvey);
            }
          }

        } else {
          const  answerQuestionsSurvey = new answerQuestionsSurveyConfig();
          answerQuestionsSurvey.id = 0;
          answerQuestionsSurvey.answer = "||";
          answerQuestionsArrayModel.push(answerQuestionsSurvey);

        }

        answerQuestionsArrayModel.splice(0,1);
        EntityConfig.answer = answerQuestionsArrayModel;

        // -----------------------
        // preguntas propias
        // -----------------------
        let ownQuestionsModel   =  this.quarterFormGroup.get('questions') as FormArray;
        let ownQuestionsArrayModel =  [new ownQuestionsSurveyConfig()];
        debugger;
        if (EntityConfig.subType != "3") {
          
          for (var i = 0; i < ownQuestionsModel.value.length; i++) {
            let item = ownQuestionsModel.value[i];
            if(item.id != "" && item.id > 0){
              const ownQuestionsSurvey = new ownQuestionsSurveyConfig();
              ownQuestionsSurvey.id  =  i;
              ownQuestionsSurvey.question =  item.question;
              ownQuestionsSurvey.answer =  item.answer;
              ownQuestionsArrayModel.push(ownQuestionsSurvey);
            }
          }

        }else{
          const ownQuestionsSurvey = new ownQuestionsSurveyConfig();
          ownQuestionsSurvey.id  =  0;
          ownQuestionsSurvey.question = "0";
          ownQuestionsSurvey.answer =  "0";
          ownQuestionsArrayModel.push(ownQuestionsSurvey);
        }
        ownQuestionsArrayModel.splice(0,1);
        EntityConfig.ownQuestions = ownQuestionsArrayModel;

        // -----------------------
        // ENVIO FORMULARIO
        // -----------------------

        this.conector.post(EntityConfig).subscribe( 
          (response: any) => {
            const {ok, data, msg} = response;
            if (ok === false) {
              var MsgError  = "";
              for(var k in data) {
                MsgError = MsgError +  "Error en " +  data[k].cam + " ( " + data[k].msg + " ) \n";
              }
              return Swal.fire('Error', MsgError, 'error')
            } else {
              this.submitValid = true;
              this.stateSubmit = true;

              Swal.fire('Buen trabajo !', 'Configuración guardada', 'success');
              this.route.navigate(['manager/Survey']);

            }
          },
          (err) => {
              this.stateSubmit = true;
              return Swal.fire('Error', err.status + ' ' + err.statusText, 'error')
          }
        );

      } else{
        this.stateSubmit = true;
        return Swal.fire('Error', "Esta Configuración ya se Guardo ", 'error')

      };

    }
  }
  
    // change type proyect  stepper 1
    onChangeType(deviceValue) {
      if (deviceValue == 1) this.isShownEngagement = true; else this.isShownEngagement = false;
    }
    // stepper 4 // QuestionsOwner
    get getQuestionsQuarter(){
      return this.quarterFormGroup.get('questions') as FormArray;
    }

    addQuestionsQuarter(){
      const control = <FormArray>this.quarterFormGroup.controls['questions'];
      if(control.status == "VALID"){
        control.push(this._formBuilder.group({question : ['',Validators.required], answer : ['',Validators.required]}));
      }
    }

    RemoveQuestionsQuarter(index : number){
      const control = <FormArray>this.quarterFormGroup.controls['questions'];
      control.removeAt(index);
    }

    // stepper 1

    validarStepperfirst(stepper: MatStepper){
      const control =this.firstFormGroup.value;
      
      if(control.stepFirstType == "1" ){

        if(control.stepSecondEngagement  != "" ){

          if(control.stepSecondEngagement  == "2" ){
            
            this.stepControl1 = true;
            this.stepControl2 = true;
            this.stepControl1 = false;

            this.stepControl3 = true;
            this.stepControl4 = true;
            this.stepControl5 = true;
            this.stepControl6 = true;
            this.stepControl7 = true;
          } else if(control.stepSecondEngagement  == "1" ){
            this.stepControl1 = true;
            this.stepControl2 = true;
            this.stepControl3 = true;
            this.stepControl4 = true;
            this.stepControl5 = false;
            this.stepControl6 = true;
            this.stepControl7 = true;
          } else if(control.stepSecondEngagement  == "3"){
            this.stepControl1 = true;
            this.stepControl2 = true;
            this.stepControl3 = true;
            this.stepControl4 = false;
            this.stepControl5 = true;
            this.stepControl6 = false;
            this.stepControl7 = true;
          } 
          //stepper.next();
          this.stepControl1 = false;

        } else {
          return Swal.fire('Error','Debe seleccionar una configuración ', 'error')
        }

      }else if(control.stepFirstType == "2" ) {
        this.stepControl2 = true;
        this.stepControl3 = true;
        this.stepControl4 = false;
        this.stepControl5 = false;
        this.stepControl6 = false;
        this.stepControl7 = true;
        this.stepControl1 = false;

      }else{
        return Swal.fire('Error','Debe seleccionar un  tipo de Poyecto ', 'error')
      }
    }

    // stepper 2

    validarStepperSecond(stepper: MatStepper){
      const control =this.secondFormGroup.value;
      if(this.secondFormGroup.status == "VALID"){
        if(control.startDate > control.endDate ){
          return Swal.fire('Error','La fecha de inicio no puede ser mayor a la fecha de finalizacion de la encuesta  ', 'error')
        }else{
          //stepper.next();
          this.stepControl2 = false;
        }
      }
      
    }

    // stepper 3

    validarStepperGroupUsers(stepper: MatStepper){
      const control =this.listUsersForm.length;
      if(control == 0){
          return Swal.fire('Error','Debe seleccionar por lo menos un usuario  ', 'error')
      }else{
        this.thirdFormGroup.controls['item'].setValue("1");
        this.stepControl3 = false ; // var control view div hiden
        //stepper.next();
      }
      
    }

    // stepper 4

    validarStepperQuarter(stepper: MatStepper){
      const control = <FormArray>this.quarterFormGroup.controls['questions'];
      if(control.status == "VALID"){
        // if(control.value.length > 4 ){
        //   return Swal.fire('Error','Debe ser 4 Valores y descripciones ', 'error')
        // }else {

          // get questionsWorkingEnvironmentGet
          this.questionsWorkingEnvironmentGet();
          // get question owner           
          let questionsOwnerModel   =  this.quarterFormGroup.get('questions') as FormArray;
          // get question engagement
          const control = <FormArray>this.fifthFormGroup.controls['questions'];

          // add question owner to  questionsWorkingEnvironmentGet
          // for (var i = 0; i < questionsOwnerModel.value.length; i++) {
          //   let item = questionsOwnerModel.value[i];
          //   if(item.id != ""){

          //     control.push(this._formBuilder.group({id : [ "-1" ], question : [ item.answer ], answer : ['']}));
              
          //   }
          // }
          this.stepControl4 = false ; // var control view div hiden

          //stepper.next();
        //}
      }
    }

    validarStepperFifth(stepper: MatStepper){
      const control = <FormArray>this.fifthFormGroup.controls['questions'];

      if(control.status == "VALID"){
        //stepper.next();
        this.stepControl6 = false;
      }else{
        return Swal.fire('Error','Debe responder todas las preguntas ', 'error')
      }
    }

  // stepper 4 2
    // Categorias
      categorySurveyGet() {
          this.conector.categorySurveyGet().subscribe( (response: any) => {
          const {ok, data, msg} = response;
          if (ok === false) {
            return Swal.fire('Error', msg, 'error')
          } else {
            const control = <FormArray>this.quarterFormGroupType2.controls['categorys'];
            for (var key in response.data) {

                var QuestionKey = response.data[key];
                control.push(this._formBuilder.group({
                  id : [ QuestionKey.id ], 
                  name : [ QuestionKey.name ], 
                  status : [ QuestionKey.status ]
                }));
            }
          }
        });
      }

      get getCategorySurveyArray(){
        let categorys = this.quarterFormGroupType2.get('categorys') as FormArray;
        return categorys;
      }

      getCategorySurveyArrayFind(Index : string, type : number){
        let Question = "";

        if(Index != "0"){
          let array   =  this.quarterFormGroupType2.get('categorys') as FormArray;
          let item = array.value[Index];
          
          if(item.question != ""){
            if(type == 1 ){
              Question = item.name;
            }else{
              Question = item.id;
            }
          }
      }
        return Question;
      }

      editCategorys(selector){
        if(document.getElementById("divEditInput"+selector).style.display == 'block'){
          document.getElementById("divEditInput"+selector).style.display = 'none';
        }else{
          document.getElementById("divEditInput"+selector).style.display = 'block';
        };

        if(document.getElementById("divEditLabel"+selector).style.display == 'block'){
          document.getElementById("divEditLabel"+selector).style.display = 'none';
        }else{
          document.getElementById("divEditLabel"+selector).style.display = 'block';
        };
      }

      addCategory(){
        const control = this.quarterFormGroupType2.controls['newCategory'];
        if(control.status == "VALID"){
          let array   =  this.quarterFormGroupType2.get('categorys') as FormArray;
          let id = array.value.length+1;
          array.push(this._formBuilder.group({
            id : [ id ], 
            name : [ control.value ], 
            status : [ 0 ]
          }));
        }
      }

      removeCategory(index : number){
        const control = <FormArray>this.quarterFormGroupType2.controls['categorys'];
        let item = control.value[index];
          
          if(item.id != ""){
            if(item.name == "Engagement" ){
              return Swal.fire('Error', "No esta permitido borrar esta categoria", 'error')
            } else{
              control.removeAt(index);
            }
          }
      
      }
    
  // Series

    seriesSurveyGet() {
      this.conector.seriesSurveyGet("1").subscribe( (response: any) => {
        const {ok, data, msg} = response;
        if (ok === false) {
          return Swal.fire('Error', msg, 'error')
        } else {
          const control = <FormArray>this.quarterFormGroupType2.controls['series'];
          for (var key in response.data) {
            var QuestionKey = response.data[key];
            control.push(this._formBuilder.group({
              id : [ { value:  QuestionKey.id, disabled: false } ], 
              idCategory : [ { value:  QuestionKey.idCategory, disabled: false }], 
              name : [  { value:  QuestionKey.name, disabled: false }], 
              status : [  { value:  QuestionKey.status, disabled: false }  ], 
              idSeries : [  { value:  QuestionKey.id, disabled: false }  ], 
            }));
          }
        }
      });
    }

    get getSeriesSurveyArray(){
      let series   =  this.quarterFormGroupType2.get('series') as FormArray;
      return series;
    }

    getSeriesSurveyArrayFind(Index : string, type : number, init : number){
      let Question = "N_A";
      if(Index != "0"){

        if(init != 0){ 

          let array   =  this.quarterFormGroupType2.get('series') as FormArray;
          let item = array.value[Index];
          
          if(item.question != ""){
            if(type == 1 ){
              Question = item.name;
            } else if( type == 2 ) {
              Question = item.id;
            } else if( type == 3 ) {
              Question = item.idCategory;
            }
          }
        }

      }
      return Question;
    }

    editSeries(selector){
      if(document.getElementById("divEditSeriesInput"+selector).style.display == 'block'){
        document.getElementById("divEditSeriesInput"+selector).style.display = 'none';
      }else{
        document.getElementById("divEditSeriesInput"+selector).style.display = 'block';
      };

      if(document.getElementById("divEditSeriesLabel"+selector).style.display == 'block'){
        document.getElementById("divEditSeriesLabel"+selector).style.display = 'none';
      }else{
        document.getElementById("divEditSeriesLabel"+selector).style.display = 'block';
      };
    }

    addSeries(){
      const control = this.quarterFormGroupType2.controls['newSerie']; // 
      const newSerieCategory = this.quarterFormGroupType2.controls['newSerieCategory']; 
      if(control.status == "VALID"){
        let array   =  this.quarterFormGroupType2.get('series') as FormArray;
        let id = array.value.length+1;
        array.push(this._formBuilder.group({
          id : [id], 
          idCategory : [this.seriesCategory], 
          name : [control.value], 
          status : [0]
        }));
      }
    }

    removeSeries(index : number){
      const control = <FormArray>this.quarterFormGroupType2.controls['series'];
      let itemSerie = control.value[index];
      
      const controlCategory = <FormArray>this.quarterFormGroupType2.controls['categorys'];
      let itemCategoryValues = controlCategory.value;

      var itemCategory = itemCategoryValues.filter((x) =>x.id === itemSerie.idCategory );

      console.log('--------------------');
      console.log('---Serires---');
      console.log(itemSerie);
      console.log('---All---');
      console.log(control);
      console.log('---Categorias---');
      console.log('---id Category in---');
      console.log(itemSerie.idCategory);
     
      console.log('---item---');
      console.log(itemCategory[0].id);
      console.log(itemCategory);
      console.log('all');
      console.log(controlCategory);
      console.log('--------------------');

      
          if(itemCategory[0].id != ""){
            if(itemCategory[0].name == "Engagement" ){
              return Swal.fire('Error', "No esta permitido borrar esta Serie", 'error')
            } else{
              control.removeAt(index);
            }
          }
    }

    changeCategory(id : string){
      this.seriesCategory = id;
    }

  // Preguntas

    getSeriesSurveyArrayByCategoryFind(Index : string, type : number, init : number){
      let Question = "N_A";

      if(Index != "0"){

        if(init != 0){ 

          let array   =  this.quarterFormGroupType2.get('series') as FormArray;
          let item = array.value[Index];
          
          if(item.question != ""){
            if(type == 1 ){
              Question = item.name;
            } else if( type == 2 ) {
              Question = item.id;
            } else if( type == 3 ) {
              Question = item.idCategory;
            }
          }
        }

      }
      return Question;
    }

    changeQuestionsSeriesCategory(id : string){
      this.questionsSeriesCategory = id;
    }

    qestionsSurveyGet() {
      this.conector.qestionsSurveyGet().subscribe( (response: any) => {
        const {ok, data, msg} = response;
        if (ok === false) {
          return Swal.fire('Error', msg, 'error')
        } else {
          const control = <FormArray>this.quarterFormGroupType2.controls['questions'];
          for (var key in response.data) {
              var QuestionKey = response.data[key];
              control.push(this._formBuilder.group({
                id : [ QuestionKey.id ],
                name : [ QuestionKey.name ],
                status : [ QuestionKey.status ],
                idSeries : [ QuestionKey.idSeries ],
                idCatQuestions : [ QuestionKey.idCatQuestions ],
                idFactors : [ QuestionKey.idFactors ],
                GroupFactor : [ QuestionKey.groupFactor ],
                idrelacion : [ QuestionKey.idQuestions ],
              }));
          }
        }
      });
    }

    get getQuestionsSurveyArray(){
      let questions = this.quarterFormGroupType2.get('questions') as FormArray;
      return questions;
    }

    getQuestionsSurveyArrayFind(Index : string, type : number){
      let Question = "N_A";
      if(Index != "0"){
        let array   =  this.quarterFormGroupType2.get('questions') as FormArray;
        let item = array.value[Index];
        
        if(item.question != ""){
          if(type == 1 ){
            Question = item.name;
          } else if( type == 2 ) {
            Question = item.id;
          } else if( type == 3 ) {
            Question = item.idSeries;
          }
        }
      }
      return Question;
    }

    editQuestions(selector){
      if(document.getElementById("divEditQuestionsInput"+selector).style.display == 'block'){
        document.getElementById("divEditQuestionsInput"+selector).style.display = 'none';
      }else{
        document.getElementById("divEditQuestionsInput"+selector).style.display = 'block';
      };

      if(document.getElementById("divEditQuestionsLabel"+selector).style.display == 'block'){
        document.getElementById("divEditQuestionsLabel"+selector).style.display = 'none';
      }else{
        document.getElementById("divEditQuestionsLabel"+selector).style.display = 'block';
      };
    }

    addQuestions(){
      const control = this.quarterFormGroupType2.controls['newNameQuestions']; // 
      if(control.status == "VALID"){
        let array   =  this.quarterFormGroupType2.get('questions') as FormArray;
        array.push(this._formBuilder.group({
          name  : [control.value], 
          status  : "1", 
          idSeries   :[this.questionsSeries], 
          idCatQuestions   :[this.questionsSeriesCategory], 
          idFactors   : "0", 
          GroupFactor   : 0, 
        }));
      }
    }

    removeQuestions(index : number){
      const control = <FormArray>this.quarterFormGroupType2.controls['questions'];
      let itemQuestions = control.value[index];

      const controlSeries = <FormArray>this.quarterFormGroupType2.controls['series'];
      let itemSeriesValues = controlSeries.value;
      var itemSeries = itemSeriesValues.filter((x) =>x.id === itemQuestions.idSeries );
      
      const controlCategory = <FormArray>this.quarterFormGroupType2.controls['categorys'];
      let itemCategoryValues = controlCategory.value;

      var itemCategory = itemCategoryValues.filter((x) =>x.id === itemSeries[0].idCategory );

    


          if(itemCategory[0].id != ""){
            if(itemCategory[0].name == "Engagement" ){
              return Swal.fire('Error', "No esta permitido borrar esta pregunta", 'error')
            } else{
              control.removeAt(index);
            }
          }


      control.removeAt(index);
    }

    changeSeries(id : string){
      this.questionsSeries = id;
    }

  // questionsTypeGet
    questionsTypeGet() {
        const control = <FormArray>this.quarterFormGroupType2.controls['questionsTypes'];
        control.removeAt(0);
      
        
            control.push(this._formBuilder.group({
              id : ['4'],
              typeid : ['1'],
              section1 : ['Totalmente de acuerdo'],
              section2 : ['Totalmente de acuerdo'],
              section3 : ['Totalmente de acuerdo'],
              description : ['Totalmente de acuerdo'],
              orderlist : ["1"],
              active : ["1"],
            }));
            control.push(this._formBuilder.group({
              id : ['3'],
              typeid : ['1'],
              section1 : ['Parcialmente de acuerdo'],
              section2 : ['Parcialmente de acuerdo'],
              section3 : ['Parcialmente de acuerdo'],
              description : ['Parcialmente de acuerdo'],
              orderlist : ["2"],
              active : ["1"],
            }));
            
            control.push(this._formBuilder.group({
              id : ['2'],
              typeid : ['1'],
              section1 : ['Parcialmente en desacuerdo'],
              section2 : ['Parcialmente en desacuerdo'],
              section3 : ['Parcialmente en desacuerdo'],
              description : ['Parcialmente en desacuerdo'],
              orderlist : ["3"],
              active : ["1"],
            }));
            control.push(this._formBuilder.group({
              id : ['1'],
              typeid : ['1'],
              section1 : ['Totalmente en desacuerdo'],
              section2 : ['Totalmente en desacuerdo'],
              section3 : ['Totalmente en desacuerdo'],
              description : ['Totalmente en desacuerdo'],
              orderlist : ["4"],
              active : ["1"],
            }));

    }
    get getQuestionsTypeArray(){

      const array   =  <FormArray>this.quarterFormGroupType2.controls['questionsTypes'];
     
      
      return array;
    }


    getQuestionsTypeArrayFind(Index : string, type : number){
      let Question = "";

     // if(Index != "0"){
        let array   =  this.quarterFormGroupType2.get('questionsTypes') as FormArray;
        let item = array.value[Index];
        
        if(item.question != ""){
          if(type == 1 ){
            Question = item.section1;
          }else{
            Question = item.id;
          }
      //  }
    }
      return Question;
    }

    editQuestionsType(selector){
      if(document.getElementById("divEditQuestionsTypeInput"+selector).style.display == 'block'){
        document.getElementById("divEditQuestionsTypeInput"+selector).style.display = 'none';
      }else{
        document.getElementById("divEditQuestionsTypeInput"+selector).style.display = 'block';
      };

      if(document.getElementById("divEditQuestionsTypeLabel"+selector).style.display == 'block'){
        document.getElementById("divEditQuestionsTypeLabel"+selector).style.display = 'none';
      }else{
        document.getElementById("divEditQuestionsTypeLabel"+selector).style.display = 'block';
      };
    }

    addQuestionsType(){
      const control = this.quarterFormGroupType2.controls['newQuestionsType'];
      if(control.status == "VALID"){
        let array   =  this.quarterFormGroupType2.get('questionsTypes') as FormArray;
        array.push(this._formBuilder.group({
          id : ['0'],
          typeid : ['1'],
          section1 : [control.value],
          section2 : [control.value],
          section3 : [control.value],
          description : [control.value],
          orderlist : ['1'],
          active : ['1'],
        }));
      }
    }

    removeQuestionsType(index : number){
      const control = <FormArray>this.quarterFormGroupType2.controls['questionsTypes'];
      return Swal.fire('Error', "No esta permitido borrar las respuestas", 'error')

      //control.removeAt(index);
    }

    qestionsTypeSurveyGet(id : string) {
      this.conector.qestionsTypeSurveyGet(id).subscribe( (response: any) => {
        const {ok, data, msg} = response;
        if (ok === false) {
          return Swal.fire('Error', msg, 'error')
        } else {
          const control = <FormArray>this.quarterFormGroupType2.controls['category'];
          for (var key in response.data) {
              var QuestionKey = response.data[key];
              control.push(this._formBuilder.group({
                id : [ QuestionKey.id ],
                typeid : [ QuestionKey.typeid ],
                section1 : [ QuestionKey.section1 ],
                section2 : [ QuestionKey.section2 ],
                section3 : [ QuestionKey.section3 ],
                description : [ QuestionKey.description ],
                orderlist : [ QuestionKey.orderlist ],
                active : [ QuestionKey.active ],
              }));
          }
        }
      });
    }

    get getQuestionsTypeSurveyArray(){
      let questionsType = this.quarterFormGroupType2.get('questionsType') as FormArray;
      return questionsType;
    }

    // stepper 5
    questionsWorkingEnvironmentGet() {

      
     

      const control = <FormArray>this.fifthFormGroup.controls['questions'];
      if (control.value.length <= 1){

        


        this.conector.questionsWorkingEnvironmentGet().subscribe( (response: any) => {
          const {ok, data, msg} = response;
          if (ok === false) {
            return Swal.fire('Error', msg, 'error')
  
          } else {
            this.fifthFormGroup.value.questions[0].answer = '1';
            for (var key in response.data) {
                var QuestionKey = response.data[key];
                control.push(this._formBuilder.group({id : [ QuestionKey.id ], question : [ QuestionKey.pregunta ], answer : ['',Validators.required]}));
            }
  
          

          }
  
        });

            
      }
      
    }

    get getQuestionsFifth(){
      let questions   =  this.fifthFormGroup.get('questions') as FormArray;
      return questions;
    }

    getQuestionsFifthForm(Index : string){
      let Question = "";

      if(Index != "0"){
        let array   =  this.fifthFormGroup.get('questions') as FormArray;
        let item = array.value[Index];

        if(item.question != ""){
          Question = item.question;
        }
    }
      return Question;
    }

    

    getGroups(id){

      const resultado = this.listGroupForm.find( item => item.idGroup === id );
      this.listUsers = [];

      if(resultado == undefined ){

        this.listGroupForm.push({idGroup : id});

        this.conector.GetUsersGroup(id).subscribe( (response: any) => {

          const {ok, data, msg} = response;
          if (ok === false) {

            return Swal.fire('Error', msg, 'error')

          } else {

            for (var key in response.data) {
              let Users = {  id : response.data[key].id, 
                name : response.data[key].name, 
                firstname : response.data[key].firstname, 
                lastname : response.data[key].lastname, 
                nickname : response.data[key].nickname,
                phone : response.data[key].phone,
                email : response.data[key].email,
                groups :response.data[key].group,
                namegroup :response.data[key].namegroup
              };

              this.listUsers.push(Users);
              this.listUsersForm.push({
                id : response.data[key].id , 
                idGroup : id,
                status : '1',
                name : response.data[key].name, 
                firstname : response.data[key].firstname, 
                lastname : response.data[key].lastname, 
                nickname : response.data[key].nickname,
                phone : response.data[key].phone,
                email : response.data[key].email,
                groups :response.data[key].group,
                namegroup :response.data[key].namegroup
              }) ; 

            }
          }

        });
      }else{
        this.listGroupForm = this.listGroupForm.filter( item => item.idGroup !== id);
        this.listUsersForm = this.listUsersForm.filter( item => item.idGroup !== id);
      }
    }

    getUsersGroup(id , idGroup, event){

      const resultado = this.listUsersForm.find( item => item.id === id );
      if(resultado == undefined ){
        this.listUsersForm.push({id : id , idGroup : idGroup}) ; 
      }else{
        // this.listUsersForm = this.listUsersForm.filter( item => item.id !== id);
        if (resultado.status == '0') {  
          resultado.status = '1';
        } else {
          resultado.status = '0';
        }
      }
    }

    comprobarChecks(event){

      var checkbox = document.getElementsByName('groupCheckbox[]');


      for(var i=0; i< checkbox.length; i++) {

        var inputchecked = (<HTMLInputElement>checkbox[i]).checked;
        var inputValue = (<HTMLInputElement>checkbox[i]).value;

        if (inputchecked != undefined && inputValue != undefined ) {

          if (inputchecked == true) {
          } 

        } 

      }
      
    }
    onClickMe(){
      alert("ok");
    }

    stepperPrevious(stepper: MatStepper ,idStepper){

      const control =this.firstFormGroup.value;
      switch (idStepper) {
        case 2:
          this.stepControl1 = true;
          stepper.previous();
          break;

        case 3:
          this.stepControl2 = true;
          stepper.previous();
          break;
        
        case 4:
          this.stepControl3 = true;
          stepper.previous();
          break;

        case 5:
          
          if (control.stepSecondEngagement  == "1") {
  
            // this.stepControl4 = true;
            // stepper.previous();
            
          } else if (control.stepSecondEngagement  == "2") {
            
            this.stepControl4 = true;
            stepper.previous();
            
          } else if (control.stepSecondEngagement  == "3") {
            
            this.stepControl3 = true;
            stepper.previous();
            
          }
          
          break;

        case 6:

          if (control.stepSecondEngagement  == "1") {
  
              this.stepControl4 = true;
              stepper.previous();
              
            } else if (control.stepSecondEngagement  == "2") {
              
              this.stepControl5 = true;
              stepper.previous();

            } else if (control.stepSecondEngagement  == "3") {
              
            }
            
          break;
        
        case 7 :
          if (control.stepFirstType == "1") {
            if (control.stepSecondEngagement  == "1") {
              
              this.stepControl6 = true;
              stepper.previous();

            } else if (control.stepSecondEngagement  == "2") {
              this.stepControl6 = true;
              stepper.previous();

            } else if (control.stepSecondEngagement  == "3") {
              
              this.stepControl5 = true;
              stepper.previous();
              
            }
          } else {

            this.stepControl3 = true;
            stepper.previous();
           
            
          }
    
        default:
          break;
      }

    }



    // if(control.stepFirstType == "1" ){

    //   if(control.stepSecondEngagement  != "" ){

    //     if(control.stepSecondEngagement  == "2" ){
          
    //       this.stepControl1 = true;
    //       this.stepControl2 = true;
    //       this.stepControl3 = true;
    //       this.stepControl4 = true;
    //       this.stepControl5 = true;
    //       this.stepControl6 = true;
    //       this.stepControl7 = true;
    //     } else if(control.stepSecondEngagement  == "1" ){
    //       this.stepControl1 = true;
    //       this.stepControl2 = true;
    //       this.stepControl3 = true;
    //       this.stepControl4 = true;
    //       this.stepControl5 = false;
    //       this.stepControl6 = true;
    //       this.stepControl7 = true;
    //     } else if(control.stepSecondEngagement  == "3"){
    //       this.stepControl1 = true;
    //       this.stepControl2 = true;
    //       this.stepControl3 = true;
    //       this.stepControl4 = false;
    //       this.stepControl5 = true;
    //       this.stepControl6 = false;
    //       this.stepControl7 = true;
    //     } 
    //     //stepper.next();
    //     this.stepControl1 = false;

    //   } else {
    //     return Swal.fire('Error','Debe seleccionar una configuración ', 'error')
    //   }

    // }else if(control.stepFirstType == "2" ) {
    //   this.stepControl1 = true;
    //   this.stepControl2 = true;
    //   this.stepControl3 = true;
    //   this.stepControl4 = false;
    //   this.stepControl5 = false;
    //   this.stepControl6 = false;
    //   this.stepControl7 = true;
    //   stepper.next();
    //   this.stepControl1 = false;

    // }else{
    //   return Swal.fire('Error','Debe seleccionar un  tipo de Poyecto ', 'error')
    // }


    stepperNext(stepper: MatStepper,idStepper ){
      switch (idStepper) {
        
        case 5:
          this.stepControl5 = false;
          break;
      
        default:
          break;
      }
    }

   
}


