import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { DemoMaterialModule } from '../../material-module';
import { SupervisorItems } from './supervisor-items';
import { routes } from './supervisor-routing.module';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		DemoMaterialModule,
		RouterModule.forChild(routes),
		MatBadgeModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => {
					return new TranslateHttpLoader(http);
				},
				deps: [HttpClient],
			},
		}),
	],
	providers: [SupervisorItems, { provide: MAT_DIALOG_DATA, useValue: {} }],
})
export class SupervisorModule {}
