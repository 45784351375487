import { Router } from '@angular/router';
import { AuthenticationService } from './../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { PermissionComponent } from './../permission/permission.component';
import { CreateUserComponent } from './../modals/create-user/create-user.component';
import { Pagination } from './../../../models/pagination';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { ManageUserComponent } from './../modals/manage-user/manage-user.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatPaginator } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import {Md5} from 'md5-typescript';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  selectedIndex = 0;
  activeRow = null;

  userForm: FormGroup;
  users = [];

  pagination = new Pagination();
  showActive = 0;

  @ViewChild(MatPaginator, { static: true }) paginationBar: MatPaginator;

  constructor(
    private conector: ConectorJsonService,
    public dialog: MatDialog,
    private route: Router,
    public translate: TranslateService,
    public auth: AuthenticationService) {
    this.auth.setLanguage().then( k => this.translate.setDefaultLang(k) );
    this.pagination.length = 10;
  }

  ngOnInit() {
    this.conector.users_get(this.showActive, this.pagination.init, this.pagination.limit).subscribe( (response: any) => {
      const {data, msg} = response;
      this.users = data;
      this.pagination.length = msg;
    });
  }

  onChange($event) {

    const { checked} = $event;

    this.users = [];
    this.pagination.length = 0;
    this.showActive =  (checked === true) ? 1 : 0;

    this.conector.users_get(this.showActive, this.pagination.init, this.pagination.limit).subscribe( (response: any) => {
      const {data, msg} = response;
      this.pagination.length = msg;

      this.users = data;

      if (this.paginationBar) {
        this.paginationBar.firstPage();
      }

    });
  }

  changePage($event) {

    this.pagination.length = 0;
    this.users = [];

    this.pagination.changePage($event);

    this.conector.users_get(this.showActive, this.pagination.init, this.pagination.limit).subscribe( (d: any) => {
      const { msg, data} = d;
      this.pagination.length = msg;
      this.users = data;
    });
  }

  editUser(row) {

    const dialogRef = this.dialog.open(ManageUserComponent, { data : row });

    dialogRef.afterClosed().subscribe(() => {
      this.conector.users_get().subscribe( (response: any) => {
        const {data} = response;
        this.users = data;
      });
    });

  }

  editLogin(formdata) {

    Swal.fire({
      title: 'Modificar contrase&ntilde;a',
      text: formdata.email,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {

        if (login !== null || login === '') {

          const model = {
            id: formdata.userlogin,
            password: login
          };

          this.conector.login_put(model).subscribe( () => {
            Swal.fire('Exito', 'Contrase&ntilde;a modificada.', 'success');
          });

        } else {
          Swal.fire('Error', 'Campo requerido', 'error');
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }

  deleteRow(row) {
    Swal.fire({
      title: 'Advertencia',
      icon: 'warning',
      text: 'Esta acción es irreversible',
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
    }).then( (d: any) => {
      if (d.value === true) {
        this.conector.users_del(row.id).subscribe( (x: any) => {
          const { ok, msg} = x;
          if (ok === true) {
            Swal.fire('Exito', 'Eliminación realizada.', 'success');
            this.conector.users_get().subscribe( (response: any) => {
              this.users = response.data;
            });
          } else {
            Swal.fire('Error', msg, 'error');
          }
        });
      }
    });
  }

  adduser() {
    const ref = this.dialog.open(CreateUserComponent, {});
    ref.afterClosed().subscribe( () => {
      this.conector.users_get().subscribe( (d: any) => {
        console.info('u&', d);
      });
    });
  }

  setPermission(row) {

    localStorage.setItem('userPermission', JSON.stringify(row));

    this.route.navigateByUrl('/manager/permissions');

    // const ref = this.dialog.open(PermissionComponent, { data: row});

    // ref.afterClosed().subscribe( () => { });

  }

}
