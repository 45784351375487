import { AuthenticationService } from './../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { environment } from './../../../../environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  configForm: FormGroup;
  configuration = { id: 0, logo: 0, company: null, reporting: null, timezone: null, token: null };
  industries = [];
  sectors = [];
  akey = environment.tiny;
  publicFolder = environment.public;
  linkTo = null;

  constructor(private conector: ConectorJsonService, private fb: FormBuilder, public translate: TranslateService, private auth: AuthenticationService) {
    this.configForm = this.fb.group({
      company: [null, Validators.required],
      reporting: [null, Validators.required],
      industry: [0, Validators.required],
      sector: [0, Validators.required],
      logo: [null, Validators.required],
      timezone: [null, Validators.required],
      lang: [null, Validators.required],
      welcome_es: [null, Validators.required],
      process_es: [null, Validators.required],
      tycos_es: [null, Validators.required],
      token: [null],
    });

    this.auth.setLanguage().then( k => this.translate.setDefaultLang(k) );

  }

  async ngOnInit() {

    this.conector.cfg_get().subscribe( (d: any) => {

      const { ok, msg, data } = d;
      this.configuration = data;

      Object.keys(this.configuration).forEach( key => {
        if (this.configForm.get(key)) {
          this.configForm.get(key).setValue( this.configuration[key] );
        }
      });

      if(this.configuration.token != null) {

        var protocol = window.location.port ?  `:${window.location.port}` : '';

        this.linkTo = `${window.location.protocol}//${window.location.hostname}${protocol}/link-to/${this.configuration.token}`;

      }

    });

    this.conector.sectors_get('all').subscribe( (r: any) => {
      const {ok, data, msg} = r;
      this.sectors = data;
    });

    this.conector.industries_get('all').subscribe( (r: any) => {
      const {ok, data, msg} = r;
      this.industries = data;
    });

  }

  updateTimezone($event) {
    console.info('tzdata&', $event);
    this.configForm.get('timezone').setValue($event.name);
  }

  updateConfig(formdata) {

    formdata.id = this.configuration.id;

    Object.keys(this.configuration).forEach( key => {
      if (formdata[key]) {
        this.configuration[key] = formdata[key];
      }
    });

    this.conector.company_put(this.configuration).subscribe( async (r: any) => {

      const msg = await this.translate.get('success').toPromise();

      Swal.fire(msg, '', 'success');


    },  (e: any) => {

      console.info('Er&', e);

    });

  }

  onSelect($ev) {

    const formData = new FormData();

    formData.append('file', $ev.addedFiles[0]);

    this.conector.company_logo(formData).subscribe( (r: any) => {

      this.configuration.logo = r.msg;

      this.conector.company_put(this.configuration).subscribe( async (x: any) => {

        const msg = await this.translate.get("success").toPromise();

        Swal.fire( msg, '', 'success');

      },  (e: any) => {

        console.info('Er&', e);

      });

    });

  }

}
