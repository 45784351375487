import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../services/authentication.service';
import {ConectorJsonService} from '../../../services/conector-json.service';

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import FileSaver from 'file-saver';

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css'],
  providers: [
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE]
      },
      {
        provide: MAT_DATE_FORMATS,
        useValue: MAT_MOMENT_DATE_FORMATS
      }
  ]
})

export class ChartsComponent implements OnInit {

  public chartHandler: any = {
    chart: {
      type: 'pie'
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Brands',
      data: [{
        name: 1,
        y: 2
      }]
    }]
  };

  public chartStatus: any = {
    chart: {
      type: 'pie'
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Brands',
      data: [{
        name: 1,
        y: 2
      }]
    }]
  };

  public chartGroups: any = {
    chart: {
      type: 'pie'
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Brands',
      data: [{
        name: 1,
        y: 2
      }]
    }]
  };

  public options: any = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Groups - Status'
    },
    xAxis: {
      categories: ['a', 'b', 'c']
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Total'
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
          color: ( Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || 'gray'
        }
      }
    },
    legend: {
      enabled: false,
    },
    labels: {
      autoRotation: [-10]
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'x',
        data:  [1, 2, 3]
      },
      {
        name: 'y',
        data:  [2, 4, 6]
      },
      {
        name: 'z',
        data:  [3, 6, 9]
      }
    ]
  };

  byHandler = [];
  byStatus = [];
  byGroups = [];
  byProcess = [];
  bySource = [];

  totales = 0;
  formDates: FormGroup;

  constructor( private translate: TranslateService, private auth: AuthenticationService, private conector: ConectorJsonService, private fb: FormBuilder ) {
    this.auth.setLanguage().then( k => this.translate.setDefaultLang(k) );
    this.formDates = this.fb.group({
      operFin: [null, Validators.required],
      operIni: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.conector.supervisor_chart().subscribe( async(k: any) => {
      await this.mapCharts(k);
    });
  }

  async filterByDate(formData) {

    const ini = moment(formData.operIni);
    const fin = moment(formData.operFin);
    const diff = ini.diff(fin);

    if (diff < 0 ) {

      const inicio = moment(formData.operIni).format('YYYY-MM-DD').toString();
      const finale = moment(formData.operFin).format('YYYY-MM-DD').toString();

      this.conector.supervisor_chart(inicio, finale).subscribe( (k: any) => {
        this.mapCharts(k);
      });

    } else {

      this.translate.get('wrong-dates').subscribe( async(k) => {
        await Swal.fire('Error', k, 'error');
      });
    }

  }

  async mapCharts(k) {

    const { ok, data, msg } = k;

    const staSeries = [];

    this.bySource = data;

    if (ok === true) {

      const { groups, handler, status, process, totales } = data;

      if (totales === 0 ) {

        const dm = await this.translate.get("No data for selected range").toPromise();

         return  Swal.fire('Error', dm, 'error');

      }

      // Total of tickets
      this.totales = totales;

      // Ticket by status
      this.byProcess = process;

      process.forEach( async(x: any) => {
        this.translate.get(x.gid).subscribe( px => {
          staSeries.push({ name: px, y: x.count });
        });
      });

      this.chartStatus.series[0].data = staSeries;
      Highcharts.chart('status', this.chartStatus);

      // ticket by user
      const mapHandlers  = [];
      this.byHandler = handler;

      handler.forEach( async(x: any) => {
        mapHandlers.push({ name: x.label, y: x.value });
      });

      this.translate.get('handler').subscribe( x => {
        this.chartHandler.series[0].title = x;
        this.chartHandler.series[0].data = mapHandlers;
        Highcharts.chart('handler', this.chartHandler);
      });

      // Groups
      this.byGroups = groups;
      const mapGroups = [];

      groups.forEach( async(x: any) => {
        mapGroups.push({ name: x.label, y: x.value });
      });

      this.translate.get('Category').subscribe( x => {
        this.chartGroups.series[0].title = x;
        this.chartGroups.series[0].data = mapGroups;
        Highcharts.chart('groups', this.chartGroups);
      });

      // Complex groups
      this.byStatus = status;
      const series = [];
      const groupNames = [];

      status.forEach( async(x: any) => {

        groupNames.push(x.name);

        const statements = ['new', 'inprogress', 'acknowledged', 'aborted', 'solved', 'cancel'];

        statements.forEach( async row => {
          series.push({
            name: row,  
            data: []
          });
        });

        statements.forEach( (st: string) => {

          const index = x.status.findIndex( o => o.status === st );

          const serie = series.findIndex( i => i.name === st);

          if ( index >= 0 ) {
            series[serie].data.push(x.status[index].total);
          }

          series[serie].data.push(0);

        });

      });

      this.options.title.text = await this.translate.get('Category').toPromise();
      this.options.xAxis.categories = groupNames;
      this.options.series = series;

      Highcharts.chart('subcategory', this.options);

    }

  }

  generatePDF() {

    const formdata = this.formDates.value;

    formdata.byStatus = JSON.stringify(this.chartStatus);
    formdata.byGroups = JSON.stringify(this.chartGroups);
    formdata.byHandler = JSON.stringify(this.chartHandler);
    formdata.byComplex = JSON.stringify(this.options);
    formdata.bySource = JSON.stringify(this.bySource);
    formdata.language = navigator.language;

    this.conector.generate_pdf(formdata).subscribe( (p) => {

      const { data, message, successfully }: any = p;

      if (successfully === false ) {

        alert(message);

      } else {

        const byteArray = new Uint8Array(atob(data.fileContentBase64).split('').map(char => char.charCodeAt(0)));

        const blob =  new Blob([byteArray], {type: 'application/pdf'});

        FileSaver(blob, data.fileName);

      }
    });

  }

}
