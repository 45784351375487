import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loginapp',
  templateUrl: './loginapp.component.html',
  styleUrls: ['./loginapp.component.css']
})
export class LoginappComponent implements OnInit {

  year = new Date().getFullYear();
  loginfrm:FormGroup;
  makeHttp = false;
  login: any;
  sm: any;

  constructor(private conector: ConectorJsonService, public router: Router, private fb: FormBuilder) { 

    this.loginfrm = this.fb.group({
      username: [ '', Validators.required],
      reporting: 34821370648,
      password: [ '', Validators.required]
    });
  }

  ngOnInit() {

  }

  auth(formdata) {

    let clone = formdata;

    const pwd= formdata.password;

    clone.password = pwd;

    this.makeHttp = true;

    clone.reporting = "34821370648";

    this.conector.validarAcceso(clone).subscribe( async(d: any) => {

      if(d.ok === true) {

        localStorage.setItem('jwt_token', JSON.stringify(d.data));

        this.router.navigate(['reportapp']);

      }

      this.conector.info(d);

      this.makeHttp = false;

    });

  }

}
