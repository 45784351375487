import { CKEditorModule } from 'ckeditor4-angular';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { IconPickerModule } from 'ngx-icon-picker';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { DemoMaterialModule } from '../../material-module';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { AccountsComponent } from '../../views/admin/accounts/accounts.component';
import { ComunicaArticulosComponent } from '../../views/comunika/comunica-articulos/comunica-articulos.component';
import { CcCrearComponent } from '../../views/comunika/comunica-cuestionarios/cc-crear/cc-crear.component';
import { CcPreguntasComponent } from '../../views/comunika/comunica-cuestionarios/cc-preguntas/cc-preguntas.component';
import { CcRetroComponent } from '../../views/comunika/comunica-cuestionarios/cc-retro/cc-retro.component';
import { ComunicaCuestionariosComponent } from '../../views/comunika/comunica-cuestionarios/comunica-cuestionarios.component';
import { ComunicaEncuestasComponent } from '../../views/comunika/comunica-encuestas/comunica-encuestas.component';
import { ComunicaEvaluacionesComponent } from '../../views/comunika/comunica-evaluaciones/comunica-evaluaciones.component';
import { ComunicaEventosComponent } from '../../views/comunika/comunica-eventos/comunica-eventos.component';
import { ConfiguracionCategoriasComponent } from '../../views/comunika/configuracion-categorias/configuracion-categorias.component';
import { ConfiguracionEmpresaComponent } from '../../views/comunika/configuracion-empresa/configuracion-empresa.component';
import { ConfiguracionUsuariosComponent } from '../../views/comunika/configuracion-usuarios/configuracion-usuarios.component';
import { EstadisticasActividadComponent } from '../../views/comunika/estadisticas-actividad/estadisticas-actividad.component';
import { EstadisticasActivosComponent } from '../../views/comunika/estadisticas-activos/estadisticas-activos.component';
import { EstadisticasArticulosComponent } from '../../views/comunika/estadisticas-articulos/estadisticas-articulos.component';
import { EstadisticasCuestionariosComponent } from '../../views/comunika/estadisticas-cuestionarios/estadisticas-cuestionarios.component';
import { EstadisticasEncuestasComponent } from '../../views/comunika/estadisticas-encuestas/estadisticas-encuestas.component';
import { EstadisticasEvaluacionesComponent } from '../../views/comunika/estadisticas-evaluaciones/estadisticas-evaluaciones.component';
import { EstadisticasEventosComponent } from '../../views/comunika/estadisticas-eventos/estadisticas-eventos.component';
import { EstadisticasGuardadosComponent } from '../../views/comunika/estadisticas-guardados/estadisticas-guardados.component';
import { EstadisticasUsuariosComponent } from '../../views/comunika/estadisticas-usuarios/estadisticas-usuarios.component';
import { GroupAddonsComponent } from '../../views/integriline/group-addons/group-addons.component';
import { HandlerHomeComponent } from '../../views/integriline/handler-home/handler-home.component';
import { HandlerTicketComponent } from '../../views/integriline/handler-ticket/handler-ticket.component';
import { QuestionsComponent } from '../../views/integriline/questions/questions.component';
import { RelationsComponent } from '../../views/integriline/relations/relations.component';
import { GroupsComponent } from '../../views/manager/groups/groups.component';
import { IntegrityReportsComponent } from '../../views/manager/integrity-reports/integrity-reports.component';
import { PermissionComponent } from '../../views/manager/permission/permission.component';
import { UsersComponent } from '../../views/manager/users/users.component';
import { PickersModule } from '../../views/shared/pickerLoader.module';
import { ManagerItems } from './manager-items';
import { routes } from './manager.routing';

export const MY_FORMATS = {
	parse: {
		dateInput: 'DD-MM-YYYY',
	},
	display: {
		dateInput: 'DD-MM-YYYY',
		monthYearLabel: 'YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'YYYY',
	},
};

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forChild(routes),
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		NgxSliderModule,
		DemoMaterialModule,
		MatTreeModule,
		SharedPipesModule,
		CKEditorModule,
		IconPickerModule,
		DragDropModule,
		HttpModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => {
					return new TranslateHttpLoader(http);
				},
				deps: [HttpClient],
			},
		}),
		FlexModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		MaterialFileInputModule,
		PickersModule,
		SharedPipesModule,
		NgxDropzoneModule,
		NgxCsvParserModule,
		DragDropModule,
		MatDialogModule,
	],
	declarations: [
		AccountsComponent,
		PermissionComponent,
		UsersComponent,
		GroupsComponent,
		RelationsComponent,
		QuestionsComponent,
		GroupAddonsComponent,
		ComunicaArticulosComponent,
		ComunicaEvaluacionesComponent,
		ComunicaEventosComponent,
		ComunicaEncuestasComponent,
		ComunicaCuestionariosComponent,
		ConfiguracionEmpresaComponent,
		ConfiguracionCategoriasComponent,
		ConfiguracionUsuariosComponent,
		EstadisticasActivosComponent,
		EstadisticasEventosComponent,
		EstadisticasArticulosComponent,
		EstadisticasGuardadosComponent,
		EstadisticasUsuariosComponent,
		EstadisticasActividadComponent,
		EstadisticasEvaluacionesComponent,
		EstadisticasEncuestasComponent,
		EstadisticasCuestionariosComponent,
		HandlerHomeComponent,
		HandlerTicketComponent,
		CcCrearComponent,
		CcRetroComponent,
		CcPreguntasComponent,
		IntegrityReportsComponent,
	],
	providers: [
		ManagerItems,
		{ provide: MAT_DIALOG_DATA, useValue: {} },
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
	entryComponents: [],
})
export class ManagerModule {}
