import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import Swal from "sweetalert2";
import { Pagination } from "../../../models/pagination";
import { ConectorModuleService } from "../../../services/conector-module.service";
import { IntegrityReportService } from "../../../services/integrity-report.service";
import { ConnectorJobProfileService } from "../../../services/job-profile.service";
import FileSaver from 'file-saver';

export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
  }

@Component({
    selector: 'app-integrity-reports',
    templateUrl: './integrity-reports.component.html',
    styleUrls: ['./integrity-reports.component.css']
  })
  export class IntegrityReportsComponent implements OnInit {

    evaluationList = [];
    jobProfileList = [];
    selectedIndex = 0;
    evaluations = [];
    pagination = new Pagination();
    tableMessage = "Por favor, realiza una busqueda para mostrar información.";

    reportForm: FormGroup;
    displayedColumns: string[] = ['reporting', 'nickname', 'name', 'firstname', 'lastname', 'jobProfile', 'endDate', 'report'];

    constructor(
        private conector: ConectorModuleService, 
        private job: ConnectorJobProfileService,
        private report: IntegrityReportService,
        private form: FormBuilder,
        private dialog: MatDialog
        ) { 

          this.pagination.length = 10;
        }

    ngOnInit(): void {
        
      this.reportForm = this.form.group({
          evaluation: [null, Validators.required],
          jobProfile: [0],
          nickname: [null],
          name: [null],
          firstname: [null],
          lastname: [null],
        });

      this.conector.submodule_get(1, 0).subscribe( (response: any) => {
        const {data, msg} = response;
        this.evaluationList = data;
      });

      this.job.jobprofile_all().subscribe((response: any)=> {
        const { ok, data, msg } = response;

        data.push({id: 0, label: "-Seleccionar puesto-"});
        data.sort(function(a, b) { return a.id - b.id; });

        this.jobProfileList = data;
        console.log(this.jobProfileList);
      });

    }

    SearchEvalautions(formdata) {

      this.report.evaluations_get(formdata, this.pagination.init, this.pagination.limit).subscribe( (response: any) => {
        const {data, msg} = response;
        this.evaluations = data;
        this.pagination.length = msg;

        if (this.evaluations.length == 0) {

          this.tableMessage = "La consulta realizada no emitio ningún resultado, verifica los filtros ingresados.";
        }
      });
    }

    changePage(formdata, $event) {
  
      this.pagination.length = 0;
      this.evaluations = [];
  
      this.pagination.changePage($event);
  
      this.report.evaluations_get(formdata, this.pagination.init, this.pagination.limit).subscribe( (response: any) => {
        const {data, msg} = response;
        this.evaluations = data;
        this.pagination.length = msg;

        if (this.evaluations.length == 0) {

          this.tableMessage = "La consulta realizada no emitio ningún resultado, verifica los filtros ingresados.";
        }
      });
  
    }

    DownloadReport(row) {

      this.report.downloadselection_get(row.evaluation).subscribe( (response: any) => {
        const { data, msg, ok } = response;

        //debugger;

        if (ok === true) {

          // const byteArray = new Uint8Array(atob(data.pdfBase64).split('').map(char => char.charCodeAt(0)));

          // const blob =  new Blob([byteArray], { type: 'application/pdf'});

          // FileSaver(blob, data.fileName);

          const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
          const downloadLink = document.createElement("a");
          const fileName = response.data.fileName;
    
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
        else {

          Swal.fire('Error', msg, 'error');
        }
        
      });
    }

  }