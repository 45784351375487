import { SurveysComponent } from './../../views/manager/surveys/surveys.component';
import { HandlerTicketComponent } from './../../views/integriline/handler-ticket/handler-ticket.component';
import { HandlerHomeComponent } from './../../views/integriline/handler-home/handler-home.component';
import { CategoryAddonsComponent } from './../../views/integriline/category-addons/category-addons.component';
import { CategoryComponent } from './../../views/integriline/category/category.component';
import { ComunicaEvaluacionesResultadosComponent } from './../../views/comunika/comunica-evaluaciones-resultados/comunica-evaluaciones-resultados.component';
import { JobProfileComponent } from './../../views/manager/jobprofile/jobprofile.component';
import { ChildGuardService } from '../../services/child-guard.service';
import { AuthGuardService } from '../../services/auth-guard.service';
import { CreateUserComponent } from '../../views/manager/modals/create-user/create-user.component';
import { ManageUserComponent } from '../../views/manager/modals/manage-user/manage-user.component';
import { GroupHandlersComponent } from '../../views/integriline/modals/group-handlers/group-handlers.component';
import { GroupQuestionsComponent } from '../../views/integriline/modals/group-questions/group-questions.component';

import { ManagerComponent } from './manager.component';
import { ModuleComponent } from '../../views/manager/module/module.component';
import { HomeManagerComponent } from '../../views/manager/home-manager/home-manager.component';
import { PermissionComponent } from '../../views/manager/permission/permission.component';
import { UsersComponent } from '../../views/manager/users/users.component';
import { CollaboratorComponent } from '../../views/manager/collaborator/collaborator.component';
import { CreateCollaboratorComponent } from '../../views/general/create-collaborator/create-collaborator.component';
import { ReportsComponent } from '../../views/manager/reports/reports.component';
import { CatalogsComponent } from '../../views/admin/catalogs/catalogs.component';
import { GroupsComponent } from '../../views/manager/groups/groups.component';
import { ConfigurationComponent } from '../../views/manager/configuration/configuration.component';
import { ConfigJobProfilesComponent } from '../../views/manager/modals/config-jobprofiles/config-jobprofiles.component';


import { GroupAddonsComponent } from '../../views/integriline/group-addons/group-addons.component';

import { ConfigProyectComponent } from './../../views/ethics-survey/config-proyect/config-proyect.component';
import { EvaluatedAnswerComponent } from './../../views/ethics-survey/evaluated-answer/evaluated-answer.component';
import { CreateConfigComponent } from './../../views/ethics-survey/modal/create-config/create-config.component';
import { DeleteConfigComponent } from './../../views/ethics-survey/modal/delete-config/delete-config.component';
import { UpdateConfigComponent } from './../../views/ethics-survey/modal/update-config/update-config.component';
import { EthicsSurveyTypeOneComponent } from './../../views/ethics-survey/evaluated-answer/ethics-survey-type-one/ethics-survey-type-one.component';
import { EthicsSurveyTypeTwoComponent } from './../../views/ethics-survey/evaluated-answer/ethics-survey-type-two/ethics-survey-type-two.component';
import { ethics_survey_type_dialog } from './../../views/ethics-survey/evaluated-answer/ethics-survey-type-two/ethics-survey-type-dialog.component';
import { ReportsEthicsSurveyComponent, reports_ethics_survey_dialog } from './../../views/ethics-survey/reports-ethics-survey/reports-ethics-survey.component';

import { QuestionsComponent } from '../../views/integriline/questions/questions.component';
import { RelationsComponent } from '../../views/integriline/relations/relations.component';

import { Routes } from '@angular/router';
import {ReportComponent} from '../../views/integriline/report/report.component';
import {ChartsComponent} from '../../views/integriline/charts/charts.component';
import {ComunicaArticulosComponent} from '../../views/comunika/comunica-articulos/comunica-articulos.component';
import {ComunicaEvaluacionesComponent} from '../../views/comunika/comunica-evaluaciones/comunica-evaluaciones.component';
import {ComunicaEventosComponent} from '../../views/comunika/comunica-eventos/comunica-eventos.component';
import {ComunicaEncuestasComponent} from '../../views/comunika/comunica-encuestas/comunica-encuestas.component';
import {ComunicaCuestionariosComponent} from '../../views/comunika/comunica-cuestionarios/comunica-cuestionarios.component';
import {ConfiguracionEmpresaComponent} from '../../views/comunika/configuracion-empresa/configuracion-empresa.component';
import {ConfiguracionCategoriasComponent} from '../../views/comunika/configuracion-categorias/configuracion-categorias.component';
import {ConfiguracionUsuariosComponent} from '../../views/comunika/configuracion-usuarios/configuracion-usuarios.component';
import {EstadisticasEventosComponent} from '../../views/comunika/estadisticas-eventos/estadisticas-eventos.component';
import {EstadisticasArticulosComponent} from '../../views/comunika/estadisticas-articulos/estadisticas-articulos.component';
import {EstadisticasGuardadosComponent} from '../../views/comunika/estadisticas-guardados/estadisticas-guardados.component';
import {EstadisticasUsuariosComponent} from '../../views/comunika/estadisticas-usuarios/estadisticas-usuarios.component';
import {EstadisticasActividadComponent} from '../../views/comunika/estadisticas-actividad/estadisticas-actividad.component';
import {EstadisticasActivosComponent} from '../../views/comunika/estadisticas-activos/estadisticas-activos.component';
import {EstadisticasEvaluacionesComponent} from '../../views/comunika/estadisticas-evaluaciones/estadisticas-evaluaciones.component';
import {EstadisticasEncuestasComponent} from '../../views/comunika/estadisticas-encuestas/estadisticas-encuestas.component';
import {EstadisticasCuestionariosComponent} from '../../views/comunika/estadisticas-cuestionarios/estadisticas-cuestionarios.component';
import { ComunicaEncuestasResultadosComponent } from '../../views/comunika/comunica-encuestas-resultados/comunica-encuestas-resultados.component';
import { IntegrityReportsComponent } from '../../views/manager/integrity-reports/integrity-reports.component';
import { SurveyHomeComponent } from '../../views/manager/home/survey-home/survey-home.component';
import { IntegrityHomeComponent } from '../../views/manager/home/integrity-home/integrity-home.component';

export const routes: Routes = [
  {
    path: 'manager',
    component: ManagerComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeManagerComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'permissions',
        component: PermissionComponent,
        canActivate: [ChildGuardService, AuthGuardService]
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'collaborators',
        component: CollaboratorComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'collaborator/:id',
        component: CreateCollaboratorComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'reports',
        component: ChartsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'catalogs',
        component: CatalogsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'configuration',
        component: ConfigurationComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'config-profiles',
        component: ConfigJobProfilesComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'groups',
        component: GroupsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'category',
        component: CategoryComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'category-groups/:id',
        component: CategoryAddonsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'module',
        component: ModuleComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'Survey',
        component: ConfigProyectComponent
      },
      {
        path: 'SurveyAnswers',
        component: EvaluatedAnswerComponent
      },
      {
        path: 'SurveyAnswersTypeOne',
        component: EthicsSurveyTypeOneComponent
      },
      {
        path: 'SurveyAnswersTypeTwo',
        component: EthicsSurveyTypeTwoComponent
      },
      {
        path: 'ethics_survey_type_dialog',
        component: ethics_survey_type_dialog
      },
      {
        path: 'SurveyCreate',
        component: CreateConfigComponent
      },
      {
        path: 'survey-home',
        component: SurveyHomeComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'integrity-home',
        component: IntegrityHomeComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'SurveyDelete',
        component: DeleteConfigComponent
      },
      {
        path: 'SurveyUpdate/:id',
        component: UpdateConfigComponent
      },
      {
        path: 'ReportsEthicsSurvey',
        component: ReportsEthicsSurveyComponent
      },
      {
        path: 'ReportsEthicsSurveyDialog',
        component: reports_ethics_survey_dialog
      },

      /***************************************************************************************
      *************************** INTEGRILINE MANAGER ROUTES *********************************
      ***************************************************************************************/

      {
        path: 'relations',
        component: RelationsComponent,
        canActivate: [ChildGuardService, AuthGuardService]
      },
      {
        path: 'questions',
        component: QuestionsComponent,
        canActivate: [ChildGuardService, AuthGuardService]
      },
      {
        path: 'group-addons',
        component: GroupAddonsComponent,
        canActivate: [ChildGuardService, AuthGuardService]
      },
      {
        path: 'group-questions',
        component: GroupQuestionsComponent,
        canActivate: [ChildGuardService, AuthGuardService]
      },
      {
        path: 'group-handlers',
        component: GroupHandlersComponent,
        canActivate: [ChildGuardService, AuthGuardService]
      },
      {
        path: 'manage-users',
        component: ManageUserComponent,
        canActivate: [ChildGuardService, AuthGuardService]
      },
      {
        path: 'create-users',
        component: CreateUserComponent,
        canActivate: [ChildGuardService, AuthGuardService]
      },

      {
        path: 'tickets',
        component: HandlerHomeComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'ticket/:id',
        component: HandlerTicketComponent,
        canActivate: [AuthGuardService]
      },

      /***************************************************************************************
       *************************** COMUNIKA MANAGER ROUTES ***********************************
       ***************************************************************************************/

      {
        path: 'comunica-articulos',
        component: ComunicaArticulosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-articulos/:id',
        component: ComunicaArticulosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-articulos/create',
        component: ComunicaArticulosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-evaluaciones',
        component: ComunicaEvaluacionesComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-evaluaciones/:id',
        component: ComunicaEvaluacionesComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-evaluaciones/create',
        component: ComunicaEvaluacionesComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-evaluaciones/resultados/:id',
        component: ComunicaEvaluacionesResultadosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-eventos',
        component: ComunicaEventosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-eventos/:id',
        component: ComunicaEventosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-eventos/create',
        component: ComunicaEventosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-encuestas',
        component: ComunicaEncuestasComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-encuestas/resultados/:id',
        component: ComunicaEncuestasResultadosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-encuestas/:id',
        component: ComunicaEncuestasComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-encuestas/create',
        component: ComunicaEncuestasComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-cuestionarios',
        component: ComunicaCuestionariosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-cuestionarios/create',
        component: ComunicaCuestionariosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-cuestionarios/:id/preguntas',
        component: ComunicaCuestionariosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'comunica-cuestionarios/:id/retros',
        component: ComunicaCuestionariosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'configuracion-empresa',
        component: ConfiguracionEmpresaComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'configuracion-categorias',
        component: ConfiguracionCategoriasComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'configuracion-categorias/:id',
        component: ConfiguracionCategoriasComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'configuracion-usuarios',
        component: ConfiguracionUsuariosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'configuracion-usuarios/:id',
        component: ConfiguracionUsuariosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'estadisticas-eventos',
        component: EstadisticasEventosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'estadisticas-articulos',
        component: EstadisticasArticulosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'estadisticas-guardados',
        component: EstadisticasGuardadosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'estadisticas-usuarios',
        component: EstadisticasUsuariosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'estadisticas-actividad',
        component: EstadisticasActividadComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'estadisticas-activos',
        component: EstadisticasActivosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'estadisticas-evaluaciones',
        component: EstadisticasEvaluacionesComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'estadisticas-encuestas',
        component: EstadisticasEncuestasComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'estadisticas-cuestionarios',
        component: EstadisticasCuestionariosComponent,
      },

      /***************************************************************************************
      *********************************** HONESTIDAD *****************************************
      ***************************************************************************************/

      {
        path: 'job-profile',
        component: JobProfileComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'integrityreports',
        component: IntegrityReportsComponent,
        canActivate: [AuthGuardService]
      },

      {
        path:'evaluation-surveys',
        component:SurveysComponent
      },

      /***************************************************************************************
      ********************************* DEFAULT ROUTES ***************************************
      ***************************************************************************************/

      {
        path: '**',
        redirectTo: 'home'
      }

    ]
  }
];
