import { ConectorJsonService } from './conector-json.service';
import { UserIdleService } from 'angular-user-idle';
import { Injectable } from '@angular/core';
import * as jwt from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  constructor(private userIdle: UserIdleService, private conector: ConectorJsonService) { }

  isAuthenticated() {

    const token = localStorage.getItem('token');

    return (token == null) ? false : true;

  }

  isAuthEvaluated() {

    const token = localStorage.getItem('token_evaluaciones');

    return (token == null) ? false : true;

  }

  userdata() {

    const token = localStorage.getItem('token');

    return jwt(token);

  }

  wakeUp() {
    this.userIdle.resetTimer();
  }

  async setLanguage() {

    let customLng = localStorage.getItem('user_lng');

    if( customLng == null ) {
      customLng = navigator.language;
    }

    return customLng.includes('es') ? 'es' : 'en';

  }

}
