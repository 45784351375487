import { LicenseComponent } from './views/authentication/license/license.component';
import { CallcenterComponent } from './views/integriline/callcenter/callcenter.component';
import { AuthGuardService } from './services/auth-guard.service';
import { InformationComponent } from './views/integriline/information/information.component';
import { TrackingComponent } from './views/integriline/tracking/tracking.component';
import { ErrorComponent } from './views/error/error.component';
import { SelectionComponent } from './views/authentication/selection/selection.component';
import { ReportComponent } from './views/integriline/report/report.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ToReportComponent } from './views/validators/to-report/to-report.component';
import { ToDoComponent } from './views/validators/to-do/to-do.component';
import { LoginappComponent } from './views/internal/loginapp/loginapp.component';
import { ReportappComponent } from './views/internal/reportapp/reportapp.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/license',
		pathMatch: 'full',
	},
	{
		path: 'selection',
		component: SelectionComponent,
	},
	{
		path: 'report',
		component: ReportComponent,
	},
	{
		path: 'callcenter',
		component: CallcenterComponent,
	},
	{
		path: 'license',
		component: LicenseComponent,
	},
	{
		path: 'link-to/:id',
		component: ToReportComponent,
	},
	{
		path: 'to-do/:id',
		component: ToDoComponent,
	},
	{
		path: 'tracking',
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full',
			},
			{
				path: 'login',
				component: TrackingComponent,
			},
			{
				path: 'information',
				component: InformationComponent,
				canActivate: [AuthGuardService],
			},
		],
	},
	{
		path: '404',
		component: ErrorComponent,
	},
	{
		path: 'management',
		component: LoginappComponent,
	},
	{
		path: 'reportapp',
		component: ReportappComponent,
	},
	{
		path: '**',
		redirectTo: 'selection',
	},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

