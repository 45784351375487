import { Router } from '@angular/router';
import { ResponseHandler } from './../../../interfaces/ResponseHandler';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { Pagination } from '../../../models/pagination';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { ModalChildrenComponent } from '../../manager/modals/modal-children/modal-children.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  pagination = new Pagination();
  categoryForm: FormGroup;
  masterGroups = [];
  length =  0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 15];
  isEditable = false;
  selected = null;
  parent_list = [];
  tree_master = [];

  constructor(private fb: FormBuilder, private conector: ConectorJsonService, public dialog: MatDialog, private auth: AuthenticationService, private translate: TranslateService, private router: Router) {

    this.categoryForm = this.fb.group({
      parent: [0, [Validators.required]],
      name: [null, Validators.required],
      description: [null],
      visible: [true, Validators.required]
    });

    this.auth.setLanguage().then( k => this.translate.setDefaultLang(k) );

  }

  async ngOnInit() {

    this.conector.get_category().subscribe( (res: ResponseHandler) => {
      this.length = res.msg.length;
      this.masterGroups = res.data;
    });

    this.loadTree();

  }

  loadTree() {

    this.conector.parent_category().subscribe( (k:ResponseHandler) => {
      this.parent_list = k.data;
    });

    this.conector.tree_category().subscribe( (k: ResponseHandler) => {
      console.info(k);
      this.tree_master = k.data;
    });
  }

  updateRows($events) {
    const { pageIndex, pageSize } = $events;

    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.masterGroups = [];

    this.conector.groups_get(this.pageIndex, this.pageSize).subscribe( (x: any) => {
      this.masterGroups = x.data;
    });
  }

  onClick(formdata) {

    if (this.isEditable === false) {

      this.conector.post_category(formdata).subscribe( async (d: any) => {

        this.categoryForm.reset();

        Object.keys(this.categoryForm.controls).forEach(key =>{
          this.categoryForm.controls[key].setErrors(null)
        });

        this.loadTree();

        const msg = await this.translate.get("success").toPromise();

        Swal.fire({ position: 'center', icon: 'success', title: msg, showConfirmButton: false, timer: 1000 });

      });

    } else {

      Object.keys(formdata).forEach( key => {
        this.selected[key] = formdata[key];
      });

      this.conector.groups_put(this.selected).subscribe( async (d) => {

        this.categoryForm.reset();
        this.isEditable = false;

        const msg = await this.translate.get("success").toPromise();
        Swal.fire({ position: 'center', icon: 'success', title: msg, showConfirmButton: false, timer: 1000 });

      });

    }
  }

  editRow(row) {
    this.isEditable = true;
    this.selected = row;
    Object.keys(this.categoryForm.controls).forEach(key => {
      this.categoryForm.get(key).setValue( row[key] );
    });
  }

  setPermission(row) {
    this.router.navigateByUrl('/manager/category-groups/' + row.id);
  }

}
