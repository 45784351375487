import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
  })
  
  export class IntegrityReportService {

    constructor(private http: HttpClient) { }

    evaluations_get(formdata, init = 0, limit = 10) {

      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('token'),
        'Content-type': 'application/json'
      });
  
      return this.http.post(`${environment.server}/integrityreport?limit=${limit}&offset=${init}`, formdata, { headers });
    }

    downloadselection_get(id) {

      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('token'),
        'Content-type': 'application/json'
      });
  
      return this.http.get(`${environment.server}/integrityreport/downloadselection/${id}`, { headers });
    }
  }