import { ResponseHandler } from './../../interfaces/ResponseHandler';
import { AuthenticationService } from './../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { ConectorKnowService } from './../../services/conector-know.service';
import { environment } from '../../../environments/environment';
import { ConectorJsonService } from '../../services/conector-json.service';
import { SharedCommunicationService } from '../../services/shared-communication.service';
import { ManagerItems } from './manager-items';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationEnd } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TycosComponent } from '../../views/manager/modals/tycos/tycos.component';
import { filter } from 'rxjs-compat/operator/filter';
import { Navigation } from 'selenium-webdriver';

interface sideMenu {
  id: number,
  route: string,
  icon: string,
  application: string,
  parent: number,
  type: string,
  children: sideMenu[]
}

/** @title Responsive sidenav */
@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.css']
})

export class ManagerComponent implements OnDestroy, OnInit {

  @ViewChild(TycosComponent, { static: true }) tycos: TycosComponent;

  mobileQuery: MediaQueryList;
  green: boolean;
  dir = 'ltr';

  blue: boolean;
  dark: boolean;
  minisidebar: boolean = true;

  boxed: boolean;
  danger: boolean;
  url: string;

  sidebarOpened;
  public showSearch = false;

  public config: PerfectScrollbarConfigInterface = {};
  public _mobileQueryListener: () => void;
  userIcon = '/assets/images/anon.jpg';  //environment.public;

  makeHttp;
  menuItems = [];
  modules = [];
  username = null;
  language = "en-US";
  isMultiple = true;
  dynamicLen = 50;

  scrollToTop() {
    document.querySelector('.page-wrapper').scroll({
      top: 0,
      left: 0
    });
  }

  showMenuBar = true;
  alternative: sideMenu[] = [];
  sbutton = null;

  constructor(
    private conector: ConectorJsonService,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    public main: ManagerItems,
    private know: ConectorKnowService,
    private translate: TranslateService,
    private auth: AuthenticationService,
    private shared: SharedCommunicationService) {

    this.mobileQuery = media.matchMedia('(min-width: 768px)');

    this._mobileQueryListener = () => changeDetectorRef.detectChanges();

    this.mobileQuery.addListener(this._mobileQueryListener);

    this.shared.httpCall.subscribe(k => this.makeHttp = k);

    localStorage.setItem('mainRoute', '/manager');

    localStorage.setItem('mainLayout', 'manager');

  }

  async ngOnInit(): Promise<void> {

    this.router.events.subscribe(async event => {

      if (event instanceof NavigationEnd) {

        if (event.url == '/') {
          return;
        }

        localStorage.setItem('lastLocation', event.url);

        await this.conector.savePath({ route: event.url }).toPromise();

      }

    });

    const tdata: any = await this.conector.tycos().toPromise();

    if (tdata !== undefined && tdata.data == false) {
      this.tycos.bsOpen();
    }

    this.conector.modules_by_user().subscribe((x: any) => {

      const { data } = x;

      this.modules = data;

      localStorage.setItem("user_modules", JSON.stringify(this.modules));

      if (localStorage.getItem("lastLocation") == null || localStorage.getItem("lastLocation") == '/manager') {
        this.loadComplements(data[0].nickname);

      }

    });

    this.conector.user_permission().subscribe((x: any) => {

      const { data, msg } = x;

      this.menuItems = data.permissions;

      /*
        Menu estatico para handler
      */

      if (data.role == 4) {

        this.menuItems.push({
          route: 'tickets',
          icon: 'dashboard',
          application: 'Tickets'
        });

        this.showMenuBar = false;

      }

      this.username = data.username;

      fetch(`${environment.public}/logos/${msg}`).then((res: Response) => {
        if (res.ok == true) {
          this.userIcon = `${environment.public}/logos/${msg}`;
        }
      });

    });

    if (localStorage.getItem('menu-sidebar') !== null) {
      this.alternative = JSON.parse(localStorage.getItem('menu-sidebar'));
    }

    const lng: any = await this.conector.getLanguage().toPromise();

    let apply = navigator.language;

    if (lng.data != null) {
      apply = lng.data;
    }

    localStorage.setItem('user_lng', apply);

    this.language = apply;

    this.auth.setLanguage().then(k => this.translate.setDefaultLang(k));

    this.shared.reloadSite.subscribe((data: any) => {
      if (data != null && data != undefined) {
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(data.route).then(() => this.shared.reloadSite.next(null));
        });
      }
    });

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  log_out() {
    localStorage.clear();
    this.router.navigateByUrl('/');
  }

  async loadComplements(moduleName) {

    this.router.navigateByUrl('/manager');

    localStorage.setItem('module', moduleName);

    this.sbutton = moduleName;

    if (moduleName === 'Comunika') {

      const { token, user }: any = await this.know.auth().toPromise();

      if (token == null) {
        return Swal.fire('Error', 'Acceso invalido, verificar credenciales.', 'error');
      }

      localStorage.setItem('know_token', token);

      localStorage.setItem('know_user', JSON.stringify(user));

      localStorage.setItem('know_session', user.company_session_app);

    }

    this.conector.extend_permissions(moduleName).subscribe((k: any) => {

      const { ok, data } = k;

      if (ok === true) {

        const master = data.filter((x) => x.parent === 0);

        data.forEach((item: sideMenu) => {

          const idx = master.findIndex(x => x.id === item.parent);

          if (idx >= 0) {

            if (master[idx].children == null) {
              master[idx].children = [];
            }

            item.type = 'sub';
            master[idx].children.push(item);

          }

        });

        this.alternative = master;

        localStorage.setItem('menu-sidebar', JSON.stringify(this.alternative));

        //precargar si tiene inicio

        const idx = this.alternative.findIndex(x => x.application.toLowerCase() == 'home');

        if (idx >= 0) this.router.navigateByUrl(this.alternative[idx].route);

      }

    });

  }

  setBar(value: boolean, event: any) {
    if (event.hasOwnProperty('fromElement') && event.fromElement.className == 'a.ng-star-inserted') {
      this.minisidebar = value;
    }
  }

  drop(e: CdkDragDrop<any[]>) {

    this.reducer(e);

    console.info('Drop', e);

    console.info('Sorter', this.modules);

    let nodes = [];

    let fx = 1;

    this.modules.forEach(item => {
      nodes.push({ module: item.idModulo, order: fx })
      fx++;
    });

    this.conector.update_modules(nodes).subscribe((res: ResponseHandler) => {
      console.info('update', res);
    });

  }

  reducer(event) {
    return (event.previousContainer === event.container) ?
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex) :
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
  }

  tycosResponse(res) {

    console.info('Trex', res);

    if (res === true) {

      this.conector.tycos_put({ tycos: true }).subscribe((e: any) => {

        console.info('tycos', e);

        Swal.fire('Perfecto!', 'Terminos y condiciones aceptadas.', 'success').then(() => this.tycos.bsClose());

      });

    } else {
      Swal.fire('Alerta!', 'Se tienen que aceptar los terminos y condiciones', 'error');
    }

  }

}
