import { Component, OnInit, Inject, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ConectorJsonService } from '../../../../services/conector-json.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-surveys-answers',
  templateUrl: './surveys-answers.component.html',
  styleUrls: ['./surveys-answers.component.css']
})
export class SurveysAnswersComponent implements OnInit {

  @Input() openModal:boolean = false;
  @Input() answers: any = [];
  @Input() question: any = {};

  @Output() addanswer: EventEmitter<any> = new EventEmitter();
  @Output() deleteAnswer: EventEmitter<any> = new EventEmitter();

  answerFrm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.answerFrm = this.fb.group({
      Name:[null, Validators.required]
    })
  }

  ngOnInit() {

  }

  dimiss(){
    this.openModal = false;
  }

  saveTo(formdata) {
    this.addanswer.emit(formdata);
    this.answerFrm.reset();
  }

  deleteRow(ev) {
    this.deleteAnswer.emit(ev);
  }

}
