import { ConectorJsonService } from './../../../services/conector-json.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-supervisor',
  templateUrl: './home-supervisor.component.html',
  styleUrls: ['./home-supervisor.component.css']
})
export class HomeSupervisorComponent implements OnInit {

  height = 400;
  type = 'pie2d';
  dataSourceHandler: Object;
  dataSourceGroups: Object;
  dataSourceStatus: Object;
  dataFormat = 'json';

  _handler = [];
  _groups = [];
  _status = [];

  chart_handler = {
    chart: {
      caption: '',
      plottooltext: '',
      showlegend: '1',
      showpercentvalues: '',
      legendposition: 'right',
      usedataplotcolorforlabels: '',
      theme: 'fusion'
    },
    data: []
  };

  chart_group = {
    chart: {
      caption: '',
      plottooltext: '',
      showlegend: '1',
      showpercentvalues: '',
      legendposition: 'right',
      usedataplotcolorforlabels: '',
      theme: 'fusion'
    },
    data: []
  };


  chart_status = {
    chart: {
    caption: '',
    subcaption: '',
    numbersuffix: '',
    showsum: '1',
    plottooltext:
      '$label reportó <b>$dataValue</b> casos de $seriesName',
    theme: 'fusion',
    drawcrossline: '1'
  },
  categories: [
    {
      category: []
    }
  ],
  dataset: [
    {
      seriesname: 'new',
      data: [
        {
          value: '400'
        }
      ]
    },
    {
      seriesname: 'acknowledged',
      data: [
        {
          value: '350'
        }
      ]
    },
    {
      seriesname: 'inprogress',
      data: [
        {
          value: '210'
        }
      ]
    },
    {
      seriesname: 'standby',
      data: [
        {
          value: '180'
        }
      ]
    },
    {
      seriesname: 'aborted',
      data: [
        {
          value: '60'
        }
      ]
    },
    {
      seriesname: 'solved',
      data: [
        {
          value: '60'
        }
      ]
    }
  ]
};

  constructor(private conector: ConectorJsonService) {
    this.dataSourceHandler = this.chart_handler;
    this.dataSourceGroups = this.chart_group;
    this.dataSourceStatus = this.chart_status;
  }

  async ngOnInit() {

    await this.conector.supervisor_chart().subscribe( (s: any) => {

      const { ok, data, msg} = s;

      this.chart_handler.data = data.handler;
      this.dataSourceHandler = this.chart_handler;

      this.chart_group.data = data.groups;
      this.dataSourceGroups = this.chart_group;

      this._groups = data.groups;
      this._handler = data.handler;
      this._status = data.status;

      const categories = [{
        category: []
      }];

      const dataset = [];

      data.status.forEach( x => {

        categories[0].category.push({ label: x.name });

        x.status.forEach( tz => {

          const gotIndex = dataset.findIndex( fx => fx.seriesname === tz.status);

          if ( gotIndex  === -1) {

            dataset.push({
              seriesname : tz.status,
              data: [{
                value: tz.total
              }]
            });

          } else {

            dataset[gotIndex].data.push({ value: tz.total });

          }

        });

      });

      this.chart_status.dataset = dataset;

      this.chart_status.categories = categories;

      this.dataSourceStatus = this.chart_status;

    });

  }

}
