import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.css']
})
export class HeaderNavComponent implements OnInit {

  @Input() enabledOut:boolean = false;

  @Input() alternate = false;

  constructor(public router: Router) { }

  ngOnInit() { }

  log_out() {
    localStorage.clear();
    this.router.navigateByUrl('/');
  }

}
