import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { Http } from '@angular/http'
import { configModelType1,configModelType2, answerUsers } from '../../views/ethics-survey/ViewModel/config-model/config-model.component';

@Injectable({
  providedIn: 'root'
})

export class ConnectorEthicsSurveyService {

    company = '65723463581';
    
    constructor(private http: HttpClient) { }

    ethicsSurveyGet(filterPagin = 0, init= 0, limit= 5) {
      //return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/Get`);
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('token'),
        'Content-type': 'application/json'
      });
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/Get?limit=${limit}&offset=${init}&active=${filterPagin}`, { headers });
    }

    ethicsSurveyGetId(id : number) {
      //return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/Get`);
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('token'),
        'Content-type': 'application/json'
      });
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetId/${id}`, { headers });
    }

    ethicsSurveyGetIdUpdate(id) {
      //return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/Get`);
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('token'),
        'Content-type': 'application/json'
      });
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetUpdateId/${id}`, { headers });
    }

    ethicsSurveyDelete(idSurvey) {
      //return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/Get`);
      const headers = new HttpHeaders({
        'Authorization': localStorage.getItem('token'),
        'Content-type': 'application/json'
      });
      return this.http.delete(`${environment.server}/EthicsSurvey/SuveyConfig/DeleteSurvey/${idSurvey}`, { headers });
    }

    ethicsSurveyUpdate(formdata: object) {
      const headers = new HttpHeaders({
        'Authorization': this.company,
        'Content-type': 'application/json'
      });
      let varformdata = JSON.stringify(formdata);
      return this.http.post(`${environment.server}/EthicsSurvey/SuveyConfig/UpdateSurvey`, varformdata, {headers}) ;
    }

    categorySurveyGet() {
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetCategory`);
    }

    seriesSurveyGet(id : string) {
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetSeries/`+id);
    }

    qestionsSurveyGet() {
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetQuestions`);
    }

    qestionsTypeSurveyGet(id : string) {
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetQuestionsType/`+id);
    }

    
    userGroupsGet() {
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetGroup`);
    }

    post(formdata: configModelType1) {
      const headers = new HttpHeaders({
        'Authorization': this.company,
        'Content-type': 'application/json'
      });
      let varformdata = JSON.stringify(formdata);
      return this.http.post(`${environment.server}/EthicsSurvey/SuveyConfig/Post`, varformdata, {headers}) ;
    }

    postNom035(formdata: configModelType2) {
      const headers = new HttpHeaders({
        'Authorization': this.company,
        'Content-type': 'application/json'
      });
      let varformdata = JSON.stringify(formdata);
      debugger;
      return this.http.post(`${environment.server}/EthicsSurvey/SuveyConfig/PostNom035`, varformdata, {headers}) ;
    }

    postAnswerUser(formdata: answerUsers) {
      const headers = new HttpHeaders({
        'Authorization': this.company,
        'Content-type': 'application/json'
      });
      let varformdata = JSON.stringify(formdata);
      return this.http.post(`${environment.server}/EthicsSurvey/SuveyConfig/PostAnswersUser`, varformdata, {headers}) ;
    }

    questionsWorkingEnvironmentGet() {
      const headers = new HttpHeaders({
        'Authorization': this.company,
        'Content-type': 'application/json'
      });
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetQuestionsWorkingEnvironment`);
    }

    evaluatedAnswerSurveyGet() {
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetSurveyEvaluted`);
    }

    evaluatedAnswerSurveyTypeOneGet(idSurvey : string, idUser : string) {
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetSurveyEvalutedTypeOne/${idSurvey}/${idUser}`);
    }

    evaluatedAnswerSurveyTypeTwoGet(idSurvey : string, idUser : string) {
      
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetSurveyEvalutedTypeTwo/${idSurvey}/${idUser}`);
    }

    PdfGetReportEngagement(id_SurveyConfig : number, id_Group : string ) {
      const headers = new HttpHeaders({
        'Authorization': this.company,
        'Content-type': 'application/json'
      });
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetReportEngagement/${id_SurveyConfig}/${id_Group}`, {headers});
    }

    PdfGetReportNom035(id_SurveyConfig : number, IdUsers : number) {
      const headers = new HttpHeaders({
        'Authorization': this.company,
        'Content-type': 'application/json'
      });
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetReportNom035/`+id_SurveyConfig+`/`+IdUsers, {headers});
    }

    PdfGetReportClima(id_SurveyConfig : number, id_Group : string) {
      const headers = new HttpHeaders({
        'Authorization': this.company,
        'Content-type': 'application/json'
      });
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetReportClima/${id_SurveyConfig}/${id_Group}`, {headers});

    }

    GetUsersGroup(id : number) {
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetUsersGroup/`+id);
    }

    GetUsersProyect(id : number) {
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/GetUsersProyect/`+id);
    }

    tree_category() {
      const headers = new HttpHeaders({
        'Authorization': this.company,
        'Content-type': 'application/json'
      });
      return this.http.get(`${environment.server}/EthicsSurvey/SuveyConfig/tree`, {headers});
    }
}