import { ConectorJsonService } from './../../../services/conector-json.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { param } from 'jquery';

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.css']
})

export class EnterpriseComponent implements OnInit {

  codigo = null;

  constructor(private route: Router, private snap: ActivatedRoute, private conector: ConectorJsonService) {
    const snapshot = snap.snapshot;
  }

  ngOnInit() {

    this.snap.paramMap.subscribe( (ks: any) => {

      const { params } = ks;

      this.codigo = params.id;

      this.conector.validate_enterprise(this.codigo).subscribe( (r: any) => {

        const { ok, data, msg } = r;

        if (ok === true) {
          localStorage.setItem('company', data.id);
          this.route.navigateByUrl('/report');

        } else {
          this.codigo = 'El código es invalido';
        }

      });

    });

    // this.codigo = 'da39a3ee5e6b4b0d3255bfef95601890afd80709';

  }

}
