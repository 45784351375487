import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
  render: string;
  permission: Array<any>;
}

const MENUITEMS = [
  {
    state: 'home',
    name: 'Home',
    type: 'link',
    render: 'material',
    icon: 'dashboard',
    permission: []
  },
  {
    state: 'users',
    name: 'Users',
    type: 'link',
    icon: 'supervised_user_circle',
    render: 'material',
    permission: [],
    children: []
  },
  {
    state: 'collaborator',
    name: 'Collaborator',
    type: 'link',
    icon: 'supervised_user_circle',
    render: 'material',
    permission: [],
    children: []
  },
  {
    state: 'groups',
    name: 'Groups',
    type: 'link',
    icon: 'category',
    render: 'material',
    permission: [],
  },
  {
    state: 'catalogs',
    name: 'Catalogs',
    type: 'link',
    icon: 'reorder',
    render: 'material',
    permission: [],
  },
  {
    state: 'configuration',
    name: 'Configuration',
    type: 'link',
    icon: 'build',
    render: 'material',
    permission: [],
  },
  {
    state: 'Survey',
    name: 'Survey',
    type: 'link',
    icon: 'build',
    render: 'material',
    permission: [],
  }
  ,
  {
    state: 'SurveyAnswers',
    name: 'SurveyAnswers',
    type: 'link',
    icon: 'build',
    render: 'material',
    permission: [],
  },
  {
    state:'evaluation-surveys',
    name:'Evaluaciones',
    type: 'link',
    icon: 'build',
    render: 'material',
    permission: []
  }

];

@Injectable()
export class ManagerItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
