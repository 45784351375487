import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-jobprofile-surveys',
  templateUrl: './jobprofile-surveys.component.html',
  styleUrls: ['./jobprofile-surveys.component.css']
})
export class JobprofileSurveysComponent implements OnInit {

  @Input() modules = [];
  @Output() update: EventEmitter<any> = new EventEmitter();

  openModal:boolean = false;

  constructor() { }

  ngOnInit() {

  }

  saveAction() {
    this.update.emit(this.modules);
  }

}
