export class Pagination {

  pageSizeOptions: number[] = [5, 10, 25, 100];
  length = 0;
  limit = 5;
  init = 0;
  end = 5;
  pageSize = 0;

  changePage = (event) => {

    const pageIndex = event['pageIndex'];
    this.limit = event['pageSize'];

    length = Math.max(length, 0);
    const startIndex = pageIndex * this.limit;
    const endIndex = startIndex < length ? Math.min(startIndex + this.limit, length) : startIndex + this.limit;

    this.init = startIndex;
    this.end = endIndex;

  }

  setPageIndex = ( index) => {
    this.init = index;
  }

}
