import { EvaInstructionsComponent } from './eva-instructions/eva-instructions.component';
import { SharedCommunicationService } from './../../../services/shared-communication.service';
import Swal from 'sweetalert2';
import { ResponseHandler } from './../../../interfaces/ResponseHandler';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { validateBasis } from '@angular/flex-layout';
import { WebcamInitError, WebcamUtil, WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { EvaInstructionsGeneralComponent } from './eva-instructions-general/eva-instructions-general.component';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})

export class SurveyComponent implements OnInit {

  @ViewChild(EvaInstructionsComponent, {static: true}) instructions:EvaInstructionsComponent;
  @ViewChild(EvaInstructionsGeneralComponent, {static: true}) generalInstructions:EvaInstructionsGeneralComponent;

  surveys:any = [];

  selectedSurvey = null;

  section = null;

  question = null;

  userip = null;

  factor = 0;

  hasCamera:boolean = false;

  multipleWebcamsAvailable: boolean = false;
  public showWebcam = true;
  public allowCameraSwitch = true;
  public deviceId: string;
  public facingMode: string = 'environment';
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  constructor(private conector: ConectorJsonService, private shared: SharedCommunicationService) { }

  async ngOnInit() {

    this.userip = await this.conector.get_user_ip().toPromise()

    const { msg, data } :any =  await this.conector.getEvaluation().toPromise();

    this.surveys = data;

  }

  async abrirSurvey(row) {

    /*
      #rules generales
      Siempre mostrar instrucciones de la prueba

      #rules secciones
      en cada seccion mostrar instrucciones si tiene
      si no tiene evitar el modal

    */

    console.info('Cargar Survey', row);

    this.selectedSurvey = row;

    this.generalInstructions.startLen();

  }

  startSections(ev) {

    console.info('startSections', ev);

    this.section = this.getNextObject(this.selectedSurvey.sections);

    this.section.hasCamera = this.hasCamera;

    console.info(ev);

    console.info('PresentarSeccion', this.section);


    if(this.section) {

      if(this.section.instructions != null && this.section.instructions.length > 0) {

        this.instructions.startLen();

        this.section.visible = false;

      } else {

        this.section.visible = true;

        this.question = this.getNextObject(this.section.questions);

      }

    } else {

      Swal.fire('Completado', 'La evaluación ha sido finalizada', 'success');

      this.surveys = [];

      this.shared.httpCall.next(true);

      this.conector.getEvaluation().subscribe( (k:any)=> {

        this.surveys = k.data;

        this.shared.httpCall.next(false);

      });

    }

  }
  confirmSection(ev: any) {

    console.info('survey.confirm', ev);

    this.factor = ev.factor;

    this.section.visible = true;

    this.question = this.getNextObject(this.section.questions);

  }

  getNextObject(group: any) {
    return group.find( x => x.solved == null  || x.solved == undefined || x.solve == false);
  }

  saveQuestion(ev){

    const model = {
      IdQuestion: ev.idpregunta,
      IdEvaluation: this.selectedSurvey.evaluationNumber,
      Answer: (ev.idrespuesta == null) ? 0 : ev.idrespuesta,
      hasAnswer:( (ev.idrespuesta == 0 || ev.idrespuesta == null) ? false: true),
      IpHostName: this.userip.data
    }

    this.conector.postEvaluation(model).subscribe( (r: any) => {

      const idx = this.section.questions.findIndex( x => x.id == ev.idpregunta);

      this.section.questions[idx].solved = true;

      this.getNextQuestion();

    });

  }

  getNextQuestion() {

    this.section = this.getNextObject(this.selectedSurvey.sections);

    console.info("current", this.section);

    if(this.section != null && this.section != undefined) {
      const found = this.section.questions.findIndex( x => x.solved == null  || x.solved == undefined || x.solve == false);

      if( found >= 0 ) {

        this.question = this.section.questions[found];

        console.info('next-question', this.question);

      } else {

        this.section.solved = true;

        this.abrirSurvey(this.selectedSurvey);

      }

    }

  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
    }
  }

  public triggerSnapshot(): void {
    console.info('triggerSnapshot');
    this.trigger.next();
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.log('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
  }

  public get triggerObservable(): Observable<void> {

    console.info('triggerObservable');
    return this.trigger.asObservable();
  }

}
