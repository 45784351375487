import { FormGroup, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { ConectorKnowService } from './../../../services/conector-know.service';
import { Pagination } from './../../shared/pagination';
import { Component, OnInit } from '@angular/core';
import { ResponseKnow } from '../../../interfaces/ResponseHandler';
import FileSaver from 'file-saver';

@Component({
  selector: 'app-estadisticas-evaluaciones',
  templateUrl: './estadisticas-evaluaciones.component.html',
  styleUrls: ['./estadisticas-evaluaciones.component.css']
})

export class EstadisticasEvaluacionesComponent implements OnInit {

  pagination = new Pagination();

  areas = [];

  categorias = [];

  evaluaFrm: FormGroup;

  resume = {};

  constructor(private know: ConectorKnowService, private fb: FormBuilder) { 
    this.evaluaFrm = this.fb.group({
      area: null,
      categoria: null,
      inicio: null,
      final: null
    })
  }

  ngOnInit() {
    this.know.areas_list().subscribe( (res: ResponseKnow) => {
      this.areas = res.structure;
    })

    this.evaluaFrm.valueChanges.subscribe( item => {
      if(item.inicio != null && item.final != null) {
        this.know.report_events(this.evaluaFrm.value).subscribe( (res: ResponseKnow) => {
          this.resume = res.questions;
        })
      }
    })
  }

  onClick() {

    let ids = [];

    if( Array.isArray(this.resume) ) {
      this.resume.forEach( x => {
        ids.push(x.id);
      })
    }

    this.evaluaFrm.value.ids = ids.toString();

    this.know.exportar_evaluaciones(this.evaluaFrm.value).then( rs => {
      FileSaver( rs, 'reporte.xlsx');
    });

  }

  getCategories(ev) {
    this.know.articles_by_area(ev.target.value).subscribe( (res: ResponseKnow) => {
      console.info('Cats', res);
      this.categorias = res.categories;
    });
  }

  changePage(ev) {
    console.info(ev);
  }

}
