import { SharedCommunicationService } from '../../../services/shared-communication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { AuthenticationService } from '../../../services/authentication.service';
import Swal from 'sweetalert2';

import { CoppelApiService } from '../../../services/coppel-api.service';


@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css']
})
export class SelectionComponent implements OnInit {

  year = new Date().getFullYear();
  makeHttp = false;

  snav: any;
  showForm = false;
  selectedForm = 0;

  authForm: FormGroup;
  loginForm: FormGroup;

  isLogged = false;
  company: any = {};

  isLoading = false;
  require2FA = false;

  isCompleted = false;
  kindOF = 'password';

  contador = 0;
  isFired = false;
  userIp = null;

  items = ['Item 1', 'Item 2', 'Item 3'];

  paises: any = [];
  estados: any = [];
  regiones: any = [];
  municipios: any = [];
  empresas: any[] = [];
  pageToken: any;

  login: any;
  sm: any;

  constructor(
    private trans: TranslateService,
    private service: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private shared: SharedCommunicationService,
    private conector: ConectorJsonService,
    private coppel: CoppelApiService) {

    this.service.setLanguage().then(k => this.trans.setDefaultLang(k));

    this.authForm = this.fb.group({
      reportingId: ['34821370648', Validators.required],
      pais: [null, Validators.required],
      municipio: [null, Validators.required],
      estado: [null, Validators.required],
      empresa: [0, Validators.required],
      region: [null, Validators.required]
    });

    this.loginForm = this.fb.group({
      reportingId: [null, Validators.required],
      password: [null, Validators.required]
    });

  }

  async ngOnInit() {

    this.shared.httpCall.subscribe(k => this.isLoading = k);

    this.coppel.login().subscribe((cclogin: any) => {

      this.pageToken = cclogin.data.token;

      this.coppel.paises(cclogin['data']['token']).then((e: any) => {
        this.paises = e.data.response;
      });

      this.coppel.regiones(cclogin['data']['token']).then((e: any) => {
        this.regiones = e.data.response;
      });

      this.coppel.empresas(cclogin['data']['token']).then((e: any) => {
        this.empresas = e.data.response
      });
    });

    const { data }: any = this.conector.get_user_ip().toPromise();

    this.userIp = data;

    await this.shared.setJsonObject('userIp', { userIp: data });

  }

  getRegions(formdata) {
    this.coppel.estados(this.pageToken, formdata.pais).then((e: any) => {
      this.estados = e.data.response;
    });
  }

  getTowns(formdata) {

    this.coppel.municipios(this.pageToken, formdata.estado).then((e: any) => {

      if (e.data.estatus == 0) {
        Swal.fire({
          title: 'Error', text: e.data.mensaje, icon: 'error', customClass: {
            confirmButton: 'round-me',
            cancelButton: 'round-me'
          },
        });
      } else {
        this.conector.info('getstates', e);
        this.municipios = e.data.response;
      }

    });
  }

  async setForm(event) {
    this.showForm = true;
    this.selectedForm = event;
    if (event == 2) {
      await this.router.navigateByUrl('/report');
    }
  }

  validateTicket(e) {

    this.conector.info('form ticket', e);

    e.IpHostName = this.userIp;

    this.conector.report_auth(e).subscribe(async (k: any) => {

      const { ok, data }: any = k;

      if (ok === true && data !== null) {

        localStorage.setItem('token', data);

        await this.router.navigateByUrl('/tracking/information');

      } else {
        await Swal.fire({
          title: 'Error', text: 'Ticket incorrecto ó contraseña invalida', icon: 'error', customClass: {
            confirmButton: 'round-me',
            cancelButton: 'round-me'
          },
        });
      }

    });

  }

  toogleKind() {
    this.kindOF = (this.kindOF === 'text') ? 'password' : 'text';
  }

}
