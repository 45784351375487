import { param } from 'jquery';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ResponseHandler } from '../../../interfaces/ResponseHandler';
import { Pagination } from '../../../models/pagination';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConectorJsonService } from '../../../services/conector-json.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-category-addons',
  templateUrl: './category-addons.component.html',
  styleUrls: ['./category-addons.component.css']
})
export class CategoryAddonsComponent implements OnInit {

  pagination = new Pagination();
  categoryForm: FormGroup;
  handlerForm: FormGroup;
  questionForm: FormGroup;

  lang = 'en';
  parent = 0;

  question_list = [];
  handlers_list = [];

  myQuestions = [];
  myHandlers = [];
  data = { name: null };

  constructor(private fb: FormBuilder, private conector: ConectorJsonService, public dialog: MatDialog, private auth: AuthenticationService, private translate: TranslateService, private router: Router, private snap: ActivatedRoute) {

    this.categoryForm = this.fb.group({
      id: [0, Validators.required],
      parent: [0, [Validators.required]],
      name: [null, Validators.required],
      description: [null],
      visible: [true, Validators.required]
    });

    this.handlerForm  = this.fb.group({
      handler: [null, Validators.required]
    });

    this.questionForm = this.fb.group({
      question: [null, Validators.required]
    });

    this.auth.setLanguage().then( k => {
      this.translate.setDefaultLang(k);
      this.lang = k;
    });

  }

  async ngOnInit() {

    this.snap.paramMap.subscribe( async (p: Params) => {

      this.parent = p.params.id;

      const { data }: any = await this.conector.single_category(this.parent).toPromise();

      this.data = data;

      Object.keys(this.categoryForm.value).forEach( item => {
        this.categoryForm.get(item).setValue( data[item] );
      });

      try{

        await this.loadQuestions();

        await this.loadHandlers();

        await this.loadCategoryQuestions();

        await this.loadCategoryHandlers();

      } catch( e ) {
        console.info(e);
      }

    })

  }

  async loadQuestions() {
    this.conector.questions_get_list().subscribe( (k: ResponseHandler) =>  this.question_list = k.data);
  }

  async loadHandlers() {
    this.conector.category_allHandlers().subscribe( (k: ResponseHandler) => this.handlers_list = k.data);
  }

  async loadCategoryQuestions() {
    this.conector.category_questions(this.parent).subscribe( (k: ResponseHandler) =>  this.myQuestions = k.data);
  }

  async loadCategoryHandlers() {
    this.conector.category_handlers(this.parent).subscribe( (k: ResponseHandler) =>  this.myHandlers = k.data);
  }

  onClick(formdata) {
    this.conector.put_category(formdata).subscribe( async(d) => {
      const msg = await this.translate.get('process-completed').toPromise();
      Swal.fire({ position: 'center', icon: 'success', title: msg, showConfirmButton: false, timer: 1000 });
    })
  }

  async removeHandler(row) {

    Swal.fire({
      title: await this.translate.get('warning').toPromise(),
      text: await this.translate.get('action-irreversible').toPromise(),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#9F5CC0',
      cancelButtonText:  await this.translate.get('x-cancel').toPromise(),
      confirmButtonText: await this.translate.get('delete-now').toPromise()
    }).then((result) => {

      if (result.isConfirmed) {
        const model = {
          id: row,
          group: this.parent
        };
        this.conector.category_handlers_del(model).subscribe( (k: ResponseHandler) => {
          this.loadCategoryHandlers();
          this.loadCategoryQuestions();
        });
      }

    })

  }

  async removeItem(row) {

    Swal.fire({
      title: await this.translate.get('warning').toPromise(),
      text: await this.translate.get('action-irreversible').toPromise(),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#9F5CC0',
      cancelButtonText:  await this.translate.get('x-cancel').toPromise(),
      confirmButtonText: await this.translate.get('delete-now').toPromise()
    }).then((result) => {

      if (result.isConfirmed) {
        const model = {
          id: row,
          group: this.parent
        };
        this.conector.category_questions_del(model).subscribe( (k: ResponseHandler) => {
          this.loadCategoryHandlers();
          this.loadCategoryQuestions();
        });
      }

    })

  }

  saveHandler(formdata: any) {
    formdata.group = this.parent;
    this.conector.category_handlers_post(formdata).subscribe( (k: ResponseHandler) => {
      if(k.ok == true){

        this.loadCategoryHandlers();

        this.handlerForm.reset();

        Swal.fire('ok!', '', 'success');

      }else {
        Swal.fire('Error', k.msg, 'error');
      }

    })
  }

  saveQuestion(formdata: any) {
    formdata.group = this.parent;
    this.conector.category_questions_post(formdata).subscribe( (k: ResponseHandler) => {

      if(k.ok == true){

        this.loadCategoryQuestions();

        this.questionForm.reset();

        Swal.fire('ok!', '', 'success');

      }else {
        Swal.fire('Error', k.msg, 'error');
      }

    })
  }

}
