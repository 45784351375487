import { STEPPER_GLOBAL_OPTIONS, StepperOrientation } from '@angular/cdk/stepper';
import { environment } from './../../../../environments/environment';
import { AuthenticationService } from './../../../services/authentication.service';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-license',
	templateUrl: './license.component.html',
	styleUrls: ['./license.component.css'],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: true },
		},
	],
})
export class LicenseComponent implements OnInit {
	isLinear = true;

	makeHttp = false;

	languaje: any;

	company: any;

	data: any;

	tycos: any;

	orientation: StepperOrientation = 'vertical';

	year = new Date().getFullYear();

	welcome: any;

	snav: any;

	firstFormGroup: FormGroup;

	secondFormGroup: FormGroup;

	publicFolder = environment.public;

	constructor(
		private router: Router,
		private translate: TranslateService,
		private auth: AuthenticationService,
		private conector: ConectorJsonService
	) {
		this.auth.setLanguage().then((k: any) => {
			this.translate.setDefaultLang(k);
			this.languaje = k;
		});
	}

	ngOnInit() {
		localStorage.setItem('page_reloaded', null);

		localStorage.setItem('company', '34821370648');

		this.company = 34821370648;

		this.conector.company_tycos(this.company).then((d: any) => {
			const { ok, data, msg } = d;

			this.data = data;

			this.welcome = data.welcome_es; // ( this.languaje == 'es') ? data.welcome_es : data.welcome_en;

			this.tycos = data.tycos_es; //( this.languaje == 'es') ? data.tycos_es : data.tycos_en;
		});
	}

	openReport() {
		this.router.navigateByUrl('/selection');
	}
}
