import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Chart } from 'angular-highcharts';

@Component({
    selector: 'app-integrity-home',
    templateUrl: './integrity-home.component.html',
    styleUrls: ['./integrity-home.component.css']
  })
  export class IntegrityHomeComponent implements OnInit, AfterViewInit {

    chartEvaluaciones : Chart;
    chartRGI : Chart;

    ngAfterViewInit(): void {
        
        this.chartRGI = new Chart({
            chart: {
              type: 'pie'
            },
            title: {
                text: ' '
            },
            credits: {
              enabled: false
            },
            tooltip: {
                pointFormat: '{series.name}: {point.percentage:.1f}%'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: [{
                name: 'Porcentaje',
                colorByPoint: true,
                type: undefined,
                data: [{
                    name: 'Selección',
                    y: 2918,
                    color: '#970FF2'
                }, {
                    name: 'Seguimiento',
                    y: 1514,
                    color: '#0597F2' 
                }, {
                    name: 'Permanencia',
                    y: 1567,
                    color: '#49D907' 
                }, {
                    name: 'Salida',
                    y: 876,
                    color: '#EAF205'
                }, {
                  name: 'Perfiles',
                  y: 1567,
                  color: '#F24607' 
              }, {
                  name: 'Inteligencia',
                  y: 876,
                  color: '#28F5EF'
              }]
            }]
          });
        
          this.chartEvaluaciones = new Chart({
            chart: {
              type: 'column'
            },
            title: {
                text: ''
            },
            legend: { 
              enabled: false 
            },
            credits: {
              enabled: false
            },
            xAxis: {
                categories: [
                    'Ene-20',
                    'Feb-20',
                    'Mar-20',
                    'Abr-20',
                    'May-20',
                    'Jun-20',
                    'Jul-20',
                    'Ago-20',
                    'Sep-20',
                    'Oct-20',
                    'Nov-20',
                    'Dic-20',
                    'Ene-21',
                    'Feb-21',
                    'Mar-21'
                ],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: undefined,
                name: 'Selección',
                color: '#970FF2',
                data: [49, 71, 106, 129, 144, 176, 135, 148, 216, 194, 95, 54, 49, 71, 106]
        
            }, {
                type: undefined,
                name: 'Seguimiento',
                color: '#0597F2',
                data: [83, 78, 98, 93, 106, 84, 105, 104, 91, 83, 106, 92, 83, 78, 98]
        
            }, {
                type: undefined,
                name: 'Permanencia',
                color: '#49D907',
                data: [48, 38, 39, 41, 47, 48, 59, 59, 52, 65, 59, 51, 48, 38, 39]
        
            }, {
                type: undefined,
                name: 'Salida',
                color: '#EAF205',
                data: [42, 33, 34, 39, 52, 75, 57, 60, 47, 39, 46, 51, 42, 33, 34]
        
            }, {
              type: undefined,
              name: 'Perfiles',
              color: '#F24607',
              data: [48, 38, 39, 41, 47, 48, 59, 59, 52, 65, 59, 51, 48, 38, 39]
        
          }, {
              type: undefined,
              name: 'Inteligencia',
              color: '#D50DD9',
              data: [42, 33, 34, 39, 52, 75, 57, 60, 47, 39, 46, 51, 42, 33, 34]
        
          }]
          });

    }

    ngOnInit(): void {
        
    }

  }