import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConectorKnowService } from '../../../../services/conector-know.service';

@Component({
  selector: 'app-cc-preguntas',
  templateUrl: './cc-preguntas.component.html',
  styleUrls: ['./cc-preguntas.component.css']
})
export class CcPreguntasComponent implements OnInit {

  @Input('fieldParams') fieldParams = null;
  @Output('exportParams') exportParams = new EventEmitter<any>();

  preguntas:any =[];
  question: any;

  options_text = null;

  constructor() { }

  ngOnInit() {

    if(this.fieldParams.length >= 0) {

      this.preguntas = this.fieldParams;

    } else {

      this.preguntas.push({
        'question_id': 0,
        'question_type': 0,
        'mandatory': 0,
        'question': null,
        'options':[]
      });
    }

  }

  add_options(row) {

    let indexPath = this.preguntas.findIndex( x => x == row );

    let model = {
      "correct":1,
      "id":0,
      "name": this.options_text,
      "option_description": this.options_text
    }

    this.preguntas[indexPath].options.push(model);

    this.options_text = null;

  }

  add_question() {

    this.preguntas.push({
      'id':0,
      'mandatory': 0,
      'question_type': 0,
      'question': null,
      'options':[],
      'question_id':0
    });

  }

  updateQuestions() {
    this.exportParams.emit(this.preguntas);
  }

}
