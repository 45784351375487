import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './../../../services/authentication.service';
import { ResponseHandler } from './../../../interfaces/ResponseHandler';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from './../../../../environments/environment';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-handler-ticket',
  templateUrl: './handler-ticket.component.html',
  styleUrls: ['./handler-ticket.component.css']
})
export class HandlerTicketComponent implements OnInit {

  publicFolder = environment.public;
  attachments: any;
  questions: any;

  relation: any;
  subgroup: any;

  ticket: any;
  group: any;

  id: any;
  lang = 'en';

  history = [];
  nodes = [];
  showAgent: boolean = false;
  activity: FormGroup;
  private: FormGroup;
  statuses = [
    'new',
    'acknowledged',
    'inprogress',
    'standby',
    'aborted',
    'solved'
  ];

  constructor(private _Activatedroute: ActivatedRoute, private conector: ConectorJsonService, private fb: FormBuilder, private auth: AuthenticationService, private translate: TranslateService) {

    this.activity = this.fb.group( {
      ticketid: [0, Validators.required],
      comments: [null, Validators.required],
      kind: ['public']
    });

    this.private = this.fb.group({
      ticketid: [0, Validators.required],
      comments: [null, Validators.required],
      kind: ['secret']
    });

    this.auth.setLanguage().then ( lang  => {
      this.translate.setDefaultLang(lang);
      this.lang = lang;
    });

  }

  ngOnInit() {

    this.id = this._Activatedroute.snapshot.paramMap.get('id');

    this.conector.handler_ticket_single(this.id).subscribe( (d: ResponseHandler) => {

      console.info('G3T TICKET', d);

      const { ok, data, msg} = d;

      if (ok === true) {

        const { ticket, questions, attachments, group, subgroup, relation} = data;

        this.attachments = attachments;

        this.questions = questions;

        this.ticket = ticket;

        this.group = group;

        this.subgroup = subgroup;

        this.relation = relation;

        this.activity.controls['ticketid'].setValue(ticket.id);

        this.private.controls['ticketid'].setValue(ticket.id);

        this.conector.handler_get_activity(ticket.id).subscribe( (k: ResponseHandler) => {
          this.history = k.data;
        });

      }

    });

    this.conector.get_category().subscribe( (m: any) => {
      console.info('all', m);
      const { ok, data, msg } = m;
      this.nodes = data;
    });

  }

  getMethod(variable: string) {

    if(variable == null || variable == undefined) return;

    this.showAgent = (variable.toLocaleLowerCase() == "true" ) ? true:false;

    return ( variable.toLocaleLowerCase() == "true" ) ? 'Call center' : 'Website';
  }

  async fileUpload(event) {

    const formData = new FormData();

    const { addedFiles, rejectedFiles, source } = event;

    formData.append('file', addedFiles[0]);

    formData.append('ticket', this.ticket.id);

    this.conector.handler_upload(formData).subscribe( (response: any) => {

      const {ok, msg, data } = response;

      if (ok === true) {
        this.attachments.push(data);
      } else {
        Swal.fire('Error', msg, 'error');
      }

    }, (err) => {
      console.info(err);
    });

  }

  publicMessage(formdata) {
    formdata.kind = "public";
    this.conector.handler_post_activity(formdata).subscribe( (d: any) => {
      this.getcomments();
    });
  }

  privateMessage(formdata) {
    formdata.kind = "private";
    this.conector.handler_post_activity(formdata).subscribe( (d: any) => {
      this.getcomments();
    });
  }

  getcomments() {
    this.conector.handler_get_activity(this.ticket.id).subscribe( (k: any) => {
      this.history = k.data;

      this.activity.reset();
      this.private.reset();

      this.activity.get('ticketid').setValue(this.ticket.id);
      this.private.get('ticketid').setValue(this.ticket.id);

    });
  }

  onChange(event) {

    const { source, value } = event;

    this.ticket.status = value;

    this.conector.handler_ticket(this.ticket).subscribe( (p: any) => {

      const { ok, data, msg} = p;

      if ( ok === true ) {

        Swal.fire('Success', 'Ticket actualizado', 'success');

      } else {

        Swal.fire('Error', msg, 'error');

        console.info('Fail ', msg);
      }

    });
  }

  onSelectGroup(event) {

    const { source, value} = event;

    Swal.fire({
      title: 'Confirmación requerida.',
      text: 'Está seguro de modificar el grupo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {

        this.ticket.group = value;

        const model = {
          id: this.ticket.id,
          group: value
        }

        console.info('NEW DATA', this.ticket);
        console.info('ALTER', model);

        this.conector.handler_ticket(model).subscribe( (u: any) => {

          Swal.fire('Completado', 'Grupo modificado correctamente', 'success');

        });

      }
    });
  }

  getPrivateFile(file) {

    this.conector.private_file(file).subscribe( (data: any) => {

      Swal.fire('Exito', 'Archivo descargado', 'success');

      const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(data);
        a.href = objectUrl;
        a.download = file;
        a.click();
        URL.revokeObjectURL(objectUrl);

    });

  }

  getLangQuestion(row) {
    if(this.lang == 'es') {
      return row.spanish;
    } else {
      return row.english;
    }
  }

}
