import { SharedCommunicationService } from './../../../services/shared-communication.service';
import { MatTabChangeEvent } from '@angular/material';
import { ResponseKnow } from './../../../interfaces/ResponseHandler';
import { ConectorKnowService } from './../../../services/conector-know.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

export interface QuestionOptions {
  option_name: string;
  correct: number;
}
export interface Pregunta {
  question_type: number;
  mandatory: number;
  question: string;
  option_description: Array<QuestionOptions>;
}

@Component({
  selector: 'app-comunica-cuestionarios',
  templateUrl: './comunica-cuestionarios.component.html',
  styleUrls: ['./comunica-cuestionarios.component.css']
})
export class ComunicaCuestionariosComponent implements OnInit {

  isTitle = null;

  areas = [];

  surveys = [];

  tabIndex = 0;

  isUpdate = false;

  preguntas = [];

  selectedRow:any = { };
  selectedFeedbacks:any = [];
  selectedQuestions:any = [];

  constructor(private know: ConectorKnowService, private widgets: SharedCommunicationService) {

  }

  ngOnInit() {
    this.loadSurveys()
  }

  loadSurveys() {
    this.know.surveys_list().subscribe( (k: any) => {
      this.areas = k.areas;
      this.surveys =  k.surveys.data;
    })
  }

  toIndex() {
    this.loadSurveys();
    this.tabIndex = 0;
    this.isUpdate = false;
  }

  switchTab(event: MatTabChangeEvent) {
    this.tabIndex = event.index + 1;
  }

  reset() {
    this.tabIndex = 0;
  }

  async openRow(row) {

    const { questions, feedbacks } = await this.know.survey_single(row.id).toPromise();
    this.selectedRow = row;

    this.selectedQuestions = questions;
    this.selectedFeedbacks = feedbacks;

    this.tabIndex = 1;
    this.isUpdate =  true;

  }

  openResults(row){
    console.info(row);
  }

  deleteRow(row){

    Swal.fire({
      title: 'Confirmacion requerida',
      text: "Esta acción es irreversible!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.know.survey_delete(row.id).subscribe( (e) => {
          Swal.fire('Completado', e.message, 'success');
          this.loadSurveys();
        });
      }
    })
  }

  dateToString(row){
    return this.widgets.dateToString(row);
  }

  onClose(event) {
    if(event === true) {
      this.tabIndex = 0;
      this.isUpdate = false;
      this.loadSurveys();
    }
  }

  updateQuestions(event: any) {

    let formdata = new FormData();
    let index = 0;

    event.forEach( x => {

      formdata.append(`question_type[${index}]`, x.question_type);
      formdata.append(`mandatory[${index}]`, x.mandatory.toString());
      formdata.append(`question_id[${index}]`, x.question_id.toString());
      formdata.append(`question[${index}]`, x.question);

      x.options.forEach( element => {
        formdata.append(`option_description[${index}][]`, element.option_description);
        formdata.append(`option_id[${index}][]`, element.option_id);
        formdata.append(`correct[${index}][]`, element.correct);
      });

      index ++;

    });

    this.know.surveys_questions(formdata, this.selectedRow.id).subscribe( (res: ResponseKnow) => {
      Swal.fire( ( res.success == true ) ? 'Completado': 'Error', res.message, (res.success == true) ? 'success' : 'error');
    });

  }

  guardaRetros(data) {

    let formdata = new FormData();
    let index = 0;

    console.info('Preparar', data);

    data.forEach( e => {

      console.info('EX', e);

      formdata.append(`condition_type[${index}]`, e.condition_type);
      formdata.append(`precedence[${index}]`, e.precedence);

      formdata.append(`feedback[${index}]`, e.feedback);
      formdata.append(`feedback_id[${index}]`, e.feedback_id);
      formdata.append(`id[${index}]`, e.id);

      e.conditions.forEach( x => {

        console.info("CON X", x);

        formdata.append(`condition_id[${index}][]`, x.condition_id);
        formdata.append(`condition_question_id[${index}][]`, x.condition_question_id);
        formdata.append(`condition_option_id[${index}][]`, x.condition_option_id);
      });

      index++;

    });

    formdata.append('from', "surveys");

    this.know.surveys_feedbacks(formdata, this.selectedRow.id).subscribe( (res: ResponseKnow) => {
      Swal.fire( ( res.success == true ) ? 'Completado': 'Error', res.message, (res.success == true) ? 'success' : 'error');

      if(res.message == true) {
        this.loadSurveys();
      }
    });

  }

}
