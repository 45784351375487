import { ChangeDetectionStrategy, Component, Input, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.css']
})
export class ApplyComponent implements OnInit {

  @Input() survey: any = {};
  @Input() section;
  @Input() question;
  @Output() solve: EventEmitter<any> = new EventEmitter();

  current = { name: null, sections: [], instructions: null, questions: [], visible: false };

  sections = [];

  constructor() {

  }

  ngOnInit() {

  }

  siguiente(ev) {
    this.solve.emit(ev)
  }

}
