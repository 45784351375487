import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AdminLayoutRoutes } from './admin.routing';
import { DemoMaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AdminItems } from './admin-items';
import { CompaniesComponent } from '../../views/admin/companies/companies.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forChild(AdminLayoutRoutes),
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        DemoMaterialModule
    ],
    declarations: [
        CompaniesComponent
    ],
    entryComponents: [

    ],
    providers: [ AdminItems]
})

export class AdminLayoutModule { }
