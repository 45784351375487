import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConectorJsonService } from '../../../../services/conector-json.service';

@Component({
  selector: 'app-modal-children',
  templateUrl: './modal-children.component.html',
  styleUrls: ['./modal-children.component.css']
})
export class ModalChildrenComponent  implements OnInit {

  children: any = [];
  parent: any = {};
  groupForm: FormGroup;
  length =  0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 15];
  isEditable = false;
  selected = null;

  constructor(
    public dialogRef: MatDialogRef<ModalChildrenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private conector: ConectorJsonService,
    private fb: FormBuilder) {
      this.groupForm = this.fb.group({
        parent: [0, [Validators.required]],
        name: [null, Validators.required],
        description: [null],
        visible: [true, Validators.required]
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    const {row} = this.data;
    this.parent = row;
    this.conector.groups_childs(row.id).subscribe( (d: any) => {
      this.children = d.data;
    });
  }

  updateRows($events) {
    const { pageIndex, pageSize } = $events;

    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.children = [];

    this.conector.groups_childs(this.parent.id, this.pageSize, this.pageIndex).subscribe( (x: any) => {
      this.children = x.data;
    });
  }

  onClick(formdata) {

    formdata.parent = this.parent.id;

    if (this.isEditable === false) {
      this.conector.groups_post(formdata).subscribe( (d: any) => {
        this.groupForm.reset();

        this.groupForm.controls["parent"].setValue(0);
        this.groupForm.controls["visible"].setValue(true);

        this.children.unshift(d.data);
        this.length++;
      });
    } else {

      Object.keys(formdata).forEach( key => {
        this.selected[key] = formdata[key];
      });

      this.conector.groups_put(this.selected).subscribe( (d) => {
        this.groupForm.reset();
        this.isEditable = false;
        this.groupForm.reset();
      });

    }

  }

  editRow(row) {
    this.isEditable = true;
    this.selected = row;
    Object.keys(this.groupForm.controls).forEach(key => {
      this.groupForm.get(key).setValue( row[key] );
    });
  }

  deleteRow(row) {}

}
