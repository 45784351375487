import { ResponseKnow } from './../../../interfaces/ResponseHandler';
import { SharedCommunicationService } from './../../../services/shared-communication.service';
import { ConectorKnowService } from './../../../services/conector-know.service';
import { Component, OnInit } from '@angular/core';
import {ConectorJsonService} from '../../../services/conector-json.service';
import {ActivatedRoute, Params} from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { isNumeric } from 'rxjs/util/isNumeric';

@Component({
  selector: 'app-comunica-evaluaciones',
  templateUrl: './comunica-evaluaciones.component.html',
  styleUrls: ['./comunica-evaluaciones.component.css']
})
export class ComunicaEvaluacionesComponent implements OnInit {

  isEditable = false;
  idEditable = 0;
  isNewRequest= false;

  showRecords = true;
  isTitle = 'Crear nueva ';

  evaluaForm: FormGroup;
  editor;

  option_value = null;
  evaluaciones = [];

  categorias = [];
  areas = [];

  //Para el multiselect
  builder = [];

  //para el formulario de listado
  area_id = 0;

  articulos = [];

  //form extras
  correct;
  option_id;
  option_name;

  constructor(private shared: SharedCommunicationService, private snap: ActivatedRoute, private know: ConectorKnowService, private fb: FormBuilder) {


    this.evaluaForm = this.fb.group({
      area_id:        [0, [Validators.required]],
      structure_id:   [0, [Validators.required]],
      article_id:     [0, [Validators.required]],
      title:         [null, [Validators.required]],
      video_link:     [null],
      imagen:         [null],
      select_media:   [null, [Validators.required]],
      question:       [null, [Validators.required]],
      start_date:     [null, [Validators.required]],
      end_date:       [null, [Validators.required]],
      question_type:  [null, [Validators.required]],
      enable_message: [1, [Validators.required]],
      active:         [1, [Validators.required]],
      message:        [null],
      push_text:      [null],
      push_notifications: [1, [Validators.required]],
    })
  }

  ngOnInit() {

    this.snap.params.subscribe( async(ks: Params) => {

      if (ks.id == null) {

        this.showRecords = true;

      } else {

        this.showRecords = false;

        this.isNewRequest = true;

        const { areas, categories, success }: any = await this.know.evaluation_prepare().toPromise();

        if(success == true) {
          this.areas = areas;
          this.categorias = categories;
          this.articulos = [];
        }

        if( isNumeric(ks.id) ) {

          this.isEditable = true;

          this.isTitle = 'Editar';

          const { success, areas, articles, categories, question, questionOptions }: ResponseKnow =  await this.know.evaluation_single(ks.id).toPromise();

          Object.keys(this.evaluaForm.value).forEach( key  => {
            this.evaluaForm.get(key).setValue( question[key] );
          });

          // Save global id
          this.idEditable = question.id;

          // update categories
          this.know.evaluations_filter(question.area_id).subscribe( (k: ResponseKnow) => {

            this.categorias = k.categories;
            this.evaluaForm.get("structure_id").setValue(question.structure_id);

            this.know.evaluations_articles(question.category_id).subscribe( (k:ResponseKnow) => {
              this.articulos = k.articles;
              this.evaluaForm.get("article_id").setValue(question.article_id);
            });

          });

          // Set date time
          this.evaluaForm.get('start_date').setValue( new Date(question.start_date));
          this.evaluaForm.get('end_date').setValue( new Date(question.end_date));

          // Pass data to builder
          questionOptions.forEach( item => {
            this.builder.push({
              option_name: item.name,
              option_id: item.id,
              correct: item.correct
            });
          });

          this.evaluaForm.get("imagen").setValue(null);
          this.evaluaForm.updateValueAndValidity();

        }

      }

    })

    this.know.evaluations_list().subscribe( (k: any) => {

      const { areas, categories, evaluations} : any = k;

      this.evaluaciones = evaluations.data;

      this.areas =  (this.shared.isJsonObject(areas)) ? this.shared.ObjecToArray(areas) : areas;

    })

  }

  updateCategories(event, form=false) {

    console.info(event);

    this.know.evaluations_filter(event).subscribe( x => {

      const { success, evaluations, areas, categories  }: any = x;

      this.categorias = categories;

      if(form == false) {
        this.evaluaciones = evaluations.data;
      }

    })

  }

  updateEventsByCategory(event) {

    console.info(event);

    this.know.evaluations_filter(this.area_id, event).subscribe( x =>{
      const { success, evaluations, areas, categories  }: any = x;
        this.evaluaciones = evaluations.data;
    })
  }

  async updateCategoriesForm(event) {

    this.know.evaluations_articles(event).subscribe( k => {
      const { success, articles } = k;
      this.articulos = articles;
    })

  }

  updateArticles(event) {
    this.know.evaluations_articles(event).subscribe( ({ success, articles}: any) => {
      if(success === true) {
        this.articulos = articles;
      }
    })
  }

  deleteRow(row) {
    console.info('Rx', row);
  }

  pickQuestionModel(event) {
    console.info('Opt', event)
    console.info( this.evaluaForm.get("question_type").value);
  }

  enviarFormulario(formdata: any) {

    //Static values
    formdata.content_type = 2;
    formdata.active = 1;
    formdata.company_id = this.shared.KnowSesion();
    formdata["select-media"] = formdata.select_media;

    formdata.start_date = moment(formdata.start_date).format("YYYY-MM-DD HH:mm a").toString();
    formdata.end_date = moment(formdata.end_date).format("YYYY-MM-DD").toString();

    let data_send = new FormData();

    Object.keys(formdata).forEach( key => {
      data_send.append(key, formdata[key]);
    })

    this.builder.forEach( item => {
      data_send.append('option_name[]', item.option_name);
      data_send.append('option_id[]', item.option_id);
      data_send.append('correct[]', item.correct);
    });

    if(formdata.imagen) {

      console.info(formdata.imagen);

      data_send.set("imagen", formdata.imagen._files[0]);

    }

    if( this.isEditable == true ) {

      data_send.append("_method", "PATCH");

      data_send.append("id", this.idEditable + '');

      this.know.evaluations_put(data_send, this.idEditable).subscribe( (x: any) => {

        const { success, message }: any = x;

        if(success === false ) {

          Swal.fire('Error', message[0], 'error');

        } else {

          Swal.fire('Actualizado', message, 'success');

        }

      })

    } else {
      this.know.evaluations_post(data_send).subscribe( (x: any) => {

        const { success, message }: any = x;

        if(success === false ) {

          Swal.fire('Error', message[0], 'error');

        } else {

          Swal.fire('Completado', message, 'success');

          this.evaluaForm.reset();

          this.builder = [];

        }

      })

    }

  }

  eliminaRespuestas() {
    this.builder = [];
  }

  addOptions(value) {

    this.builder.push({
      option_name: value,
      option_id:0,
      correct:0
    });

    this.option_value = null;

  }

  setUnique(event) {
    console.info(event)
  }

  delete_article(id) { }

}
