import { Router } from '@angular/router';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { Component, OnInit } from '@angular/core';
import { ResponseHandler } from '../../../interfaces/ResponseHandler';

@Component({
  selector: 'app-tycos',
  templateUrl: './tycos.component.html',
  styleUrls: ['./tycos.component.css']
})
export class TycosComponent implements OnInit {

  constructor(private conector: ConectorJsonService, private router: Router) { }

  tycos = { es: null, en: null};

  ngOnInit() {
    this.conector.getEvaluation_profile().subscribe( (res:ResponseHandler) => {
      if(res.ok === true) {
        localStorage.setItem('webdata', JSON.stringify(res.data));
        this.tycos = res.data.complements.tycos;
      }
    });
  }

  saveStep(){
    localStorage.setItem('evaluationTycos', "ok");
    this.router.navigateByUrl("/evaluations/profile");
  }

}
