import { Component, OnInit } from '@angular/core';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-to-do',
  templateUrl: './to-do.component.html',
  styleUrls: ['./to-do.component.css']
})
export class ToDoComponent implements OnInit {

  grant: any = 0;

  constructor(private conector: ConectorJsonService, private snap: ActivatedRoute, private router: Router) { }

  async ngOnInit() {

    this.snap.paramMap.subscribe((d: any) => {

      this.conector.reporte_valida_puesto({ token: d.params.id }).subscribe((e: any) => {

        if (e.ok == true) {

          localStorage.setItem('token_evaluaciones', e.data);

          this.router.navigateByUrl('evaluations');

          this.grant = 'Correcto, redireccionando...';

        }
        else {

          Swal.fire('Error', 'Código no coindice', 'error');

          this.grant = 'El código no es valido, validar nuevamente.';

        }

      });

    });

  }

}
