import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/Rx';

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ConectorKnowService {

  constructor(private http: HttpClient, private native: Http) { }

  auth() {

    const model = {
      email: environment.know_email,
      password: environment.know_password
    }

    return this.http.post(`${environment.know_server}/auth/login`, model);

  }

  getHeaders() {
    let token = localStorage.getItem('know_token');
    let headers = new Headers({ 'Authorization': 'Bearer ' + token });
    let options = new RequestOptions({ headers: headers });
    return options;
  }

  /***************************************************
   **************** CONTENT - ARTICLES ***************
   ***************************************************/

  articles_list() {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/articles?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  articles_by_area(area) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/articles?companySession=${sess}&area_id=${area}`, this.getHeaders() ).map( d => d.json() );
  }

  articles_by_category(area, category) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/articles?companySession=${sess}&area_id=${area}&category_id=${category}`, this.getHeaders() ).map( d => d.json() );
  }

  article_single(formdata) {
    return this.native.get(`${environment.know_server}/articles/${formdata}`, this.getHeaders() ).map( d => d.json() );
  }

  articles_post(formdata) {
    return this.native.post(`${environment.know_server}/articles`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  articles_put(formdata, id) {
    return this.native.post(`${environment.know_server}/articles/${id}`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  articles_delete(row) {
    return this.native.delete(`${environment.know_server}/articles/${row}`, this.getHeaders()).map(d => d.json());
  }

  articles_option_general() {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/articles/create?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  articles_option_list(formdata) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/categories/${formdata}/list?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  /***************************************************
   ***************** CONTENT - EVENTS ****************
   ***************************************************/

  events_list() {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/events?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  events_filter(area = 0, categoria = null) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/events?companySession=${sess}&area_id=${area}&category_id=${categoria}`, this.getHeaders() ).map( d => d.json() );
  }

  event_single(formdata) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/events/${formdata}/edit?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  events_post(formdata) {
    return this.native.post(`${environment.know_server}/events`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  events_put(formdata, id) {
    return this.native.post(`${environment.know_server}/events/${id}`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  events_delete(row) {
    return this.native.delete(`${environment.know_server}/events/${row}`, this.getHeaders()).map(d => d.json());
  }

  events_create() {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/events/create?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  /*******************************************************
   ***************** CONTENT - EVALUATIONS ***************
   ******************************************************/

  evaluations_list() {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/evaluations?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  evaluations_filter(area= 0, category=0) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/evaluations?companySession=${sess}&area_id=${area}&category_id=${category}`, this.getHeaders() ).map( d => d.json() );
  }

  evaluation_single(formdata) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/evaluation/${formdata}/edit?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  evaluation_prepare() {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/evaluations/create?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  evaluations_post(formdata) {
    return this.native.post(`${environment.know_server}/articles`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  evaluations_put(formdata, id) {
    return this.native.post(`${environment.know_server}/articles/${id}`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  evaluations_articles(category) {
    return this.native.get(`${environment.know_server}/articles/category/${category}`, this.getHeaders() ).map( d => d.json() );
  }

  evaluations_results(id) {
    return this.native.get(`${environment.know_server}/results/${id}`, this.getHeaders() ).map( d => d.json() );
  }

  /*******************************************************
   ****************** CONTENT - QUESTIONS ****************
   ******************************************************/

  questions_list() {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/questions?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  question_single(formdata) {
    return this.native.get(`${environment.know_server}/questions/${formdata}/edit`, this.getHeaders() ).map( d => d.json() );
  }

  questions_post(formdata) {
    return this.native.post(`${environment.know_server}/questions`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  questions_put(formdata, id) {
    return this.native.post(`${environment.know_server}/questions/${id}`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  question_delete(qid) {
    return this.native.delete(`${environment.know_server}/questions/${qid}`, this.getHeaders() ).map( d => d.json() );
  }

  /********************************************************
   ******************** CONTENT - SURVEYS* ****************
   *******************************************************/

  surveys_list() {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/surveys?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  survey_single(formdata) {
    return this.native.get(`${environment.know_server}/surveys/${formdata}/edit`, this.getHeaders() ).map( d => d.json() );
  }

  surveys_post(formdata) {
    return this.native.post(`${environment.know_server}/surveys`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  surveys_questions(formdata, survey) {
    return this.native.post(`${environment.know_server}/surveys/questions/${survey}`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  surveys_feedbacks(formdata, survey) {
    return this.native.post(`${environment.know_server}/surveys/feedbacks/${survey}`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  surveys_put(formdata, id) {
    return this.native.post(`${environment.know_server}/surveys/${id}`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  survey_delete(formdata) {
    return this.native.delete(`${environment.know_server}/surveys/${formdata}`, this.getHeaders() ).map( d => d.json() );
  }

  filter_surveys(formdata) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/filter-surveys?area_id=${formdata.area}&start_date=${formdata.inicio}&end_date=${formdata.final}&companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }
  report_surveys(formdata) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/get-data-surveys-report?survey_id=${formdata.survey}&area_id=${formdata.area}&start_date=${formdata.inicio}&end_date=${formdata.final}&companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  report_questions(formdata) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/get-data-questions-report?survey_id=&area_id=${formdata.area}&start_date=${formdata.inicio}&end_date=${formdata.final}&companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  /************************************************************
   ******************** CONFIGURATION - AREAS *****************
   ***********************************************************/

  /*
   * http://apicmsknowapp.cinnco.co/api/areas/{{know_session}}/structure
   */

  areas_structure() {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/areas/${sess}/structure`, this.getHeaders() ).map( d => d.json() );
  }

  /*
   * http://apicmsknowapp.cinnco.co/api/areas/{{know_session}}/list
   */

  areas_list() {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/areas/${sess}/list`, this.getHeaders() ).map( d => d.json() );
  }

  /*
   * http://apicmsknowapp.cinnco.co/api/structure
   */

  areas_post(formdata) {
    return this.native.post(`${environment.know_server}/structure`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  /*
  * http://apicmsknowapp.cinnco.co/api/structure/1274/edit?companySession=32
  */

  areas_single(formdata) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/structure/${formdata}/edit?companySession=${sess}`, this.getHeaders() ).map( d => d.json() );
  }

  /*
  * http://apicmsknowapp.cinnco.co/api/structure/1273
  */

  areas_put(formdata, id) {
    return this.native.post(`${environment.know_server}/structure/${id}`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  /************************************************************
   ******************** CONFIGURATION - USERS *****************
   ***********************************************************/

  /*
  * http://apicmsknowapp.cinnco.co/api/allusers
  */

  users_all(formdata) {
    return this.native.post(`${environment.know_server}/allusers`, formdata, this.getHeaders() ).map( d => d.json() );
  }

  /************************************************************
   ******************** CONFIGURATION - COMPANY *****************
   ***********************************************************/

  company_get() {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/editCompany?companySession=${sess}`, this.getHeaders()).map( d => d.json() );
  }

  company_update(formdata) {
    const sess = localStorage.getItem('know_session');
    return this.native.post(`${environment.know_server}/companies/${sess}`, formdata, this.getHeaders()).map( d => d.json() );
  }

  /************************************************************
   ********************** STADISTICS **********************
   ***********************************************************/

  chart_articles(init, end){
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/getChartArticles?start_date=${init}&end_date=${end}&all_fields=true&companySession=${sess}`, this.getHeaders()).map( d => d.json() );
  }

  chart_events(init, end){
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/getChartEvents?start_date=${init}&end_date=${end}&all_fields=true&companySession=${sess}`, this.getHeaders()).map( d => d.json() );
  }

  chart_esaved(init, end){
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/getSavedEvents?start_date=${init}&end_date=${end}&all_fields=true&companySession=${sess}`, this.getHeaders()).map( d => d.json() );
  }

  chart_users(init, end){
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/getChartUsers?start_date=${init}&end_date=${end}&all_fields=true&companySession=${sess}`, this.getHeaders()).map( d => d.json() );
  }

  report_events(model) {
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/get-data-evaluations-report?area_id=${model.area}&category_id=${model.categoria}&start_date=${model.inicio}&end_date=${model.final}&companySession=${sess}`, this.getHeaders()).map( d => d.json() );
  }

  users_lastActivity(model){
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/getChartUsers?start_date=${model.inicio}&end_date=${model.final}&companySession=${sess}`, this.getHeaders()).map( d => d.json() );
  }
  users_active(){
    const sess = localStorage.getItem('know_session');
    return this.native.get(`${environment.know_server}/getActiveUsers?companySession=${sess}`, this.getHeaders()).map( d => d.json() );
  }

  exportar_evaluaciones(formdata) {

    const sess = localStorage.getItem('know_session');

    const token = localStorage.getItem('know_token');

    const request = `${environment.know_server}/export-questions?questions_id=${formdata.ids}&start_date=${formdata.inicio}&end_date=${formdata.final}&companySession=${sess}`;

    return fetch(request, { method:'GET', headers: { 'Authorization': 'Bearer ' + token } }).then(response => { return response.blob() });

  }

  exportar_cuestionarios(formdata) {

    console.info('EC', formdata);

    const sess = localStorage.getItem('know_session');

    const token = localStorage.getItem('know_token');

    const request = `${environment.know_server}/export-questions?export-data-surveys?survey_id=${formdata.id}&questions_id=${formdata.survey_id}&start_date=${formdata.inicio}&end_date=${formdata.final}&companySession=${sess}`;

    return fetch(request, { method:'GET', headers: { 'Authorization': 'Bearer ' + token } }).then(response => { return response.blob() });

  }

  exportar_encuestas(formdata) {

    const sess = localStorage.getItem('know_session');

    const token = localStorage.getItem('know_token');

    const request = `${environment.know_server}/export-questions?questions_id=${formdata.id}&start_date=${formdata.inicio}&end_date=${formdata.final}&companySession=${sess}`;

    return fetch(request, { method:'GET', headers: { 'Authorization': 'Bearer ' + token } }).then(response => { return response.blob() });

  }

}
