import { ResponseKnow } from './../../../interfaces/ResponseHandler';
import { Article } from './../../../interfaces/Article';
import { SharedCommunicationService } from './../../../services/shared-communication.service';
import { ConectorKnowService } from './../../../services/conector-know.service';
import { Pagination } from './../../../models/pagination';
import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'app-comunica-articulos',
  templateUrl: './comunica-articulos.component.html',
  styleUrls: ['./comunica-articulos.component.css']
})
export class ComunicaArticulosComponent implements OnInit {

  @ViewChild('default_area', { static: false }) default_area: MatSelect;

  isEditable = false;
  idEditable = 0;
  isNewRequest = false;
  showRecords = true;
  isTitle = 'Crear nuevo ';

  articleForm: FormGroup;
  pagination = new Pagination();

  area = 0;
  articles = [];
  areas = [];
  categories = [];
  structure = [];
  imageURL = null;

  constructor(private snap: ActivatedRoute, private fb: FormBuilder, private know: ConectorKnowService, private shared: SharedCommunicationService) {

    this.articleForm = this.fb.group({
      id: 0,
      company_id:[localStorage.getItem('know_session')],
      area_id: [null],
      category_id: [null],
      title: [null],
      publish_date: [null],
      image: [null],
      video_link: [null],
      order: [0],
      important: [null],
      main_article: [null],
      summary: [null],
      content: [null],
      options: [null],
      select_media: [null],
      question: [null],
      start_date:[null],
      end_date: [null],
      question_type: [null],
      opcion: [null],
      enable_message: [null],
      active: 1,
      message: [null],
      notified:[null],
      push_notifications: [null],
      push_text: [null],
      isDisabled: [null],
      "select-media": 0,
      imagen: null,
      titulo: null,
      video_url: null,
      structure_id: null
    });

  }

  async ngOnInit() {

    const { success, articles, areas, categories }:any =  await this.know.articles_list().toPromise();

    if( success === true) {
      this.areas =  areas;
      this.categories = categories;
      this.articles = articles.data;
    }

    this.snap.params.subscribe( (ks: Params) => {

      if (ks.id == null) {

        this.showRecords = true;

      } else {

        this.showRecords = false;

        this.isNewRequest = true;

        const { id } = ks;

        if ( id > 0 ) {

          this.isEditable = true;

          this.idEditable = id;

          this.isTitle = 'Editar';

          this.know.article_single(id).subscribe( async(k) => {

            const { article }: any = k;

            Object.keys(this.articleForm.value).forEach( item => {

              if( this.articleForm.get(item) != undefined) {

                if(item == 'publish_date') {

                  this.articleForm.get('publish_date').setValue( new Date( article.publish_date ));

                } else {

                  this.articleForm.get(item).setValue(article[item]);

                }

              }

            });

            this.imageURL = article.image_url;

            if(article.area_id != null) {

              this.load_by_area(article.area_id, true).then( () => {

                this.articleForm.controls.area_id.setValue(article.area_id);

                this.articleForm.controls.structure_id.setValue(article.structure_id);

              });

              this.articleForm.get("imagen").setValue(null);

              this.articleForm.updateValueAndValidity();

            }

          });

        }

      }

    })

  }

  get_by_area(event) {

    console.info('get_by_area', event);

    this.area = event.target.value;

    console.info('Area', this.area);

    this.know.articles_by_area(this.area).subscribe( (k: any) => {

      console.info('articles_by_area', k);

      this.articles = k.articles.data;

      this.categories = k.categories;

    });

  }

  get_by_category(event) {
    console.info('GetByCategory', this.area, event.target.value);
    this.know.articles_by_category(this.area, event.target.value).subscribe( (k: any) => {
      console.info('gbc', k);
      this.articles = k.articles.data;
    });
  }

  async load_by_area(event:any, isCustom = false) {

    console.info('Event');

    let input_key = (isCustom === true) ? event : event.value.key;

    this.know.articles_option_list(input_key).subscribe( (response: ResponseKnow) => {
      this.structure = response.structure;
    })

  }

  saveArticle(formdata: any) {

    console.info("FORM_ARTICLE", formdata);

    console.info("IMAGEN", formdata.imagen);

    let model = new FormData();

    Object.keys(formdata).forEach( keys => {
      model.append(keys, formdata[keys]);
    })

    model.set("active", "1");

    model.set("content_type", "1");

    model.set("company_id", localStorage.getItem("know_session"));

    model.set("publish_date", moment(formdata.publish_date).format("YYYY-MM-DD HH:mm:ss a").toString());

    if( formdata.imagen) {
      model.set("imagen", formdata.imagen._files[0].File);
    }

    this.know.articles_post(model).subscribe( (k: any) => {

      const { success, message }: any = k;

      if(success === false ) {

        Swal.fire('Error', message[0], 'error');

      } else {

       Swal.fire('Completado', message, 'success');

       this.articleForm.reset();

      }

    });

  }

  updateArticle(formdata: any)
  {

    let model = new FormData();

    Object.keys(formdata).forEach( keys => {
      model.append(keys, formdata[keys]);
    })

    if( formdata.imagen) {
      model.set("imagen", formdata.imagen._files[0]);
    }

    model.set("active", "1");

    model.set("content_type", "1");

    model.set("company_id", localStorage.getItem("know_session"));

    model.set("publish_date", moment(formdata.publish_date).format("YYYY-MM-DD HH:mm:ss a").toString());

    model.append("_method", "PATCH");

    this.know.articles_put(model, this.idEditable).subscribe( (k: any) => {

      console.info('up', k);

      const { success, message }: any = k;

      if(success === false ) {

        Swal.fire('Error', message[0], 'error');

      } else {

        Swal.fire('Completado', message, 'success');

      }

    });

  }

  delete_article(row){

    Swal.fire({
      title: 'Confirmar acción',
      icon: 'info',
      html:'Está seguro de realizar está acción ?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then( response => {
      if(response.value === true) {
        this.know.articles_delete(row).subscribe( a => {

          const { success, message }:any = a;

          Swal.fire('Completado', message, 'success');

          this.articles = [];

          this.know.articles_list().subscribe( k => {
            const { success, categories, articles, areas }:any = k;
            this.articles = articles.data;
          });

        });
      }
    });

  }

  humanDate(data) {
    return this.shared.dateToString(data);
  }

  integerToString(data) {
    return (data == 0) ?  "No" : "Si";
  }

}
