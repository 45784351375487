import { FilterMessages, TrimStrTwo, ConvertirFecha, SwitchStatus, HoldQuestion } from './all.pipe';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    FilterMessages,
    TrimStrTwo,
    ConvertirFecha,
    SwitchStatus,
    HoldQuestion
  ],
  exports: [
    FilterMessages,
    TrimStrTwo,
    ConvertirFecha,
    SwitchStatus,
    HoldQuestion
  ],
  imports: []
})

export class SharedPipesModule { }
