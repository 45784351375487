import { ConnectorEthicsSurveyService } from '../../../services/ethics-survey/conector-ethics-survey.service';
import { CreateConfigComponent } from '../modal/create-config/create-config.component';
import { CreateCollaboratorComponent } from '../../general/create-collaborator/create-collaborator.component';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatTabChangeEvent, MatDialog, MatPaginator, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './../../../services/authentication.service';
import { Pagination } from './../../../models/pagination';
import { DeleteConfigComponent } from '../modal/delete-config/delete-config.component';
import { UpdateConfigComponent } from '../modal/update-config/update-config.component';
 

import { from } from 'rxjs';

@Component({
  selector: 'app-config-proyect',
  templateUrl: './config-proyect.component.html',
  styleUrls: ['./config-proyect.component.css'],
})
export class ConfigProyectComponent implements OnInit {
  pagination = new Pagination();
  showActive = 1;
  selectedIndex = 0;
  activeRow = null;
  proyecto = [];

  @ViewChild(MatPaginator, { static: true }) paginationBar: MatPaginator;

 

  constructor( 
      public datepipe: DatePipe, 
      private conector: ConnectorEthicsSurveyService,
      public dialog: MatDialog,
      public translate: TranslateService,
      
      public auth: AuthenticationService) 


      {
        this.auth.setLanguage().then( k => this.translate.setDefaultLang(k) );
        this.pagination.length = 10;
      }

  ngOnInit() {
    this.get_ethics_suvey();
  }

   // ge list proyect
  get_ethics_suvey() {

    this.conector.ethicsSurveyGet(this.showActive, this.pagination.init, this.pagination.limit).subscribe( (response: any) => {
      const {ok, data, msg} = response;
      
      if (ok === false) {
        return Swal.fire('Error', msg, 'error')

      } else {
        const {data, msg} = response;
        this.proyecto = data;
        this.pagination.length = msg;
      }
    });

  }

  AddConfig(row) {

    const dialogRef = this.dialog.open(CreateConfigComponent, { data : row, width : '70%' });

    // dialogRef.afterClosed().subscribe(res => {
    //   this.conector.users_get().subscribe( (response: any) => {
    //     const {ok, data, msg} = response;
    //     this.users = data;
    //   });
    // });

  }
  AddDelete(id : number) {
    const dialogRef = this.dialog.open(DeleteConfigComponent, { data : { idSurvey: id }, width : '70%' });
    dialogRef.afterClosed().subscribe(result => {
      this.get_ethics_suvey();
    });
  }

  AddUpdate(id : number) {
    const dialogRefff = this.dialog.open(UpdateConfigComponent, {  data : { idSurvey: id },width : '70%' });
    dialogRefff.afterClosed().subscribe(result => {
      this.get_ethics_suvey();
    });
  }


  // AddConfig2() {

  //   this.conector.PdfGetReportEngagement("85").subscribe( (response: any) => {
      
  //     const {ok, data, msg} = response;
  //     debugger;
  //     const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
  //     const downloadLink = document.createElement("a");
  //     const fileName = "sample.pdf";

  //     downloadLink.href = linkSource;
  //     downloadLink.download = fileName;
  //     downloadLink.click();
  //     debugger;

  //   });

 // }
  // AddConfig3() {

  //   this.conector.PdfGetReportNom035("85").subscribe( (response: any) => {
      
  //     const {ok, data, msg} = response;
  //     debugger;
  //     const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
  //     const downloadLink = document.createElement("a");
  //     const fileName = "sample.pdf";

  //     downloadLink.href = linkSource;
  //     downloadLink.download = fileName;
  //     downloadLink.click();
  //     debugger;

  //   });
  // }
  // AddConfig4() {

  //   this.conector.PdfGetReportClima("85").subscribe( (response: any) => {
      
  //     const {ok, data, msg} = response;
  //     debugger;
  //     const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
  //     const downloadLink = document.createElement("a");
  //     const fileName = "sample.pdf";

  //     downloadLink.href = linkSource;
  //     downloadLink.download = fileName;
  //     downloadLink.click();
  //     debugger;

  //   });
  // }
  
  onChange($event) {

    const { checked} = $event;

    this.proyecto = [];
    this.pagination.length = 0;
    this.showActive =  (checked === true) ? 0 : 1;

    this.conector.ethicsSurveyGet(this.showActive, this.pagination.init, this.pagination.limit).subscribe( (response: any) => {
      const {data, msg} = response;
      this.pagination.length = msg;

      this.proyecto = data;

      if (this.paginationBar) {
        this.paginationBar.firstPage();
      }

    });
  }

  changePage($event) {

    this.pagination.length = 0;
    this.proyecto = [];

    this.pagination.changePage($event);

    this.conector.ethicsSurveyGet(this.showActive, this.pagination.init, this.pagination.limit).subscribe( (response: any) => {
      const { msg, data} = response;
      this.pagination.length = msg;
      this.proyecto = data;
    });

  }

}