import * as moment from 'moment';
import Swal from 'sweetalert2';

import { MediaMatcher } from '@angular/cdk/layout';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatStepper } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { CoppelApiService } from '../../../services/coppel-api.service';
import { SharedCommunicationService } from '../../../services/shared-communication.service';

export const MY_DATE_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.css'],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: true },
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: MY_DATE_FORMATS,
		},
		{
			provide: MAT_DATE_LOCALE,
			useValue: 'es-ES',
		},
	],
})
export class ReportComponent implements OnInit {
	maxDate: Date;
	mobileQuery: MediaQueryList;
	year = new Date().getFullYear();
	groupInfo = { visible: false, description: null };
	private _mobileQueryListener: () => void;
	isCallCenter = false;

	public companyFrm: FormGroup;
	relations = [];
	categories: any = [];
	subcategories: any = [];

	gotQuestions: any = [];

	makeResume: any = {
		relation: {},
		category: {},
		reportingId: '0000',
		finalMessage: null,
	};

	company_values = {
		empresa: null,
		estado: null,
		municipio: null,
		pais: null,
	};

	localTranslations = {
		confirmation: 'Confirmación requerida',
		confirmation_text: '¿ Estás seguro de realizar esta acción ?',
		badPass: 'Las contraseñas no coinciden',
		submit: 'Enviar',
		cancel: 'Detener',
		allfieldsarequired: 'Todos los campos son requeridos',
	};
	attachments: any = [];
	publicFolder = environment.public;
	index = 0;

	company = null;
	appToken = localStorage.getItem('token');
	files_to: any[] = [];

	@ViewChild('stepper', { static: false }) private stepper: MatStepper;
	makeHttp = false;

	authForm: FormGroup;
	pageToken = [];
	paises = [];
	regiones = [];
	regiones_all = [];
	empresas: any = [];
	municipios: any = [];
	estados: any = [];
	cortinilla = false;
	disabled_cats = true;
	disabled_contacts = true;

	constructor(
		private fb: FormBuilder,
		private conector: ConectorJsonService,
		private coppel: CoppelApiService,
		private shared: SharedCommunicationService,
		private service: AuthenticationService,
		private translate: TranslateService,
		public changeDetectorRef: ChangeDetectorRef,
		public media: MediaMatcher,
		public router: Router
	) {
		this.maxDate = new Date();

		this.mobileQuery = media.matchMedia('(min-width: 768px)');

		this._mobileQueryListener = () => changeDetectorRef.detectChanges();

		this.mobileQuery.addListener(this._mobileQueryListener);

		this.shared.httpCall.subscribe((k) => {
			this.makeHttp = k;
		});

		this.authForm = this.fb.group({
			reportingId: ['34821370648', Validators.required],
			pais: [null, Validators.required],
			municipio: [null, Validators.required],
			estado: [null, Validators.required],
			empresa: [0, Validators.required],
			region: [null, Validators.required],
			centro: [null, Validators.required],
		});

		this.companyFrm = this.fb.group({
			reporting: ['34821370648', Validators.required],
			anonymous: [false, Validators.required],
			relation: [null, Validators.required],
			category: [null, Validators.required],
			subcategory: [0, Validators.required],
			priority: [false],
			enableFeedback: [false],
			alfa: [null],
			beta: [null],
			gama: [null],
			delta: [null],
			uname: [null],
			umail: [null],
			upass: [null],
			urepeat: [null],
			userid: [0],
			incident: [null],
			isCallCenter: [null],
			isCallDate: [null],
			isCallTime: [null],
			isCallEnd: [null],
			reporter: [0],
			phone: null,
			sucursal: null,
		});

		// Ask for CC
		const model = localStorage.getItem('callcenter');

		if (model !== null) {
			const params = JSON.parse(model);

			params.isCallCenter = true;

			Object.keys(params).forEach((x) => {
				if (this.companyFrm.value.hasOwnProperty(x)) {
					this.companyFrm.controls[x].setValue(params[x]);
				}
			});
		}

		this.company_values = JSON.parse(localStorage.getItem('dictionary_user'));
	}

	async ngOnInit() {
		this.service.setLanguage().then((k) => {
			this.translate.setDefaultLang(k);
		});

		this.coppel.login().subscribe((res: any) => {
			this.appToken = res.data.token;
		});

		this.company = localStorage.getItem('company');

		this.localTranslations.confirmation = await this.translate.get('confirmation').toPromise();

		this.localTranslations.confirmation_text = await this.translate.get('confirmation_text').toPromise();

		this.localTranslations.badPass = await this.translate.get('passwords-bad').toPromise();

		this.localTranslations.cancel = await this.translate.get('x-cancel').toPromise();

		this.localTranslations.submit = await this.translate.get('submit').toPromise();

		this.isCallCenter = localStorage.getItem('callcenter') != null;

		this.coppel.login().subscribe(async (cclogin: any) => {
			this.pageToken = cclogin.data.token;

			this.coppel.paises(this.pageToken).then((e: any) => {
				this.paises = e.data.response;

				this.coppel.regiones(this.pageToken).then((e: any) => {
					this.regiones_all = e.data.response;

					this.coppel.empresas(cclogin['data']['token']).then((e: any) => {
						this.empresas = e.data.response;
						this.conector.info(this.empresas);
					});
				});
			});
		});

		this.companyFrm.valueChanges.subscribe((data: any) => {
			this.categories_rule(data);

			this.contact_rules();
		});
	}

	contact_rules() {
		this.disabled_contacts =
			this.companyFrm.controls['relation'].valid == true &&
			this.companyFrm.controls['uname'].valid == true &&
			this.companyFrm.controls['umail'].valid == true &&
			this.companyFrm.controls['phone'].valid == true
				? false
				: true;
	}

	filterRegions(event) {
		const a = this.municipios.findIndex((e) => e.id_municipio == event.value);

		this.conector.info(a);

		const region = this.municipios[a].id_region;

		this.conector.info(region);

		this.regiones = this.regiones_all.filter((x) => x.id_region == region);

		this.conector.info(this.regiones);

		if (this.regiones.length == 0) {
			this.authForm.controls['region'].clearValidators();
		} else {
			this.authForm.controls['region'].setValidators(Validators.required);
		}

		this.authForm.controls['region'].updateValueAndValidity();
	}

	async send_coppel(reporting, data, anexos) {
		const cdata = {
			idFolioAmitai: data.reporting,
			opcPrioridad: reporting.priority.toString(),
			idCategoria: reporting.categoryid,
			idSubcategoria: reporting.subcategoryid,
			desReporte: JSON.stringify(reporting),
			anexos: anexos,
		};

		return this.coppel.submit_report(cdata, this.appToken);
	}

	enableFeedback(item) {
		this.companyFrm.get('enableFeedback').setValue(item == 'true');

		if (this.companyFrm.value.enableFeedback == false) {
			this.companyFrm.value.upass = null;
			this.companyFrm.value.urepeat = null;
		}
	}

	async setPassword() {
		let opt: any = false;

		if (this.companyFrm.value.upass != null && this.companyFrm.value.upass == this.companyFrm.value.urepeat) {
			const pwd = this.companyFrm.value.upass;

			const test = pwd.match(
				/(?=[A-Za-z0-9@#$*\-\/.%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$*\-\/.%^&+!=])(?=.{8,}).*$/g
			);

			if (test != null && test != undefined && test.length > 0) {
				await this.conector
					.report_puser({
						id: this.companyFrm.value.userid,
						reporting: this.companyFrm.value.reporting,
						password: this.companyFrm.value.upass,
					})
					.toPromise();

				opt = true;
			}
		}

		return opt;
	}

	async validatePassword(formdata) {
		let response = false;

		const rand = moment().format('x').toString();

		if (formdata.uname == null && formdata.umail == null && formdata.anonymous == false) {
			return Swal.fire({
				title: 'Ops',
				text: this.localTranslations.allfieldsarequired,
				icon: 'error',
				customClass: {
					confirmButton: 'round-me',
					cancelButton: 'round-me',
				},
			});
		}

		const model = {
			username: formdata.anonymous == false ? formdata.uname : `user-${rand}`,
			email: formdata.anonymous == false ? formdata.umail : `${rand}@no-mail.com`,
			password: formdata.upass == null ? '0x' : formdata.upass,
			reporting: formdata.reporting == null,
		};

		const { ok, data, msg }: any = await this.conector.report_user(model).toPromise();

		if (ok === true) {
			this.companyFrm.get('userid').setValue(data.id);
			response = true;
		} else {
			Swal.fire({
				title: 'Error',
				text: msg,
				icon: 'error',
				customClass: {
					confirmButton: 'round-me',
					cancelButton: 'round-me',
				},
			});
		}

		return response;
	}

	async getRelations(formdata: any) {
		// anonimo
		if (formdata.anonymous == false && formdata.umail == null) {
			return Swal.fire({
				title: 'Error',
				text: this.localTranslations.allfieldsarequired,
				icon: 'error',
				customClass: {
					confirmButton: 'round-me',
					cancelButton: 'round-me',
				},
			});
		}

		const mailer = await this.validatePassword(formdata);

		if (mailer == false) {
			return;
		}

		this.getCategories();
	}

	getCategories() {
		this.coppel.categorias(this.appToken).then((res: any) => {
			this.categories = res.data.response;
			this.index = 2;
			this.stepper.next();
		});
	}

	subcategoriesSelected(formdata) {
		const subcategory =
			this.subcategories[
				this.subcategories.findIndex((i: any) => i.idu_subcategoria == this.companyFrm.get('subcategory').value)
			];

		this.companyFrm.get('priority').setValue(subcategory.prioridad);
	}

	getSubcategories(formdata) {
		this.makeResume.category =
			this.categories[this.categories.findIndex((i) => i.idu_categoria == formdata.category)];

		this.coppel.subcategorias(this.appToken, formdata.category).then((res: any) => {
			if (res.data.estatus == 0) {
				Swal.fire({
					title: 'Error',
					text: res.data.mensaje,
					icon: 'error',
					customClass: {
						confirmButton: 'round-me',
						cancelButton: 'round-me',
					},
				});
			} else {
				// catch subcategories
				this.subcategories = res.data.response;

				// resetcategory
				this.companyFrm.get('subcategory').setValue(0);

				// evaluate
				this.categories_rule(formdata);
			}
		});
	}

	busquedaProfunda(needle, baseline: Array<any>) {
		let result = null;

		baseline.forEach((row) => {
			if (row.id === needle && result == null) {
				result = row;
			} else if (row.subs.length !== 0 && result == null) {
				result = this.busquedaProfunda(needle, row.subs);
			}
		});

		return result;
	}

	getQuestions(formdata) {
		if (formdata.category === null) {
			return Swal.fire({
				title: 'Error',
				text: this.localTranslations.allfieldsarequired,
				icon: 'error',
				customClass: {
					confirmButton: 'round-me',
					cancelButton: 'round-me',
				},
			});
		}

		this.index = 3;

		this.stepper.next();
	}

	async validateQuestions(formdata) {
		const a = formdata.alfa;
		const b = formdata.beta;
		const c = formdata.gama;
		const d = formdata.delta;

		if (a == null || a === '' || b == null || b === '' || c == null || c === '' || d == null || d === '') {
			Swal.fire({
				title: 'Error',
				text: this.localTranslations.allfieldsarequired,
				icon: 'error',
				customClass: {
					confirmButton: 'round-me',
					cancelButton: 'round-me',
				},
			});
		} else {
			this.index = 4;
			this.stepper.next();
		}
	}

	async completeForm(formdata) {
		if (formdata.enableFeedback == true) {
			const pwd = await this.setPassword();

			if (pwd == false) {
				return Swal.fire({
					title: 'Espera un segundo',
					text: 'La contraseña no cumple con los requerimientos.',
					icon: 'warning',
					customClass: {
						confirmButton: 'round-me',
						cancelButton: 'round-me',
					},
				});
			}
		}

		Swal.fire({
			title: 'Confirmar envío de la denuncia',
			text: this.localTranslations.confirmation_text,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#006fb9',
			customClass: {
				confirmButton: 'round-me',
				cancelButton: 'round-me',
			},
			cancelButtonColor: '#f44336',
			confirmButtonText: this.localTranslations.submit,
			cancelButtonText: this.localTranslations.cancel,
		}).then(async (result) => {
			if (result.value) {
				try {
					const form_values = this.authForm.value;

					const modelCoppel: any = {
						companyid: form_values.empresa,
						countryid: form_values.pais,
						stateid: form_values.estado,
						municipalityid: form_values.municipio,
						regionid: form_values.region,

						company:
							this.empresas[this.empresas.findIndex((x) => x.id_empresa == form_values.empresa)]
								.nombre_empresa,
						country: this.paises[this.paises.findIndex((x) => x.id_pais == form_values.pais)].nombre_pais,
						state: this.estados[this.estados.findIndex((x) => x.id_estado == form_values.estado)]
							.nombre_estado,
						municipality:
							this.municipios[this.municipios.findIndex((x) => x.id_municipio == form_values.municipio)]
								.nombre_municipio,
						region: this.regiones[this.regiones.findIndex((x) => x.id_region == form_values.region)]
							.nombre_region,
						centro: this.authForm.get('centro').value,

						relationid: formdata.relation,
						relation:
							this.relations[this.relations.findIndex((x: any) => x.id == formdata.relation)].relation,

						anonymous: formdata.anonymous == true ? 1 : 0,
						enableFeedback: formdata.enableFeedback,

						uname: formdata.uname,
						umail: formdata.umail,
						phone: formdata.phone,

						categoryid: formdata.category,
						subcategoryid: formdata.subcategory,
						priority: this.companyFrm.value.priority,
						category:
							this.categories[this.categories.findIndex((c) => c.idu_categoria == formdata.category)]
								.nom_categoria,
						subcategory:
							formdata.subcategory != null
								? this.subcategories[
										this.subcategories.findIndex((c) => c.idu_subcategoria == formdata.subcategory)
								  ].nom_subcategoria
								: 0,

						name: formdata.alfa,
						job: formdata.beta,
						date: formdata.gama,
						detail: formdata.delta,
						aditional: formdata.incident == null ? '0' : formdata.incident.toString(),
					};

					const modelCoppelExtras: any = modelCoppel;

					modelCoppelExtras.categoria =
						this.categories[
							this.categories.findIndex((c) => c.idu_categoria == formdata.category)
						].nom_categoria;
					modelCoppelExtras.subcategoria =
						this.subcategories[
							this.subcategories.findIndex((c) => c.idu_subcategoria == formdata.subcategory)
						].nom_subcategoria;
					modelCoppelExtras.pais =
						this.paises[this.paises.findIndex((x) => x.id_pais == form_values.pais)].nombre_pais;
					modelCoppelExtras.municipio =
						this.municipios[
							this.municipios.findIndex((x) => x.id_municipio == form_values.municipio)
						].nombre_municipio;
					modelCoppelExtras.estado =
						this.estados[this.estados.findIndex((x) => x.id_estado == form_values.estado)].nombre_estado;
					modelCoppelExtras.empresa =
						this.empresas[
							this.empresas.findIndex((x) => x.id_empresa == form_values.empresa)
						].nombre_empresa;
					modelCoppelExtras.region =
						this.regiones[this.regiones.findIndex((x) => x.id_region == form_values.region)].nombre_region;

					const model: any = {
						anonymous: formdata.anonymous == true ? 1 : 0,
						status: 'new',
						relation: formdata.relation,
						incident: formdata.incident == null ? '0' : formdata.incident.toString(),
						reporting: formdata.reporting,
						reporter: formdata.reporter,
						repname: 0,
						comments: formdata.comments,
						isCallCenter: 'false',
						isCallDate: formdata.isCallDate,
						isCallTime: formdata.isCallTime,
						isCallEnd: moment().format('HH:mm:ss').toString(),
						type: formdata.type,
						method: formdata.method,
						alfa: formdata.alfa,
						beta: formdata.beta,
						gama: formdata.gama,
						delta: formdata.delta,
						created: moment().format().toString(),
						enableFeedback: formdata.false,
						group: 69547,
						userid: formdata.userid,
						attachments: this.attachments,
						questions: this.gotQuestions,
						extras: JSON.stringify(modelCoppelExtras),
					};

					const { ok, data, msg }: any = await this.recursiveInsert(model);

					model.category = formdata.category;

					model.subcategory = formdata.subcategory;

					if (ok === true) {
						this.cortinilla = true;

						this.conector.info('Coppel send !');

						const response = await this.send_coppel(modelCoppel, data, this.allFilesToUpload);

						const logger = await this.conector
							.report_logger(
								{
									data: response,
									ticket: data.reporting,
								},
								formdata.reporting
							)
							.toPromise();

						console.info('PROCESS', logger);

						this.cortinilla = false;
						this.makeResume.reportingId = data.reporting;
						this.makeResume.finalMessage = data.message_es;
						this.index = 6;
						this.stepper.next();
					} else {
						Swal.fire('Sucedió un error tipo A', msg, 'error');
					}
				} catch (error) {
					Swal.fire('Sucedió un error tipo X', error, 'error');
				}
			}
		});
	}

	// recursive

	async recursiveInsert(model) {
		try {
			console.info('Intentando insert');

			const response = await this.conector.report_create(model).toPromise();

			return response == null ? this.recursiveInsert(model) : response;
		} catch (e) {
			console.info('IMPOSIBLE INSERTAR, REINTENTAR', e.message);

			this.recursiveInsert(model);
		}
	}

	toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	allFilesToUpload = [];

	async fileUpload(event) {
		const formData = new FormData();

		const { addedFiles } = event;

		const file = addedFiles[0];

		formData.append('file', file);

		this.files_to.push(file);

		let decoded: any = await this.toBase64(file);

		decoded = decoded.split(',')[1];

		this.allFilesToUpload.push({
			base64: decoded,
			file: addedFiles[0].name,
		});

		this.conector.report_upload(formData, this.company).subscribe(
			(response: any) => {
				const { ok, msg, data } = response;

				if (ok === true) {
					this.attachments.push(data);

					const fileReader = new FileReader();

					fileReader.readAsDataURL(addedFiles[0]);

					fileReader.onload = () => {
						this.files_to.push({
							archivo: addedFiles[0].name,
							base64: fileReader.result,
							folioAmitai: null,
						});
					};

					Swal.fire({
						title: 'Éxito',
						text: 'Tu documento fue cargado correctamente',
						icon: 'success',
					});
				} else {
					Swal.fire({
						title: 'Error',
						text: msg,
						icon: 'error',
						customClass: {
							confirmButton: 'round-me',
							cancelButton: 'round-me',
						},
					});
				}
			},
			(err) => {
				console.info(err);
			}
		);
	}

	removeItem(item) {
		const op = this.attachments.findIndex((x) => x === item);
		if (op !== -1) {
			this.attachments.splice(op, 1);
		}
	}

	async resetForm() {
		localStorage.clear();

		Swal.fire({
			html: this.makeResume.finalMessage,
			icon: 'success',
			title: await this.translate.get('success').toPromise(),
		}).then(() => {
			this.router.navigateByUrl('/selection');
		});
	}

	setRelation(ev) {
		const target =
			this.relations[this.relations.findIndex((x: any) => x.id == this.companyFrm.get('relation').value)];

		if (target != null) {
			this.makeResume.relation = target;
		}
	}

	async validateCompany(values) {
		const model = {
			reportingId: '34821370648',
			pais: this.paises[this.paises.findIndex((x) => x.id_pais == values.pais)].nombre_pais,
			municipio:
				this.municipios[this.municipios.findIndex((x) => x.id_municipio == values.municipio)].nombre_municipio,
			estado: this.estados[this.estados.findIndex((x) => x.id_estado == values.estado)].nombre_estado,
			empresa: this.empresas[this.empresas.findIndex((x) => x.id_empresa == values.empresa)].nombre_empresa,
			region: this.regiones[this.regiones.findIndex((x) => x.id_region == values.region)].nombre_region,
		};

		localStorage.setItem('company', String(34821370648));
		localStorage.setItem('token', String(34821370648));
		localStorage.setItem('location_user', JSON.stringify(values));
		localStorage.setItem('dictionary_user', JSON.stringify(model));

		this.conector.reporte_relations(values).subscribe((res: any) => {
			const { ok, data, msg } = res;

			if (ok === false) {
				return Swal.fire({
					title: 'Error',
					text: msg,
					icon: 'error',
					customClass: {
						confirmButton: 'round-me',
						cancelButton: 'round-me',
					},
				});
			}

			if (data.length == 0) {
				this.relations = [
					{
						id: 1,
						relation: 'Empleado',
					},
				];
			} else {
				this.relations = data;
			}

			this.index = 1;
		});

		this.stepper.next();
	}

	getRegions(formdata) {
		this.municipios = [];
		this.regiones = [];
		this.coppel.estados(this.pageToken, formdata.pais).then((e: any) => {
			this.estados = e.data.response;
		});
	}

	getTowns(formdata) {
		this.coppel.municipios(this.pageToken, formdata.estado).then(async (e: any) => {
			this.regiones = [];
			if (e.data.estatus == 0) {
				await Swal.fire({
					title: 'Error',
					text: e.data.mensaje,
					icon: 'error',
					customClass: {
						confirmButton: 'round-me',
						cancelButton: 'round-me',
					},
				});
			} else {
				this.municipios = e.data.response;
			}
		});
	}

	toBoolean(item) {
		this.companyFrm.get('anonymous').setValue(item == 'true');

		if (item === 'true') {
			this.companyFrm.controls['uname'].clearValidators();
			this.companyFrm.controls['umail'].clearValidators();
			this.companyFrm.controls['phone'].clearValidators();
		} else {
			this.companyFrm.controls['uname'].setValidators(Validators.required);
			this.companyFrm.controls['umail'].setValidators(Validators.required);
			this.companyFrm.controls['phone'].setValidators(Validators.required);
		}

		this.companyFrm.controls['uname'].updateValueAndValidity();
		this.companyFrm.controls['umail'].updateValueAndValidity();
		this.companyFrm.controls['phone'].updateValueAndValidity();
	}

	// RULES

	categories_rule(data) {
		if (
			this.companyFrm.get('category').value == null ||
			this.companyFrm.get('subcategory').value == null ||
			this.companyFrm.get('subcategory').value == 0 ||
			(this.companyFrm.get('subcategory').value == '' && this.subcategories.length != 0)
		) {
			this.disabled_cats = true;
		} else {
			this.disabled_cats = false;
		}
	}
}
