import { ConnectorEthicsSurveyService } from '../../../services/ethics-survey/conector-ethics-survey.service';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { MatTabChangeEvent, MatDialog, MatStepper, MatTreeFlattener, MatTreeFlatDataSource, MatPaginator, MatDialogRef, MAT_DIALOG_DATA, MatSelect } from '@angular/material';
import {  DatePipe, NumberFormatStyle } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './../../../services/authentication.service';
import { Pagination } from './../../../models/pagination';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ResponseHandler } from '../../../interfaces/ResponseHandler';
import { MatSelectSearchVersion } from 'ngx-mat-select-search';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
//import { debugger } from 'fusioncharts';


// arbol de usuarios 
interface FoodNode {
  name: string;
  id: string;
  count?: number;
  subs?: FoodNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  id: string;
  count: number;
  level
}

// search select name proyect 

@Component({
  selector: 'app-reports-ethics-survey',
  templateUrl: './reports-ethics-survey.component.html',
  styleUrls: ['./reports-ethics-survey.component.css']
})


export class ReportsEthicsSurveyComponent implements OnInit {
  


  // search select 

  /** list of banks */
  protected banks = [] ;

  /** control for the selected bank */
  public bankCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  

  // arbol 
  displayedColumns: string[] = ['name', 'count'];
  matSelectSearchVersion = MatSelectSearchVersion;  
  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.subs && node.subs.length > 0,
      name: node.name,
      id: node.id,
      count: node.count,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(node => node.level, node => node.expandable);
  treeFlattener = new MatTreeFlattener(this.transformer, node => node.level, node => node.expandable, node => node.subs);
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  // --------  
  pagination = new Pagination();
  GroupSelected : number= 0;
  //selectedIndex = 0;
  //activeRow = null;
  proyecto = [];
  ListGetUsersProyect = [];
  listUsers_ = [];

  idSurvey = 0 ;
  idType = 0 ;
  idSubType = 0 ;

  //isLinear = false;

  //step1 :boolean = true;
  stepEvaluated :boolean = false;
  stepGrupo :boolean = false;
  stepProyect :boolean = false;

  viewStepProyect :boolean = false;
  viewStepGroup :boolean = false;
  viewStepEvaluated :boolean = false;

  isDisabledClima :boolean = true;
  isDisabledEngagement :boolean = true;
  isDisabledNom035 :boolean = true;

  isViewClima :boolean = false;
  isViewEngagement :boolean = false;
  isViewNom035 :boolean = false;



  //isShownEngagement: boolean = false ;

  TypeProyect: FormGroup;
  DataProyect: FormGroup;
  
  buttonState: FormGroup;
  //nameType :string = "";
  //nameSubType :string = "";

  //ShowStep2 : boolean = true;
  ShowSpinnerStep2 =  true;

  nameGroupProyect = "Seleccione un grupo";

  //countX = 0;

 //@ViewChild(MatPaginator, { static: true }) paginationBar: MatPaginator;


  constructor( 
      public datepipe: DatePipe, 
      private conector: ConnectorEthicsSurveyService,
      public dialog: MatDialog,
      public translate: TranslateService,
      private _formBuilder: FormBuilder ,
      public auth: AuthenticationService) 
      {
        this.auth.setLanguage().then( k => this.translate.setDefaultLang(k) );
        this.pagination.length = 10;
        this.TypeProyect = this._formBuilder.group( {
          stepFirstType :  [''],
          stepSecondEngagement : ['']
        });
        this.DataProyect = this._formBuilder.group( {
          stepFirstType :  [''],
        }); 
        this.buttonState = this._formBuilder.group({
          Clima: [''],
          Engagement: [''],
          Nom035: ['']
        });
  }

  ngOnInit() {

    this.conector.tree_category().subscribe( (k: ResponseHandler) => {

      this.dataSource.data = k.data;

    });

    
    
  }

  CountUserGroup(id : number){

    let resultado2 = this.ListGetUsersProyect.find( item => item.groups === id);

   // return resultado2.length;
    if (resultado2 == undefined) {
      return "0"
    } else {

      if (Object.prototype.toString.call(resultado2) === '[object Array]') {
        // is array 
        return resultado2.length;
      } else {
        return "1";
      }

    }
  }

  getGroupsEva( id : number, nameGroup){
    this.GroupSelected = id;

    this.changeViewTreeGroup();
    
    this.listUsers_ =  this.ListGetUsersProyect.filter(  item => item.groups === id);

    this.changeViewSelectInput(this.idType , this.idSubType);

    this.nameGroupProyect = nameGroup;
    
    this.stepGrupo = false;


  }

  changeTypeProyect(id){

    this.conector.ethicsSurveyGet(0, this.pagination.init, this.pagination.limit).subscribe( (response: any) => {

      const {ok, data, msg} = response;
      if (ok === false) {
        return Swal.fire('Error', msg, 'error')

      } else {
        const {data, msg} = response;
        this.pagination.length = msg;
        this.stepProyect = true;
        if(id  == "1" ){
          this.proyecto = data.filter(data => data.type == 1 );
        } else if(id  == "2" ){
          this.proyecto = data.filter(data => data.type == 2 );
        }
        this.viewStepProyect = true;
        this.banks = this.proyecto.map(proyect => ({ id: proyect.id, name: proyect.nameProyect }));

        // search select 

        // set initial selection
        this.bankCtrl.setValue(this.banks[10]);

        // load the initial bank list
        this.filteredBanks.next(this.banks.slice());

        // listen for search field value changes
        this.bankFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBanks();
          });


          }
        });
  }

  LoadGroup(id): void {
    //alert(id);
    this.conector.GetUsersProyect(id).subscribe( (response: any) => {
      this.ListGetUsersProyect = [];
      //load users
      const { msg, data} = response;
      this.ListGetUsersProyect = data;
      //Load Tree
      // this.conector.tree_category().subscribe( (k: ResponseHandler) => {

      //   this.dataSource.data = k.data;

      // });

      //load data proyect
      //var ProyectItem  = this.proyecto.filter(x => x.id === id);

      var ProyectItem  = this.proyecto.filter(x => x.id === id);
      this.idSurvey = id;

      this.idType = ProyectItem[0].type;
      this.idSubType = ProyectItem[0].subType;

      this.changeViewSelectInput(this.idType, this.idSubType );
     



    });
  }
  

  changeViewTreeGroup(){
    if (this.stepGrupo == true) {
      this.stepGrupo = false;
      
    } else {
      this.stepGrupo = true;
      
    }

  }

  orchestrator(Type ){


      if (Type == 1) {
        this.PdfGetReportClima(this.idSurvey,this.GroupSelected );
      
      } else if (Type == 2) {

        this.PdfGetReportEngagement(this.GroupSelected);
      
      } else if (this.idType == 3 )   {
        
        //this.PdfGetReportNom035(identity);
        
      }

  }

  changeViewSelectInput(Type , SubType){

    if (Type == 1 && SubType == 1 ) {

      this.stepGrupo = true;
      this.viewStepGroup = true;
      this.isViewClima = true;
      this.isViewEngagement = true;
      
    } else if (Type == 1 && SubType == 2 ) {

      this.stepGrupo = true;
      this.viewStepGroup = true;
      this.isViewClima = true;
      this.isViewEngagement = true;
      
    } else if (Type == 2 ) {

      this.stepGrupo = true;
      this.viewStepGroup = true;
      this.isViewClima = true;
      this.isViewEngagement = true;
      this.viewStepEvaluated = true;
      
    }


  }










  PdfGetReportEngagement(id){
    this.ShowSpinnerStep2 = true;
    this.conector.PdfGetReportEngagement(this.idSurvey,id.toString()).subscribe( (response: any) => {

      const {ok, data, msg} = response;

      if (ok == true) {

        const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
        const downloadLink = document.createElement("a");
        const fileName = "Type1ReportEngagement.pdf";
  
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
  
      } else {
        
        return Swal.fire('Error','No se udo generar el informe ' + msg , 'error')
        
      }

    });
    this.ShowSpinnerStep2 = false;

    
  }

  PdfGetReportClima(idSurvey, idGropup){

    this.conector.PdfGetReportClima(idSurvey,idGropup.toString()).subscribe( (response: any) => {
      this.ShowSpinnerStep2 = true;
    
      const {ok, data, msg} = response;

      if (ok == true) {

        const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
        const downloadLink = document.createElement("a");
        const fileName = "Type2PdfGetReportClima.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.ShowSpinnerStep2 = false;

      } else {
          
        return Swal.fire('Error','No se udo generar el informe ' + msg , 'error')
        
      }

    });
    
  }

  PdfGetReportNom035(id){
    this.conector.PdfGetReportNom035(this.idSurvey,id).subscribe( (response: any) => {
      this.ShowSpinnerStep2 = true;
      
      const {ok, data, msg} = response;

      if (ok == true) {

      const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
      const downloadLink = document.createElement("a");
      const fileName = "PdfGetReportNom035.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.ShowSpinnerStep2 = false;

    } else {
        
      return Swal.fire('Error','No se udo generar el informe ' + msg , 'error')
      
    }
 
    });
  }

  downLoadNom035(identity : number, IdGroup : string = "") {
    if (identity > 0 ){ // nom035
      if (this.idType == 1 && this.idSubType == 1) {

        this.PdfGetReportEngagement(IdGroup);
      
      } else if (this.idType == 1 && this.idSubType == 2) {
       
        this.PdfGetReportClima(this.idSurvey, 0);
      
      } else if (this.idType == 2 )   {
        
        this.PdfGetReportNom035(identity);
        
      }

    }

  }

  
  // select search 

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: Bank, b: Bank) => a && b && a.id === b.id;
      });
  }

  protected filterBanks() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }


}

@Component({
  selector: 'reports-ethics-survey-dialog.component',
  templateUrl: 'reports-ethics-survey-dialog.component.html',
})
export class reports_ethics_survey_dialog {
  datain  = '';
  constructor(
    public dialogRef: MatDialogRef<reports_ethics_survey_dialog>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
      data = '1';
      this.datain = data;
    }

  onNoClick(): void {
    this.dialogRef.close(this.datain);
  }

}

// structure search 

export interface Bank {
  id: string;
  name: string;
}