import { ConectorKnowService } from './../../../services/conector-know.service';
import { Pagination } from './../../shared/pagination';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-estadisticas-actividad',
  templateUrl: './estadisticas-actividad.component.html',
  styleUrls: ['./estadisticas-actividad.component.css']
})
export class EstadisticasActividadComponent implements OnInit {

  inicio = null;

  final = null;

  records = [];

  constructor(private know: ConectorKnowService) {

  }

  ngOnInit() {

  }

  onClick() {

    const model = {
      inicio: this.convertDate(this.inicio),
      final: this.convertDate(this.final)
    };

    this.know.users_lastActivity(model).subscribe( x => {
      console.info('Upto', x);
      this.records = x.logs;
    });

  }

  changePage(ev) {}

  convertDate(ev) {
    return moment(ev).format("YYYY-MM-DD 00:00 a").toString();
  }

}
