import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { Pagination } from '../../../models/pagination';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})

export class CompaniesComponent implements OnInit {

  editing = {};
  rows: any = [];

  loadingIndicator = true;
  reorderable = true;

  pagination = new Pagination();

  @ViewChild(MatPaginator, { static: true }) thepaginator: MatPaginator;

  displayedColumns: string[] = ['id', 'company', 'reporting', 'created_at', 'action', 'options'];

  constructor(private conector: ConectorJsonService) {

  }

  ngOnInit() {
    this.pagination.setPageIndex(5);
    this.cargar();
  }

  changePage($event) {
    this.pagination.changePage($event);
    this.cargar();
  }

  cargar() {

    this.conector.obtenerCompanias(this.pagination.init, this.pagination.limit).subscribe( (response: any) => {

      const { rows, total } = response.data;
      this.rows = [];

      this.pagination.length = total;
      this.rows = rows;

    });

  }

}
