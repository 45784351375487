import { ResponseKnow } from './../../../interfaces/ResponseHandler';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConectorKnowService } from './../../../services/conector-know.service';
import { Pagination } from './../../shared/pagination';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import FileSaver from 'file-saver';
@Component({
  selector: 'app-estadisticas-cuestionarios',
  templateUrl: './estadisticas-cuestionarios.component.html',
  styleUrls: ['./estadisticas-cuestionarios.component.css']
})
export class EstadisticasCuestionariosComponent implements OnInit {

  pagination = new Pagination();
  cuestionarioFrm: FormGroup;

  areas = [];
  surveys = [];
  response = [];
  isExecuted = false;

  constructor(private know: ConectorKnowService, private fb: FormBuilder) {
    this.cuestionarioFrm = this.fb.group({
      area: null,
      survey: null,
      inicio: null,
      final: null
    })
  }

  ngOnInit() {
    this.know.areas_list().subscribe( (x:ResponseKnow) => {
      this.areas = x.structure;
    });
  }


  getResults() {
    this.know.report_surveys(this.cuestionarioFrm.value).subscribe( item => {
      this.response = item.questions;
    })
  }

  getSurveys(ev) {
    this.know.filter_surveys(this.cuestionarioFrm.value).subscribe( a => {
      this.surveys = a.surveys;
    });

  }

  descargaRow(row) {

    row.inicio = this.cuestionarioFrm.value.inicio;
    row.final = this.cuestionarioFrm.value.final;

    this.know.exportar_cuestionarios(row).then( rs => {
      FileSaver( rs, 'Cuestionarios.xlsx');
    });

  }

}
