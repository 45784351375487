import { async } from '@angular/core/testing';
import { Router } from '@angular/router';
import { SurveysAnswersComponent } from './surveys-answers/surveys-answers.component';
import { ResponseHandler } from './../../../interfaces/ResponseHandler';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList, MatDialog } from '@angular/material';
import Swal  from 'sweetalert2';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent implements OnInit {

  @ViewChild('target', { static: false}) target:MatSelectionList;
  @ViewChild(SurveysAnswersComponent, { static: false}) answersComponent: SurveysAnswersComponent;

  constructor(private conector: ConectorJsonService, private dialog: MatDialog, private router: Router) { }

  lisToSurveys = [];
  enableQuestionsForm = false;

  baseSurvey:any = {};
  baseSections = [];
  baseQuestions = [];
  baseAnswers = [];
  questionTypes = [];

  currentSection:any = {};
  currentQuestion:any = {};

  ngOnInit() {
    this.loadSurveys();
    this.conector.get_eConfigQuestionTypes().subscribe( (r:ResponseHandler) => {
      this.questionTypes = r.data;
    });
  }

  saveSurvey(ev) {
    this.conector.post_eConfig(ev).subscribe( async(r:ResponseHandler) => {

      console.info('Response_create', r);

      await this.loadSurveys();

      if(r.data == null) {
        this.enableQuestionsForm = true;
      }else {
        this.setCurrentSurvey(r.data);
      }

    })

  }

  async addSection(ev) {

    // Complementos
    ev.IdTst = this.baseSurvey.id;
    ev.IdLeng = 1;

    // Guarda
    await this.conector.post_eConfigSections(this.baseSurvey.id, ev).toPromise();

    // Reload
    this.conector.get_eConfingSections(this.baseSurvey.id).subscribe( (r:ResponseHandler) => {
      console.info('Reload', r.data);
      this.baseSections = r.data;
    });

  }

  addQuestion(ev){
    this.conector.post_eConfigQuestions(ev.IdSrs, ev).subscribe( (r:ResponseHandler)=> {
      this.getQuestions(this.currentSection);
    })
  }

  addAnswer(ev) {

    ev.IdQstn = this.currentQuestion.id;
    ev.Weight = 0;

    this.conector.post_eConfigAnswers(ev.IdQstn, ev).subscribe( (r:ResponseHandler) => {
      this.openAnswers(this.currentQuestion);
    })

  }

  loadSurveys() {
    this.conector.get_eConfig().subscribe( (r:ResponseHandler) => this.lisToSurveys = r.data );
  }


  setCurrentSurvey(ev){

    this.lisToSurveys.forEach( x => x.active = false );

    ev.active = true;

    this.baseSurvey = ev;

    this.conector.get_eConfingSections(ev.id).subscribe( (r:ResponseHandler) => {
      this.baseSections = r.data;
      this.enableQuestionsForm = true;
    });

  }

  getQuestions(ev) {
    this.conector.get_eConfigQuestions(ev.id).subscribe( (r:ResponseHandler) => {
      this.baseQuestions = r.data;
      this.currentSection = ev;
    })
  }

  async updateQuestion(ev) {
    await this.conector.put_eConfigQuestions(ev.id, {id: ev.id, type: ev.typeId, name: ev.name}).toPromise();
  }

  async updateSection(ev) {
    await this.conector.put_eConfigSection(ev.id, ev).toPromise();
  }

  openAnswers(data) {
    this.conector.get_eConfigAnswers(data.id).subscribe( (r:ResponseHandler) => {
      this.baseAnswers = r.data;
      this.currentQuestion = data;
      this.answersComponent.openModal = true;
    });
  }

  destroyQuestion(ev) {
    Swal.fire({
      icon: 'warning',
      title: '&iquest; Está seguro de realizar esta acción ?',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.conector.del_eConfigQuestion(ev.id).subscribe( (r:ResponseHandler) => {
          this.getQuestions(this.currentSection);
        });
      }
    })
  }

  deleteAnswer(ev) {
    Swal.fire({
      icon: 'warning',
      title: '&iquest; Está seguro de realizar esta acción ?',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.conector.del_eConfigAnswer(ev.id).subscribe( (r:ResponseHandler) => {
          this.openAnswers(this.currentQuestion);
        })
      }
    })
  }

  deleteSection(ev) {
    Swal.fire({
      icon: 'warning',
      title: '&iquest; Está seguro de realizar esta acción ?',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.conector.del_eConfigSections(ev.id).subscribe( (r:ResponseHandler) => {
          this.conector.get_eConfingSections(this.baseSurvey.id).subscribe( (r:ResponseHandler) => {
            this.baseSections = r.data;
          });
        })
      }
    })
  }

  resetForm() {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate(['/manager/evaluation-surveys']);
    })
  }

}
