import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ConnectorJobProfileService {

  constructor(private http: HttpClient) { }

  jobprofile_get(init = 0, limit = 5) {

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token'),
      'Content-type': 'application/json'
    });

    return this.http.get(`${environment.server}/jobprofile?limit=${limit}&offset=${init}`, { headers });
  }

  jobprofile_all() {

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token'),
      'Content-type': 'application/json'
    });

    return this.http.get(`${environment.server}/jobprofile/getall`, { headers });
  }

  jobprofile_post(formdata) {
    return this.http.post(`${environment.server}/jobprofile`, formdata);
  }

  jobprofile_put(formdata) {

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token'),
      'Content-type': 'application/json'
    });

    return this.http.put(`${environment.server}/jobprofile/${formdata.id}`, formdata, { headers });
  }

  jobprofile_delete(rowId) {

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token'),
      'Content-type': 'application/json'
    });

    return this.http.delete(`${environment.server}/jobprofile/${rowId}`, { headers });
  }

  dimentions_get(id) {

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token'),
      'Content-type': 'application/json'
    });

    return this.http.get(`${environment.server}/dimentions?id=${id}`, { headers });
  }

  dimentions_post(formdata) {

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token'),
      'Content-type': 'application/json'
    });

    return this.http.post(`${environment.server}/dimentions`, formdata, { headers });
  }

  get_job_surveys(data) {
    return this.http.post(`${environment.server}/jobprofile/submodules`, data);
  }

  put_job_surveys(data) {
    return this.http.put(`${environment.server}/jobprofile/submodules`, data);
  }

}