import { ResponseKnow } from './../../../interfaces/ResponseHandler';
import { ConectorKnowService } from './../../../services/conector-know.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Pagination } from '../../../models/pagination';

@Component({
  selector: 'app-configuracion-usuarios',
  templateUrl: './configuracion-usuarios.component.html',
  styleUrls: ['./configuracion-usuarios.component.css']
})
export class ConfiguracionUsuariosComponent implements OnInit {

  isEditable = false;
  isNewRequest = false;
  showRecords = true;
  userForm: FormGroup;
  all_users = [];

  pagination = new Pagination();

  constructor(private fb: FormBuilder, private know:ConectorKnowService, private conector: ConectorJsonService, private snap: ActivatedRoute) { }

  ngOnInit() {

    this.snap.params.subscribe( (ks: Params) => {

      if (ks.id == null) {

        this.showRecords = true;

        const formdata = new FormData();

        formdata.append("companySession", localStorage.getItem('know_session')) ;
        formdata.append("order[0][column]", "4");
        formdata.append("order[0][dir]", "desc");
        formdata.append("start", "0");
        formdata.append("length", "25");

        this.know.users_all(formdata).subscribe( (res: any) => {

          console.info(res);

          this.all_users =  res.users.data;

          this.pagination.length = res.users.total;

        });

      } else {

        this.showRecords = false;
        this.isNewRequest = true;
        this.isEditable = true;

      }

    })

  }

  changePage(ev) {
    console.info('Aux', ev);


  }

  create() {}

  onClick() {}

}
