import { ComunikatRoutingModule } from './comunikat-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';




@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ComunikatRoutingModule
  ]
})

export class ComunikatModule { }
