// export const environment = {

//   production: false,
//   base: 'https://test-api.amitaisystem.com',
//   server: 'https://test-api.amitaisystem.com/api/v1',

//   public: 'https://test-api.amitaisystem.com/public',
//   private: 'https://test-api.amitaisystem.com/private',
//   tiny: '5iboyhuj1h86j1nz1avwhfiknu3qs31rwditmn4x2bnd4nb8',

//   know_server: 'http://apicmsknowapp.cinnco.co/api',
//   know_email: 'rduran@amitai.com',
//   know_password: 'Amitai2020',

//   // coppel_auth: 'https://app.coppel.cloud/api/sso/v1/app/authenticate',
//   // coppel_api: 'https://apigateway.coppel.com:58443/lineadenuncia-dev/api',

//   coppel_auth: 'https://juridicosajv3.coppel.space/ms-login-homologado/api/v1/exp/token/obtener-token',
//   coppel_api: 'https://juridicodev.coppel.com/ms-linea-de-denuncia/api/v1',
//   // coppel_appId: 'ce088dc0-fe4a-48a0-9633-6e4b50ddcf23',
//   // coppel_appKey: '3328919a9c48c9b583bc6c833028095fc7084c915684f97bcfc520a0037d76a7'

//   coppel_appId: '2c9e82f9-cff8-4583-935b-337e6159d246',
//   coppel_appKey: '526bd05b06ab4a8380d84c44b2a98395ccce726b4313d89a365d6832dad15d58'


// };

export const environment = {

  production: true,
  base: 'https://api.amitaisystem.com',
  server: 'https://api.amitaisystem.com/api/v1',

  public: 'https://api.amitaisystem.com/public',
  private: 'https://api.amitaisystem.com/private',
  tiny: '5iboyhuj1h86j1nz1avwhfiknu3qs31rwditmn4x2bnd4nb8',

  know_server: 'http://apicmsknowapp.cinnco.co/api',
  know_email: 'rduran@amitai.com',
  know_password: 'Amitai2020',

  coppel_auth: 'https://juridicosajv3.coppel.space/ms-login-homologado/api/v1/exp/token/obtener-token',
  coppel_api: 'https://juridico.coppel.space/ms-linea-de-denuncia/api/v1',

  // coppel_auth: 'https://app.coppel.cloud/api/sso/v1/app/authenticate',
  // coppel_api: 'https://sajproveedores.coppel.com/LINEADEDENUNCIARAC/api',

  coppel_appId: '2c9e82f9-cff8-4583-935b-337e6159d246',
  coppel_appKey: '526bd05b06ab4a8380d84c44b2a98395ccce726b4313d89a365d6832dad15d58'

  // coppel_appId: 'ce088dc0-fe4a-48a0-9633-6e4b50ddcf23',
  // coppel_appKey: '3328919a9c48c9b583bc6c833028095fc7084c915684f97bcfc520a0037d76a7'

};