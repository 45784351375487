// Type 1
export class configModelType1{
  id : number; 
  nameProyect : string; 
  nameCompany : string; 
  idModule : number; 
  idUser : string;
  idAccess : string;
  startDate : string;
  endDate : string; 
  createDate : string;
  engagement : string;
  idAccount : string; 
  type : string;
  subType : string;
  description : string;
  predetermined : string;
  idLanguaje : string;
  status : string;
  random : string;
  category : topicsSurvey[];
  series : seriesSurveyConfig[];
  questions : questionsSurveyConfig[];
  answer : answerQuestionsSurveyConfig[];
  ownQuestions : ownQuestionsSurveyConfig[];
  typeAnswer : questionsTypeSurveyConfig[];
  ListUsersSurvey : UsersSurvey[];
}

export class answerUsers {
  host : string ;
  answer : answerQuestionsSurveyConfig[];
  dateAnswers : string;
  typeSurvey : string;

}

export class topicsSurvey {
  id : number;
  idSurveyConfig : number;
  idCategory : number;
  name : string;
  description : string;
  active : number;
  orderlist : number;
}

export class seriesSurveyConfig {
  id : number;
  idSurveyConfig : number;
  name : string;
  description : string;
  idTopic : number;
  active : number;
  orderList : number;
  change : number;
  idSeries : number;
}

export class questionsSurveyConfig {

  idquestion : number;
  idserie : number;
  idtype : number;
  name : string;
  active : number;
  orderlist : number;
  idFactors : number;
  GroupFactor : number;
  idrelacion : number;
  change : number;

}

export class answerQuestionsSurveyConfig {

  id : number;
  answer : string;
  type : string;

}

export class ownQuestionsSurveyConfig {
  id  : number;
  question  : string;
  answer : string;
}

export class questionsTypeSurveyConfig {
  id : number;
  typeid : string;
  section1 : string;
  section2 : string;
  section3 : string;
  description : string;
  orderlist : number;
  active : number;
}

// Type 2
export class configModelType2{
  id : number; 
  nameProyect : string; 
  nameCompany : string; 
  idModule : number; 
  idUser : string;
  idAccess : string;
  startDate : string;
  endDate : string; 
  createDate : string;
  engagement : string;
  idAccount : string; 
  type : string;
  subType : string;
  description : string;
  predetermined : string;
  idLanguaje : string;
  status : string;
  random : string;
  ListUsersSurvey : UsersSurvey[];
}

export class UsersSurvey {
  id : string;
  idGroup : string;
}