import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import Swal from "sweetalert2";
import { ConnectorJobProfileService } from "../../../../services/job-profile.service";

@Component({
    selector: 'app-jobprofile-analisisriesgo',
    templateUrl: './jobprofile-analisisriesgo.component.html',
    styleUrls: ['./jobprofile-analisisriesgo.component.css']
})

export class JobProfileAnalisisRiesgoComponent implements OnInit {

    isLinear = false;
    jobProfileAnalisisRiesgo: FormGroup;
    displayedColumns: string[] = [ 'seleccionado', 'riesgo' ];
    puesto : string;
    descripcion : string;
    idJobProfile : number;
    dataSource : Dimension[];
    panelOpenState = false;

    constructor(
        private form: FormBuilder,
        private conector: ConnectorJobProfileService, 
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) {

            this.puesto = data.puesto;
            this.descripcion = data.descripcion;
            this.idJobProfile = data.id;

            this.jobProfileAnalisisRiesgo = this.form.group({
                dimentions : this.dataSource,
                idJobProfile : data.id
            });
        }
    
    ngOnInit(): void {

        this.conector.dimentions_get(this.idJobProfile).subscribe( (response: any) => {
            const {data, msg} = response;
            this.dataSource = data as Dimension[];

            console.log(this.dataSource);
          });
        
    }
    
    probabilidadLabel(value: number): string {

        switch(value) {
            case 1: return "Nula";
            case 2: return "Muy remota";
            case 3: return "Remota";
            case 4: return "Ocasional";
            case 5: return "Frecuente";
            case 6: return "Muy frecuente";
        }
      }
    
      impactoLabel(value: number): string {
  
          switch(value) {
              case 1: return "Imperceptible";
              case 2: return "Bajo";
              case 3: return "Medio";
              case 4: return "Alto";
              case 5: return "Muy alto";
              case 6: return "Catastrófico";
          }
        }

    ProSeleccionado(row? : Dimension, evento? : any) {

        row.probabilidad = evento.value;

    }

    ImpSeleccionado(row? : Dimension, evento? : any) {

        row.impacto = evento.value;

    }

    NumSeleccionados(row? : Dimension, evento? : any) {

        var dimensionesHonestidad = 5;
        var dimensionesIncidencia = 4;

        var maxSelected = row.dimensionH === 1 ? dimensionesHonestidad : dimensionesIncidencia;

        var seleccionados = this.dataSource.filter(dimention => dimention.dimensionH === row.dimensionH && dimention.seleccionado).length;

        if (seleccionados < maxSelected)
            row.seleccionado = evento.checked;
        else
        {
            row.seleccionado = false;
            evento.source.checked = false;
        }
    }

    submit(formdata) {

        formdata.dimentions = this.dataSource.filter(f=> f.seleccionado);
        console.log(formdata);

        this.conector.dimentions_post(formdata).subscribe( (d: any) => {
            const { ok, msg, data} = d;
            if (ok === true) {
              Swal.fire({
                title: 'Puesto configurado correctamente.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                reverseButtons: true
              }).then((result) => {
                this.dialog.closeAll();
              });
            } else {
              Swal.fire('Error', msg, 'error');
            }
          });
      }
    
}

export interface Dimension {
    id: number;
    dimension: string;
    orden: number;
    editable: boolean;
    dimensionH: number;
    seleccionado: boolean;
    peso: number;
    linferior: number;
    lsuperior: number;
    probabilidad: number;
    impacto: number;
}