import { UserIdleModule } from 'angular-user-idle';
import { CKEditorModule } from 'ckeditor4-angular';
import { JwPaginationModule } from 'jw-angular-pagination';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { IconPickerModule } from 'ngx-icon-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
	PERFECT_SCROLLBAR_CONFIG,
	PerfectScrollbarConfigInterface,
	PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { QuillModule } from 'ngx-quill';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EditorModule } from '@tinymce/tinymce-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AdminComponent } from './layouts/admin/admin.component';
import { AdminLayoutModule } from './layouts/admin/admin.module';
import { ComunikatHomeComponent } from './layouts/comunikat/comunikat-home/comunikat-home.component';
import { ComunikatModule } from './layouts/comunikat/comunikat.module';
import { EvaluationsComponent } from './layouts/evaluations/evaluations.component';
import { EvaluationsModule } from './layouts/evaluations/evaluations.module';
import { LearningHomeComponent } from './layouts/learning/learning-home/learning-home.component';
import { LearningModule } from './layouts/learning/learning.module';
import { ManagerComponent } from './layouts/manager/manager.component';
import { ManagerModule } from './layouts/manager/manager.module';
import { AppBreadcrumbComponent } from './layouts/shared/breadcrumb/breadcrumb.component';
import { AppHeaderComponent } from './layouts/shared/header/header.component';
import { AppSidebarComponent } from './layouts/shared/sidebar/sidebar.component';
import { SupervisorComponent } from './layouts/supervisor/supervisor.component';
import { SupervisorModule } from './layouts/supervisor/supervisor.module';
import { UserComponent } from './layouts/user/user.component';
import { UserLayoutModule } from './layouts/user/user.module';
import { DemoMaterialModule } from './material-module';
import { AllPipe, FilterParentPipe } from './pipes/all.pipe';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { CustomErrorHandlerService } from './services/custom-error-handler.service';
import { InterceptorService } from './services/interceptor.service';
import { HeaderNavComponent } from './shared/header-nav/header-nav.component';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { CatalogsComponent } from './views/admin/catalogs/catalogs.component';
import { LicenseComponent } from './views/authentication/license/license.component';
import { LoginComponent } from './views/authentication/login/login.component';
import { ResetComponent } from './views/authentication/reset/reset.component';
import { SelectionComponent } from './views/authentication/selection/selection.component';
import { ComunicaEncuestasResultadosComponent } from './views/comunika/comunica-encuestas-resultados/comunica-encuestas-resultados.component';
import { ComunicaEvaluacionesResultadosComponent } from './views/comunika/comunica-evaluaciones-resultados/comunica-evaluaciones-resultados.component';
import { ErrorComponent } from './views/error/error.component';
import { CarouselComponent } from './views/ethics-survey/carousel/carousel.component';
import { ConfigProyectComponent } from './views/ethics-survey/config-proyect/config-proyect.component';
import { EthicsSurveyTypeOneComponent } from './views/ethics-survey/evaluated-answer/ethics-survey-type-one/ethics-survey-type-one.component';
import { ethics_survey_type_dialog } from './views/ethics-survey/evaluated-answer/ethics-survey-type-two/ethics-survey-type-dialog.component';
import { EthicsSurveyTypeTwoComponent } from './views/ethics-survey/evaluated-answer/ethics-survey-type-two/ethics-survey-type-two.component';
import { EvaluatedAnswerComponent } from './views/ethics-survey/evaluated-answer/evaluated-answer.component';
import { CreateConfigComponent } from './views/ethics-survey/modal/create-config/create-config.component';
import { DeleteConfigComponent } from './views/ethics-survey/modal/delete-config/delete-config.component';
import { UpdateConfigComponent } from './views/ethics-survey/modal/update-config/update-config.component';
import {
	reports_ethics_survey_dialog,
	ReportsEthicsSurveyComponent,
} from './views/ethics-survey/reports-ethics-survey/reports-ethics-survey.component';
import { CompletionComponent } from './views/evaluations/completion/completion.component';
import { CreateCollaboratorComponent } from './views/general/create-collaborator/create-collaborator.component';
import { CallcenterComponent } from './views/integriline/callcenter/callcenter.component';
import { CategoryAddonsComponent } from './views/integriline/category-addons/category-addons.component';
import { CategoryComponent } from './views/integriline/category/category.component';
import { ChartsComponent } from './views/integriline/charts/charts.component';
import { EnterpriseComponent } from './views/integriline/enterprise/enterprise.component';
import { HomeSupervisorComponent } from './views/integriline/home-supervisor/home-supervisor.component';
import { InformationComponent } from './views/integriline/information/information.component';
import { GroupHandlersComponent } from './views/integriline/modals/group-handlers/group-handlers.component';
import { GroupQuestionsComponent } from './views/integriline/modals/group-questions/group-questions.component';
import { ReportComponent } from './views/integriline/report/report.component';
import { TrackingComponent } from './views/integriline/tracking/tracking.component';
import { LoginappComponent } from './views/internal/loginapp/loginapp.component';
import { ReportappComponent } from './views/internal/reportapp/reportapp.component';
import { CollaboratorComponent } from './views/manager/collaborator/collaborator.component';
import { ConfigurationComponent } from './views/manager/configuration/configuration.component';
import { HomeManagerComponent } from './views/manager/home-manager/home-manager.component';
import { IntegrityHomeComponent } from './views/manager/home/integrity-home/integrity-home.component';
import { SurveyHomeComponent } from './views/manager/home/survey-home/survey-home.component';
import { JobProfileComponent } from './views/manager/jobprofile/jobprofile.component';
import { ConfigJobProfilesComponent } from './views/manager/modals/config-jobprofiles/config-jobprofiles.component';
import { ConfigurateJobProfileComponent } from './views/manager/modals/configurate-jobprofile/configurate-jobprofile.component';
import { CreateJobProfileComponent } from './views/manager/modals/create-jobprofile/create-jobprofile.component';
import { CreateManyCollaboratorComponent } from './views/manager/modals/create-many-collaborator/create-many-collaborator.component';
import { CreateUserComponent } from './views/manager/modals/create-user/create-user.component';
import { ImportCollaboratorComponent } from './views/manager/modals/import-collaborator/import-collaborator.component';
import { JobProfileAnalisisRiesgoComponent } from './views/manager/modals/jobprofile-analisisriesgo/jobprofile-analisisriesgo.component';
import { JobprofileSurveysComponent } from './views/manager/modals/jobprofile-surveys/jobprofile-surveys.component';
import { ManageUserComponent } from './views/manager/modals/manage-user/manage-user.component';
import { ModalChildrenComponent } from './views/manager/modals/modal-children/modal-children.component';
import { SubmoduleCollaboratorComponent } from './views/manager/modals/submodule-collaborator/submodule-collaborator.component';
import { TycosComponent } from './views/manager/modals/tycos/tycos.component';
import { ModuleComponent } from './views/manager/module/module.component';
import { ReportsComponent } from './views/manager/reports/reports.component';
import { SurveyBuilderComponent } from './views/manager/surveys/survey-builder/survey-builder.component';
import { SurveyCreateComponent } from './views/manager/surveys/survey-create/survey-create.component';
import { SurveysAnswersComponent } from './views/manager/surveys/surveys-answers/surveys-answers.component';
import { SurveysComponent } from './views/manager/surveys/surveys.component';
import { PickersModule } from './views/shared/pickerLoader.module';
import { ToDoComponent } from './views/validators/to-do/to-do.component';
import { ToReportComponent } from './views/validators/to-report/to-report.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
	wheelSpeed: 2,
	wheelPropagation: true,
};

export const MY_DATE_FORMATS = {
	parse: {
		dateInput: 'YYYY-MM-DD',
	},
	display: {
		dateInput: 'YYYY-MM-DD',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};
@NgModule({
	declarations: [
		HeaderNavComponent,
		AppComponent,
		AdminComponent,
		ManagerComponent,
		UserComponent,
		CollaboratorComponent,
		JobProfileComponent,
		SubmoduleCollaboratorComponent,
		ReportsComponent,
		SpinnerComponent,
		LoginComponent,
		SelectionComponent,
		AppHeaderComponent,
		AppSidebarComponent,
		AppBreadcrumbComponent,
		HomeManagerComponent,
		CatalogsComponent,
		ReportComponent,
		ConfigurationComponent,
		ModalChildrenComponent,
		TycosComponent,
		ModuleComponent,
		SelectionComponent,
		GroupQuestionsComponent,
		GroupHandlersComponent,
		ManageUserComponent,
		CreateUserComponent,
		SupervisorComponent,
		HomeSupervisorComponent,
		AllPipe,
		ErrorComponent,
		TrackingComponent,
		InformationComponent,
		ConfigProyectComponent,
		CreateConfigComponent,
		CreateCollaboratorComponent,
		CreateManyCollaboratorComponent,
		ImportCollaboratorComponent,
		EvaluatedAnswerComponent,
		CreateJobProfileComponent,
		ConfigJobProfilesComponent,
		ConfigurateJobProfileComponent,
		JobProfileAnalisisRiesgoComponent,
		EnterpriseComponent,
		CallcenterComponent,
		ChartsComponent,
		EthicsSurveyTypeOneComponent,
		EthicsSurveyTypeTwoComponent,
		ethics_survey_type_dialog,
		CarouselComponent,
		ComunicaEvaluacionesResultadosComponent,
		LicenseComponent,
		CategoryComponent,
		CategoryAddonsComponent,
		ComunikatHomeComponent,
		LearningHomeComponent,
		FilterParentPipe,

		ResetComponent,
		ReportsEthicsSurveyComponent,

		EvaluationsComponent,
		ComunicaEncuestasResultadosComponent,

		reports_ethics_survey_dialog,
		UpdateConfigComponent,
		DeleteConfigComponent,
		CompletionComponent,
		SurveysComponent,
		SurveyCreateComponent,
		SurveyBuilderComponent,
		SurveysAnswersComponent,
		SurveyHomeComponent,
		IntegrityHomeComponent,
		ToReportComponent,
		ToDoComponent,
		JobprofileSurveysComponent,
		LoginappComponent,
		ReportappComponent,
	],

	imports: [
		JwPaginationModule,
		BrowserModule,
		CommonModule,
		BrowserAnimationsModule,
		DemoMaterialModule,
		FormsModule,
		FlexLayoutModule,
		PerfectScrollbarModule,
		HttpClientModule,
		SharedModule,
		NgMultiSelectDropDownModule.forRoot(),
		AppRoutingModule,
		MatInputModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatButtonModule,
		MatPaginatorModule,
		MatCheckboxModule,
		UserLayoutModule,
		QuillModule.forRoot(),
		MomentTimezonePickerModule,
		EditorModule,
		NgxDropzoneModule,
		NgxSliderModule,
		MatBadgeModule,
		MatTreeModule,
		CKEditorModule,
		IconPickerModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => {
					return new TranslateHttpLoader(http);
				},
				deps: [HttpClient],
			},
		}),

		/**************************************** Start Layouts ****************************************/

		AdminLayoutModule,
		ManagerModule,
		SupervisorModule,
		SharedPipesModule,
		ComunikatModule,
		LearningModule,
		EvaluationsModule,

		/**************************************** End Layouts ****************************************/

		UserIdleModule.forRoot({ idle: 3600, timeout: 30, ping: 120 }),

		/**************************************** Arboles de datos ****************************************/

		PickersModule,

		DragDropModule,
		NgxMatSelectSearchModule,
	],

	providers: [
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		{
			provide: ErrorHandler,
			useClass: CustomErrorHandlerService,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptorService,
			multi: true,
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: MY_DATE_FORMATS,
		},
		DatePipe,
	],

	exports: [],

	schemas: [CUSTOM_ELEMENTS_SCHEMA],

	bootstrap: [AppComponent],

	entryComponents: [
		ModalChildrenComponent,
		GroupHandlersComponent,
		GroupQuestionsComponent,
		ManageUserComponent,
		CreateUserComponent,
		SubmoduleCollaboratorComponent,
		TycosComponent,
		CreateCollaboratorComponent,
		CreateManyCollaboratorComponent,
		ImportCollaboratorComponent,
		CreateJobProfileComponent,
		ConfigurateJobProfileComponent,
		JobProfileAnalisisRiesgoComponent,
		ReportComponent,
	],
})
export class AppModule {}
