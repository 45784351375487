import { ConectorJsonService } from './../../../services/conector-json.service';
import { RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-to-report',
  templateUrl: './to-report.component.html',
  styleUrls: ['./to-report.component.css']
})
export class ToReportComponent implements OnInit {

  grant: any = 0;

  constructor(private conector: ConectorJsonService, private snap: ActivatedRoute, private router: Router) { }

  async ngOnInit() {

    this.snap.paramMap.subscribe((d: any) => {

      this.conector.reporte_validaToken(d.params.id).subscribe((e: any) => {

        if (e.ok == true) {

          localStorage.setItem('company', e.data);

          this.router.navigateByUrl('/license');

          this.grant = 'Correcto, redireccionando...';

        }
        else {

          Swal.fire('Error', 'Código no coindice', 'error');

          this.grant = 'El código no es valido, validar nuevamente.';

        }

      });

    });

  }

}
