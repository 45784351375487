import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-eva-instructions-general',
  templateUrl: './eva-instructions-general.component.html',
  styleUrls: ['./eva-instructions-general.component.css']
})
export class EvaInstructionsGeneralComponent implements OnInit {

  @Input() data: any = null;
  @Input() openModal:boolean = false;
  @Output() confirmation:EventEmitter<any> = new EventEmitter();

  public webcam:boolean = false;
  public readlen:any = 0;

  constructor() { }

  ngOnInit() {

  }

  startLen() {
    this.readlen = moment();
    this.openModal = true;
  }

  continue() {

    if(this.data.instructions !== null && this.data.instructions != "") {

      const timer = moment.duration(moment().diff(this.readlen)).asSeconds();
      const words = this.data.instructions.match(/(\w+)/g).length;

      const factor = (words/timer) * 1000;

      this.confirmation.next({ webcam: this.webcam, timer, words, factor });

    } else {
      this.confirmation.next({ webcam: this.webcam, timer:0, words:0, factor:1});
    }

    this.webcam = false;
    this.readlen = null;
    this.openModal = false;

  }

}
