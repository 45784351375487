import { ResponseHandler } from './../../../interfaces/ResponseHandler';
import { Pagination } from './../../shared/pagination';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { MatDialog } from '@angular/material';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  pagination = new Pagination();
  isEditable = false;

  questionsForm: FormGroup;
  questions = [];
  selected = null;

  constructor(private fb: FormBuilder, private conector: ConectorJsonService, public dialog: MatDialog, private service: AuthenticationService, private translate: TranslateService) {

    this.questionsForm = this.fb.group({
      english: [null],
      spanish: [null, Validators.required]
    });

    this.service.setLanguage().then( k => this.translate.setDefaultLang(k));

  }

  async ngOnInit() {
    this.loadQuestions();
  }

  updateRows($events) {

    console.info($events);

    const { pageIndex, pageSize } = $events;

    this.pagination.pageSize = pageSize;

    this.pagination.init = pageIndex;

    this.questions = [];

    this.conector.questions_get(this.pagination.init, this.pagination.pageSize).subscribe( (x: ResponseHandler) => {
      this.questions = x.data;
      this.pagination.length = parseInt(x.msg);
    });

  }

  async onClick(formdata) {

    if (this.isEditable === false) {

      this.conector.questions_post(formdata).subscribe( async (d: ResponseHandler) => {

        const msg = await this.translate.get('process-complete').toPromise();
        Swal.fire({ position: 'center', icon: 'success', title: msg, showConfirmButton: false, timer: 1000 });

        this.questionsForm.reset();
        this.loadQuestions();

      });

    } else {

      Object.keys(formdata).forEach( key => {
        this.selected[key] = formdata[key];
      });

      this.conector.questions_put(this.selected).subscribe( async (d: ResponseHandler) => {

        const msg = await this.translate.get('process-complete').toPromise();
        Swal.fire({ position: 'center', icon: 'success', title: msg, showConfirmButton: false, timer: 1000 });

        this.questionsForm.reset();
        this.isEditable = false;

        this.loadQuestions();
      });

    }

  }

  loadQuestions() {
    this.conector.questions_get(this.pagination.init, this.pagination.pageSize).subscribe( (res: ResponseHandler) => {
      const { ok, data, msg }  = res;
      this.pagination.length = parseInt(msg);
      this.questions = data;
    });
  }

  editRow(row) {
    this.isEditable = true;
    this.selected = row;
    Object.keys(row).forEach(key => {
      if (this.questionsForm.get(key)) {
        this.questionsForm.controls[key].setValue(row[key]);
      }
    });
  }

  deleteRow(row) {

    this.conector.questions_delete(row.id).subscribe( async(d: ResponseHandler) => {

      console.info(d);

      if( d.ok == true) {

        const title = await this.translate.get('process-complete').toPromise();

        Swal.fire( title, ' ', 'success');

        this.loadQuestions();

      } else {

        const title = await this.translate.get('warning-error').toPromise();

        Swal.fire( title, d.msg, 'error');

      }

    });

  }

}
