import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ConnectorGroupService {

  constructor(private http: HttpClient) { }

  group_tree() {

    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token'),
      'Content-type': 'application/json'
    });

    return this.http.get(`${environment.server}/groups/tree`, { headers });
  }

  import_users(data) {
    return this.http.post(`${environment.server}/users/import`, data);
  }

}
