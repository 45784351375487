import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { NgxCsvParser, NgxCSVParserError } from "ngx-csv-parser";
import Swal from "sweetalert2";
import { ResponseHandler } from "../../../../interfaces/ResponseHandler";
import { ConnectorGroupService } from "../../../../services/Admin/conector-group.service";
import { ConectorJsonService } from "../../../../services/conector-json.service";
import { GenericCatalogsService } from "../../../../services/GenericCatalogs.service";
import { ConnectorJobProfileService } from "../../../../services/job-profile.service";

@Component({
    selector: 'app-import-collaborator',
    templateUrl: './import-collaborator.component.html',
    styleUrls: ['./import-collaborator.component.css']
  })
  export class ImportCollaboratorComponent implements OnInit {

    files: File[] = [];
    fileUpload:any = [];

    importCollaboratorForm: FormGroup;
    jobProfileList = [];
    lentTestList = [];
    countriesList = [];
    groupInfo = { visible: false, description: null };
    groupsList = [];
    tree_selected: FormControl;

    constructor(
        private jsonconector: ConectorJsonService,
        private generic: GenericCatalogsService,
        private job: ConnectorJobProfileService,
        private groupConnector: ConnectorGroupService,
        private form: FormBuilder,
        private dialog: MatDialog,
        private ngxCsvParser: NgxCsvParser) { }

    ngOnInit(): void {

      this.tree_selected = new FormControl();

      this.tree_selected.valueChanges.subscribe((e: any) => {
        this.saveGroup(e);
      });

      this.importCollaboratorForm = this.form.group({
        lengtest: (localStorage.getItem('module') == 'Honestidad') ? [null, Validators.required] : [0],
        country: [null, Validators.required],
        group: [0],
        jobProfile: (localStorage.getItem('module') == 'Honestidad') ? [null, Validators.required] : [0]
      });

      this.generic.ct_LengTest().subscribe((response: any)=> {
        const {ok, data, msg} = response;
        this.lentTestList = data;
      });

      this.generic.ct_Countries().subscribe((response: any)=> {
        const { ok, data, msg } = response;
        this.countriesList = data;
      });

      this.job.jobprofile_all().subscribe((response: any)=> {
        const { ok, data, msg } = response;
        this.jobProfileList = data;
      });

      this.groupConnector.group_tree().subscribe( (k: any) => {
        const { ok, data } = k;
        this.groupsList = data;
      });

    }

    submit(formdata) {

      const model_send = [];

      this.fileUpload.forEach( x => {

        let model = {
          country: formdata.country,
          group: (formdata.group == null) ? 0 : formdata.group,
          jobProfile: formdata.jobProfile,
          lengtest: 1,
          nickname: x[0],
          name: x[1],
          firstname: x[2],
          lastname: x[3],
          email: x[4]
        };

        if( !JSON.stringify(x).includes('/') ) {
          model_send.push(model);
        }

      });

      this.groupConnector.import_users(model_send).subscribe( (res:ResponseHandler) => {

        console.info('ImportRes', res);

        if(res.ok === true) {

          Swal.fire({
            title: 'Importación generada',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText : ' Aceptar '
          }).then((result) => {

            this.dialog.closeAll();



          });

        } else {

          Swal.fire('Error', 'Sucedió un error inesperado, verificar información para intentarlo nuevamente.', 'error');

        }

      });

    }

    saveGroup(action) {

      this.importCollaboratorForm.get('group').setValue(action.id);

      this.jsonconector.reporte_category_description(this.importCollaboratorForm.value).subscribe( (opts: ResponseHandler) => {
        console.info(opts.data);
        this.groupInfo = opts.data;
      });

    }

    download(event) {

      // // Note: Ie and Edge don't support the new File constructor,
      // // so it's better to construct blobs and use saveAs(blob, filename)
      // var file = new File(["Hello, world!"], "hello world.txt", {type: "text/plain;charset=utf-8"});
      // FileSaver.saveAs(file);

    }

    async onSelect(event) {

      // Agregar visualmente
      this.files.push(...event.addedFiles);

      // Leer archivo
      this.fileUpload = event.addedFiles[0];

      // Validacion de coma
      const isComma = await this.ngxCsvParser.parse( event.addedFiles[0], { header: false, delimiter: ',' }).pipe().toPromise();

      const delimiter = (isComma[0].length == 1) ? ';': ',';

      //Parsear a json
      this.ngxCsvParser.parse( event.addedFiles[0], { header: false, delimiter }).pipe().subscribe((result: Array<any>) => {

        console.info('Parsed', result);

        this.fileUpload = result;

      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });

    }

    onRemove(event) {
      console.log(event);
      this.files.splice(this.files.indexOf(event), 1);
    }

  }
