import { ConectorJsonService } from './../../../services/conector-json.service';
import { ExcelServiceService } from '../../../services/excel-service.service';
import { CoppelApiService } from '../../../services/coppel-api.service';
import { environment } from '../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import * as Highcharts from 'highcharts';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { PieModel } from '../../shared/ChartModels';

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

export const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    },
};

@Component({
    selector: 'app-reportapp',
    templateUrl: './reportapp.component.html',
    styleUrls: ['./reportapp.component.css'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
    ]
})
export class ReportappComponent implements OnInit {

    year = new Date().getFullYear();

    token: string = null;

    searchForm: FormGroup;

    filterFrm: FormGroup;

    makeHttp = false;

    coppel_token: any = null;

    res_data = [];

    paises: string[] = [];

    estados: any = [];

    municipios: any = [];

    regiones: any = [];

    categorias = [];

    subcategorias = [];

    empresas = [];

    denunciantes: any = [];

    consecuencias: any = [];

    conclusiones: any = [];

    denuncias_estados: any = [];

    clasificacion_priodidad: any = [];

    filtered: any = [];

    colors = [
        '#006cba',
        '#FFD204',
        '#00876c',
        '#d6ec91',
        '#ffff9d',
        '#fee17e',
        '#fcc267',
        '#f7a258',
        '#ef8250',
        '#e4604e',
        '#d43d51',
        '#ED561B',
        '#DDDF00',
        '#24CBE5',
        '#64E572',
        '#FF9655',
        '#FFF263',
        '#6AF9C4',
        '#665191',
        '#a05195',
        '#d45087',
        '#f95d6a',
        '#ff7c43',
        '#ffa600',
        '#2f4b7c',
    ];

    public chartStatus: any = PieModel;

    public chartDenunciaTipo: any = PieModel;

    public chartCategorias: any = PieModel;

    public chartConsecuencia: any = PieModel;

    public chartEmpresas: any = PieModel;

    ap: any[] = [];
    cc: any[] = [];
    cs: any[] = [];
    co: any[] = [];
    em: any[] = [];

    status_denuncia = [];
    filterFieldsPublic: FormGroup;

    constructor(
        public router: Router,
        private fb: FormBuilder,
        private coppel: CoppelApiService,
        private connector: ConectorJsonService,
        private excelService: ExcelServiceService) {

        this.searchForm = this.fb.group({
            fec_inicio: [null, Validators.required],
            fec_fin: [null, Validators.required],
            opc_proveedor: [null, Validators.required]
        });

        this.filterFrm = this.fb.group({
            folio: null,
            prioridad: null,
            pais: null,
            estado: null,
            municipio: null,
            regiones: null,
            categoria: null,
            subcategoria: null,
            region: null,
            centro: null,
            empresa: null,
            denunciante: null,
            num_colaborador: null,
            nom_denunciado: null,
            num_auditor: null,
            nom_auditor: null,
            fecha_asignacion: null,
            estatus: null,
            conclusion: null,
            fecha_cierre: null,
            consecuencia: null
        });

        this.filterFieldsPublic = this.fb.group({
            folio_grupocoppel: null,
            clasificacion_prioridad: null,
            nom_pais: null,
            nom_estado: null,
            nom_municipio: null,
            nom_region: null,
            categoria_denuncia: null,
            subcategoria_denuncia: null,
            nom_empresa: null,
            nombre_denunciante: null,
            num_auditor: null,
            nom_auditor: null,
            fecha_asignacion_denuncia: null,
            fecha_cierre_denuncia: null,
            estatus_denuncia: null,
            conclusion_denuncia: null,
            tipo_consecuencia_denuncia: null,
            involucrados: null,
            num_colaborador: null,
            nom_denunciado: null
        });

    }

    async ngOnInit() {

        this.token = localStorage.getItem('jwt_token');

        if (this.token == null) {
            await this.router.navigate(['/management']);
        }

        this.coppel.login().subscribe((e: any) => {
            this.coppel_token = e.data.token;
        });

        this.filterFrm.valueChanges.subscribe((e: any) => {
            return;
        });

    }

    filterdata_complex() {

        // recibe datos del form
        const e = this.filterFieldsPublic.value;

        if (e.fecha_cierre_denuncia != null) {
            e.fecha_cierre_denuncia = moment(e.fecha_cierre_denuncia).format("YYYY-MM-DD").toString()
        }

        if(e.fecha_asignacion_denuncia != null) {
            e.fecha_asignacion_denuncia = moment(e.fecha_asignacion_denuncia).format("YYYY-MM-DD").toString()
        }

        // elimina los nulos
        Object.keys(e).forEach(key => {
            if (e[key] == null || e[key].length <= 0) {
                delete e[key];
            }
        });

        // filtra sobre datos generales
        //this.filtered = this.res_data.filter(o => Object.keys(e).every(k => [].concat(e[k]).some(v => o[k.toLowerCase()].includes(v.toLowerCase()))));

        this.filtered = this.res_data.filter(obj => {
            let match = true;
            for (let prop in e) {
                if (obj[prop].toString().toLowerCase() !== e[prop].toString().toLowerCase() && !obj[prop].toString().toLowerCase().includes(e[prop].toString().toLowerCase())) {
                    match = false;
                    break;
                }
            }
            return match;
        });

        this.makeCharts();

        // this.filtered = this.res_data.filter(x =>
        //     x.folio_grupocoppel.toString().includes(e.folio) ||
        //     x.clasificacion_prioridad === e.prioridad ||
        //     x.nom_pais === e.pais ||
        //     x.nom_estado === e.estado ||
        //     x.nom_municipio === e.municipio ||
        //     x.nom_region === e.region ||
        //     x.categoria_denuncia === e.categoria ||
        //     x.subcategoria_denuncia === e.subcategoria ||
        //     x.nom_empresa === e.empresa ||
        //     x.nombre_denunciante === e.denunciante ||
        //     x.involucrados.filter(z => z.num_denunciado === e.num_colaborador || z.nom_denunciado === e.nom_denunciado).length > 0 ||
        //     x.num_auditor === e.num_auditor ||
        //     x.nom_auditor === e.nom_auditor ||
        //     x.fecha_asignacion_denuncia === e.fecha_asignacion_denuncia ||
        //     x.fecha_cierre_denuncia === e.fecha_cierre ||
        //     x.estatus_denuncia === e.estatus ||
        //     x.conclusion_denuncia === e.conclusion ||
        //     x.tipo_consecuencia_denuncia === e.consecuencia
        // );

        let limit = 0;

        // reset
        Object.keys(e).forEach((i) => {
            limit += (e[i] == null || !e[i].length) ? 0 : 1;
        });

        if (this.filtered.length === 0 && limit === 0) {
            this.filtered = this.res_data;
        }
    }

    getResults(formdata) {

        formdata.fec_inicio = moment(formdata.fec_inicio).format('YYYY-MM-DD');
        formdata.fec_fin = moment(formdata.fec_fin).format('YYYY-MM-DD');

        this.makeHttp = true;

        fetch(environment.coppel_api + '/obtenerReporte', {
            headers: { 'Content-Type': 'application/json', 'Authorization': this.coppel_token },
            method: 'POST',
            body: JSON.stringify(formdata)
        })
            .then(res => res.json())
            .then(async (res: any) => {

                if (res.data.estatus === 0) {

                    await Swal.fire('Error', res.data.mensaje, 'error');

                } else {

                    this.filterFieldsPublic.reset();
                    this.res_data = res.data;

                    this.makeRefill();

                }

            })
            .catch(err => {
                this.connector.info('Error message: ', err);
            })
            .finally(() => {
                this.makeHttp = false;
            });

    }

    download() {
        this.excelService.exportAsExcelFile(this.filtered, 'reporte');
    }

    filter_fields(array: any[], field: string) {
        return (array.map(i => i[field]).filter((v, i, s) => s.indexOf(v) === i)).filter(x => x != null && x != '' && x.length > 0);
    }

    count_fields(source: any[], filtering: any[], field) {

        const response = [];

        filtering.forEach((item: any) => {
            const src = source.filter(x => x[field] === item).length;
            response.push({ name: item, y: src });
        });

        return response;
    }

    downloadStatus() {
        this.exportCSVFile({ nombre: '', cantidad: '' }, this.cs, 'EstatusDenuncia');
    }

    downloadPaises() {
        this.exportCSVFile({ nombre: '', cantidad: '' }, this.ap, 'PaisesDenuncia');
    }

    downloadCategorias() {
        this.exportCSVFile({ nombre: '', cantidad: '' }, this.cc, 'CategoriasDenuncia');
    }

    downloadConsecuentias() {
        this.exportCSVFile({ nombre: '', cantidad: '' }, this.co, 'ConsecuenciasDenuncia');
    }

    downloadEmpresas() {
        this.exportCSVFile({ nombre: '', cantidad: '' }, this.em, 'EmpresasDenuncia');
    }

    convertToCSV(objArray) {
        const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (const index in array[i]) {
                if (line != '') {
                    line += ',';
                }

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    }

    exportCSVFile(headers, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }

        // Convert Object to JSON
        const jsonObject = JSON.stringify(items);

        const csv = this.convertToCSV(jsonObject);

        const exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    launchFilter() {

    }

    private makeCharts() {

        // Colores para las graficas
        Highcharts.setOptions({
            colors: [
                '#006cba',
                '#FFD204',
                '#00876c',
                '#d6ec91',
                '#ffff9d',
                '#fee17e',
                '#fcc267',
                '#f7a258',
                '#ef8250',
                '#e4604e',
                '#d43d51',
                '#ED561B',
                '#DDDF00',
                '#24CBE5',
                '#64E572',
                '#FF9655',
                '#FFF263',
                '#6AF9C4',
                '#665191',
                '#a05195',
                '#d45087',
                '#f95d6a',
                '#ff7c43',
                '#ffa600',
                '#2f4b7c',
            ]
        });

        // test 1
        this.cs = this.count_fields(this.filtered, this.denuncias_estados, 'estatus_denuncia');
        this.chartStatus.series[0].name = 'Estatus denuncia';
        this.chartStatus.series[0].data = this.cs;
        Highcharts.chart('status1', this.chartStatus);

        // test 2
        this.ap = this.count_fields(this.filtered, this.paises, 'nom_pais');
        this.chartDenunciaTipo.series[0].name = 'Paises';
        this.chartDenunciaTipo.series[0].data = this.ap;
        Highcharts.chart('status2', this.chartDenunciaTipo);

        // test 3
        this.cc = this.count_fields(this.filtered, this.categorias, 'categoria_denuncia');
        this.chartCategorias.series[0].name = 'Categorias';
        this.chartCategorias.series[0].data = this.cc;
        Highcharts.chart('status3', this.chartCategorias);

        // test 4
        this.co = this.count_fields(this.filtered, this.consecuencias, 'tipo_consecuencia_denuncia');
        this.chartConsecuencia.series[0].name = 'Consecuencias';
        this.chartConsecuencia.series[0].data = this.co;
        Highcharts.chart('status4', this.chartConsecuencia);

        // test 5
        this.em = this.count_fields(this.filtered, this.empresas, 'nom_empresa');
        this.chartEmpresas.series[0].name = 'Empresas';
        this.chartEmpresas.series[0].data = this.em;
        Highcharts.chart('status5', this.chartEmpresas);
    }

    private makeRefill() {

        // reset arrays
        this.paises = [];
        this.estados = [];
        this.municipios = [];
        this.regiones = [];
        this.categorias = [];
        this.subcategorias = [];
        this.empresas = [];
        this.denunciantes = [];
        this.clasificacion_priodidad = [];
        this.status_denuncia = [];

        // refill
        this.clasificacion_priodidad = this.filter_fields(this.res_data, 'clasificacion_prioridad');

        // paises
        this.paises = this.filter_fields(this.res_data, 'nom_pais');

        // estados
        this.estados = this.filter_fields(this.res_data, 'nom_estado');

        // municipios
        this.municipios = this.filter_fields(this.res_data, 'nom_municipio');

        // regiones
        this.regiones = this.filter_fields(this.res_data, 'nom_region');

        // categorias
        this.categorias = this.filter_fields(this.res_data, 'categoria_denuncia');

        // subcategoria
        this.subcategorias = this.filter_fields(this.res_data, 'subcategoria_denuncia');

        // empresa
        this.empresas = this.filter_fields(this.res_data, 'nom_empresa');

        // denunciantes
        this.denunciantes = this.filter_fields(this.res_data, 'tipo_denuncia');

        // estados
        this.denuncias_estados = this.filter_fields(this.res_data, 'estatus_denuncia');

        // consecuencias
        this.consecuencias = this.filter_fields(this.res_data, 'tipo_consecuencia_denuncia');

        // conclusiones
        this.conclusiones = this.filter_fields(this.res_data, 'conclusion_denuncia');

        // estatus
        this.status_denuncia = this.filter_fields(this.res_data, 'estatus_denuncia');

        // para filtrar
        this.filtered = this.res_data;

        // this.makeCharts();

    }
}
