import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-survey-create',
  templateUrl: './survey-create.component.html',
  styleUrls: ['./survey-create.component.css']
})
export class SurveyCreateComponent implements OnInit {

  @Output() savesurvey: EventEmitter<any> = new EventEmitter();

  surveyForm:FormGroup;

  constructor(private fb:FormBuilder) {
    this.surveyForm = this.fb.group({
      name:[null, Validators.required],
      instructions: [null, Validators.required],
      defaults: [false]
    });
  }

  ngOnInit() {

  }

  saveAs($e) {
    this.savesurvey.emit($e);
  }

}
