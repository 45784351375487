import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material';

/* Interface pre-definida de los valores unicos de cada nodo */
interface FoodNode {
  id: number;
  name: string;
  subs?: FoodNode[];
}

@Component({
  selector: 'app-tree-picker',
  templateUrl: './tree-picker.component.html',
  styleUrls: ['./tree-picker.component.css']
})

/* Componente para pintar en forma de arbol un arreglo de nodos de N niveles */

export class TreePickerComponent implements OnInit {

  /* Eventos que se enviaran del lado del receptor */
  @Output() onSelectGroup: EventEmitter<any> = new EventEmitter();

  /* Información que ingresa para mostrarse
   * @params
   * data: JSONArray<JsonArray< N > >; donde N es la profundidad indefinida.
   * key: String; es la clave para identificar el siguiente nodo del arbol
  */

  @Input() data = new MatTreeNestedDataSource<FoodNode>();
  @Input() key: string;
  @Input() collapsed:boolean = false;
  @Input() hasIcon:boolean = false;

  /* Requerido por material para dar forma al arbol, mediante una interface previamente definida */
  treeControl = new NestedTreeControl<FoodNode>(node => node.subs);

  hasChild;

  constructor() {}
  ngOnInit() {}

  /* Lanzador de actividad */
  handlerWasClicked(row): void {
    this.onSelectGroup.emit(row);
  }

}
