import { CoppelApiService } from './../../../services/coppel-api.service';
import Swal from 'sweetalert2';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthenticationService } from '../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {


  makeHttp = false;

  mobileQuery: MediaQueryList;
  dir = 'ltr';

  green: boolean;
  blue: boolean;

  dark: boolean;
  minisidebar: boolean;

  boxed: boolean;
  danger: boolean;

  showHide: boolean;
  url: string;

  relation: string;
  group: string;

  sidebarOpened;
  public showSearch = false;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  moduleName: any = [];
  loginForm: FormGroup;

  //publicFolder = environment.private;

  statuses = [
    'new',
    'acknowledged',
    'inprogress',
    'standby',
    'aborted',
    'solved'
  ];

  extras = {
    "pais": "",
    "municipio": "",
    "estado": "",
    "empresa": "",
    "region": "",
    "categoria": null,
    "subcategoria": null
  }

  ticket = null;
  activity = [];
  history = [];
  questions = [];
  comments: FormGroup;
  attachments = [];
  appToken = localStorage.getItem('token');

  constructor(
    private coppel: CoppelApiService,
    private conector: ConectorJsonService,
    private fb: FormBuilder,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    private service: AuthenticationService,
    private translate: TranslateService,
    private router: Router
  ) {

    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.comments = this.fb.group({
      comments: [null, Validators.required]
    });

    this.service.setLanguage().then(k => this.translate.setDefaultLang(k));

  }

  ngOnInit() {

    this.coppel.login().subscribe((res: any) => {
      this.appToken = res.data.token;
    })

    this.reloadMyData();

  }

  reloadMyData() {

    this.conector.info('INFORMATION', localStorage.getItem('token'));

    this.conector.ticket_information().subscribe((k: any) => {

      const { ok, data, msg } = k;

      if (ok === true) {

        const { ticket, activity, questions, attachments, relation, group }: any = data;

        this.group = group;
        this.ticket = ticket;

        this.relation = relation;
        this.activity = activity;

        this.questions = questions;
        this.attachments = attachments;

        if (this.ticket.extras != null) {
          this.extras = JSON.parse(this.ticket.extras);
        }

      }

    });
  }

  log_out() {
    localStorage.clear();
    localStorage.removeItem('token');
    this.router.navigateByUrl('/');
  }

  publicMessage(e) {
    e.kind = 'public';
    this.conector.publish_message(e).subscribe((k: any) => {
      this.reloadMyData();
      this.comments.reset();
    });
  }

  allFilesToUpload: any[] = [];

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async fileUpload(event) {

    this.allFilesToUpload = [];

    const formData = new FormData();

    const { addedFiles, rejectedFiles, source } = event;

    formData.append('file', addedFiles[0]);

    const file = addedFiles[0];

    this.conector.ticket_upload(formData).subscribe(async (response: any) => {

      const { ok, msg, data } = response;

      if (ok === true) {

        this.attachments.push(data);

        let decoded: any = await this.toBase64(file);

        decoded = decoded.split(',')[1];

        this.allFilesToUpload.push({
          "base64": decoded,
          "file": addedFiles[0].name
        });

        this.coppel.send_report_file({ "folioAmitai": this.ticket.ticketID, anexos: this.allFilesToUpload }, this.appToken).then(e => this.conector.info('UPC', e));

        Swal.fire({
          title: 'Éxito',
          text: 'Tu documento fue cargado correctamente',
          icon: 'success'
        });
      } else {
        Swal.fire({
          title: 'Error', text: msg, icon: 'error', customClass: {
            confirmButton: 'round-me',
            cancelButton: 'round-me'
          },
        });
      }

    }, (err) => {
      this.conector.info(err);
    });

  }

  getPrivateFile(file) {

    this.conector.info('req', file);

    this.conector.private_file(file).subscribe((data: any) => {

      Swal.fire({
        title: 'Exito', text: 'Archivo descargado', icon: 'success', customClass: {
          confirmButton: 'round-me',
          cancelButton: 'round-me'
        }
      });

      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(data);
      a.href = objectUrl;
      a.download = file;
      a.click();
      URL.revokeObjectURL(objectUrl);

    });

  }

}
