import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material';
import { SharedCommunicationService } from '../../../services/shared-communication.service';
import { Component, OnInit, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { TycosComponent } from '../modals/tycos/tycos.component';
import * as moment from 'moment';

declare var $;
declare var Cloud9Carousel;

@Component({
  selector: 'app-home-manager',
  templateUrl: './home-manager.component.html',
  styleUrls: ['./home-manager.component.css']
})
export class HomeManagerComponent implements OnInit {


  

  moduleName: any = [];

  menu = [
    {
      node: 'Integriline',
      image : 'integriline.png',
      enabled: true,
      color: '#3b2050',
      submenus: [
        { name: 'Relaciones', route: 'relations'},
        { name: 'Banco de preguntas', route: 'questions' },
        { name: 'Configurar Grupos', route: 'group-addons'}
      ]
    },
    {
      node: 'Lucius Report',
      image : 'lucius.png',
      enabled: false,
      color: '#950209',
      submenus: []
    },
    {
      node: 'Honestidad',
      image : 'honestidad.png',
      enabled: true,
      color: '#00274c',
      submenus: []
    },
    {
      node: 'E-thics survey',
      image : 'culture.png',
      enabled: true,
      color: '#507600',
      submenus: []
    },
    {
      node: 'E-thics learning',
      image : 'learning.png',
      enabled: true,
      color: '#ff9e00',
      submenus: []
    }
  ];


  constructor(
    private route: Router,
    public translate: TranslateService,
    private socket: SharedCommunicationService,
    public dialog: MatDialog) {

    this.moduleName = this.menu;

  }

  options: Object = {
    clicking: true,
    sourceProp: 'src',
    visible: 4,
    perspective: 1,
    startSlide: 0,
    border: 3,
    dir: 'ltr',
    width: 360,
    height: 270,
    space: 220,
    autoRotationSpeed: 5000,
    loop: true
};

  async ngOnInit() {

    this.socket.isVisibleSource.subscribe((data: any) => {

      if (data === null || data === false ) {

        this.moduleName = this.menu;

      } else {


      }

      this.launchPlayer();

    });

  }

  launchPlayer() {

    $('#cloud9').hide();

    setTimeout( function() {

      $('#cloud9').fadeIn().Cloud9Carousel( {
        autoPlay: 1,
        bringToFront: true,
        buttonLeft: $('#buttons > .left'),
        buttonRight: $('#buttons > .right'),
      });

    }, 10);

  }


  openModal(row): void {

    // Guarda menu localStorage
    localStorage.setItem('menuItems', JSON.stringify(this.menu) );

    const dialogRef = this.dialog.open( TycosComponent, {});

    dialogRef.afterClosed().subscribe( async (res: any) => {

      if (res.data === true) {

        this.socket.enableNav.next(true);

        this.socket.switchModule.next(row);

        if ( row.submenus.length > 0) {

          console.info('menu', row.node);

          this.route.navigateByUrl( `/manager/${row.submenus[0].route}`);

        }

      } else {
        Swal.fire('wops!', 'Must be accept the termns and conditions', 'error');
      }

    });

  }

}
