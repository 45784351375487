import { FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { ConnectorCollaboratorService } from '../../../services/Collaborator/conector-collaborator.service';
import { GenericCatalogsService } from '../../../services/GenericCatalogs.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject, SimpleChanges } from '@angular/core';
import { ConnectorJobProfileService } from '../../../services/job-profile.service';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { ResponseHandler } from '../../../interfaces/ResponseHandler';
import { ConnectorGroupService } from '../../../services/Admin/conector-group.service';
import { ConectorModuleService } from '../../../services/conector-module.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-create-collaborator',
  templateUrl: './create-collaborator.component.html',
  styleUrls: ['./create-collaborator.component.css']
})
export class CreateCollaboratorComponent implements OnInit {

  id = 0;
  evaluations = [];
  displayedColumns: string[] = ['selected', 'name'];
  collaboratorForm: FormGroup;
  lentTestList = [];
  countriesList = [];
  nationalitiesList = [];
  genreList = [];
  groupsList = [];
  jobProfileList = [];
  groupInfo = { visible: false, description: null };
  groups: any = [];

  public AllowParentSelection = true;
  public RestructureWhenChildSameName = false;
  public ShowFilter = true;
  public Disabled = false;
  public FilterPlaceholder = 'Type here to filter elements...';
  public MaxDisplayed = 5;

  treeWords:FormControl;

  constructor(
    public router: Router,
    private jsonconector: ConectorJsonService,
    private groupConnector: ConnectorGroupService,
    private conector: ConnectorCollaboratorService,
    private conectorModule: ConectorModuleService,
    private form: FormBuilder,
    private generic: GenericCatalogsService,
    private job: ConnectorJobProfileService,
    private dialog: MatDialog,
    private snap: ActivatedRoute) {

    this.collaboratorForm = this.form.group({
      id: [0],
      username: [null, Validators.required],
      name: [null, Validators.required],
      firstname: [null, Validators.required],
      lastname: [null],
      email: [null, [Validators.email]],
      phone: [null, [Validators.pattern("[0-9]{10}$")]],
      birthday: [null],
      lengtest: (localStorage.getItem('module') == 'Honestidad') ? [null, Validators.required] : [0],
      country: [0, Validators.required],
      nationality: [0],
      genre: [0, Validators.required],
      group: [0],
      jobProfile: (localStorage.getItem('module') == 'Honestidad') ? [null, Validators.required] : [0]
    });
  }

  async ngOnInit() {

    this.treeWords = new FormControl();

    this.treeWords.valueChanges.subscribe( (e:any) => {
      this.saveGroup(e);
    });

    this.snap.paramMap.subscribe(async (p: Params) => {
      this.id = p.params.id;
    });

    if (this.id) {

      this.conector.collaborator_getbyid(this.id).subscribe((response: any) => {

        const { ok, data, msg } = response;

        console.log(data);

        this.collaboratorForm.get("id").setValue(data.id);
        this.collaboratorForm.get("username").setValue(data.nickname);
        this.collaboratorForm.get("name").setValue(data.name);
        this.collaboratorForm.get("firstname").setValue(data.firstname);
        this.collaboratorForm.get("lastname").setValue(data.lastname);
        this.collaboratorForm.get("email").setValue(data.email);
        this.collaboratorForm.get("phone").setValue(data.phone);
        this.collaboratorForm.get("birthday").setValue(data.birthday);
        this.collaboratorForm.get("lengtest").setValue(data.lengtest);
        this.collaboratorForm.get("country").setValue(data.country);
        this.collaboratorForm.get("nationality").setValue(data.nationality);
        this.collaboratorForm.get("genre").setValue(data.genre);
        this.collaboratorForm.get("group").setValue(data.group);
        this.collaboratorForm.get("jobProfile").setValue(data.jobProfile);

      })
    }

    this.generic.ct_LengTest().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.lentTestList = data;
    });

    this.generic.ct_Countries().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.countriesList = data;
    });

    this.generic.ct_Nationalities().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.nationalitiesList = data;
    });

    this.generic.ct_Genres().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.genreList = data;
    });

    this.generic.ct_Groups().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.groupsList = data;
    });

    this.job.jobprofile_all().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.jobProfileList = data;
    });

    this.groupConnector.group_tree().subscribe((k: any) => {
      const { ok, data } = k;
      this.groups = data;
    });

    this.conectorModule.submodule_get(1, this.id).subscribe((response: any) => {
      const { data, msg } = response;
      this.evaluations = data.filter(f => f.id != 2);
    });

  }

  NumSeleccionados(row?: Submodule, evento?: any) {

    row.selected = evento.checked;
  }

  submit(formdata) {

    if (formdata.lengtest == null) formdata.lengtest = 0;

    if (this.id > 0) {
      this.conector.collaborator_put(formdata).subscribe((d: any) => {
        const { ok, msg, data } = d;
        if (ok == true) {
          Swal.fire({
            title: 'Colaborador actualizado.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: ' Aceptar '
          }).then((result) => this.router.navigateByUrl("/manager/collaborators"));
        } else {
          Swal.fire('Error', msg, 'error');
        }
      });
    }
    else {
      formdata["id"] = 0;
      this.conector.collaborator_post(formdata).subscribe((d: any) => {
        const { ok, msg, data } = d;
        if (ok == true) {
          Swal.fire({
            title: 'Colaborador registrado.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: ' Aceptar '
          }).then((result) => this.router.navigateByUrl("/manager/collaborators"));
        } else {
          Swal.fire('Error', msg, 'error');
        }
      });
    }

  }

  saveGroup(action) {
    this.collaboratorForm.get('group').setValue(action.id);
    this.jsonconector.reporte_category_description(this.collaboratorForm.value).subscribe((opts: ResponseHandler) => {
      console.info(opts.data);
      this.groupInfo = opts.data;
    });
  }

}

export interface Submodule {
  id: number;
  name: string;
  selected: boolean;
}