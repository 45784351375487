export let barModel = {
    chart: {
        type: 'bar'
    },
    title: {
        text: 'Total'
    },
    xAxis: {
        categories: [],
        title: {
            text: null
        }
    },
    yAxis:{
        tickInterval: 1
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true
            }
        }
    },
    credits: {
        enabled: false
    },
    series: [{
        name: '',
        data: [1, 2, 3]
    }]
};