import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { Chart } from 'angular-highcharts';

export interface CompetenceElement {
    seleccionado: boolean;
    IdCompetencia: number;
    Competencia: string;
    Descriptor: string;
    Mapa: number;
    L: number;
    R: number;
    P: number;
    A: number;
    Me: number;
    IdCategoria: number;
    Categoria: string;
  }

const ELEMENT_DATA: CompetenceElement[] = [
    {seleccionado: false, IdCompetencia: 1, Competencia: 'Autocontrol', Descriptor: 'Mantener habitualmente control sobre sus actos.', Mapa: 64918, L: 6, R: 4, P: 9, A: 1, Me: 8, IdCategoria: 12, Categoria: 'INTELIGENCIA EMOCIONAL  '},
{seleccionado: false, IdCompetencia: 2, Competencia: 'Liderazgo', Descriptor: 'Motivar a los subordinados a que trabajen de forma entusiasta para lograr un objetivo común', Mapa: 97319, L: 9, R: 7, P: 3, A: 1, Me: 9, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 3, Competencia: 'Cooperación efectiva', Descriptor: 'Colaborar con otros, con buenos resultados', Mapa: 16945, L: 1, R: 6, P: 9, A: 4, Me: 5, IdCategoria: 15, Categoria: 'TRABAJO EN EQUIPO  '},
{seleccionado: false, IdCompetencia: 4, Competencia: 'Uso positivo de la energía', Descriptor: 'Demostrar alta energía y empuje en forma permanente.', Mapa: 95245, L: 9, R: 5, P: 2, A: 4, Me: 5, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 5, Competencia: 'Dirección', Descriptor: 'Ofrecer directrices enérgicas y precisas a los colaboradores.', Mapa: 94437, L: 9, R: 4, P: 4, A: 3, Me: 7, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 6, Competencia: 'Originalidad', Descriptor: 'Proponer modelos e ideas muy originales', Mapa: 92369, L: 9, R: 2, P: 3, A: 6, Me: 9, IdCategoria: 5, Categoria: 'CAPACIDAD CREATIVA  '},
{seleccionado: false, IdCompetencia: 7, Competencia: 'Inspección correctiva', Descriptor: 'Controlar un espacio determinado, vigilando y corrigiendo desviaciones', Mapa: 14967, L: 1, R: 4, P: 9, A: 6, Me: 7, IdCategoria: 3, Categoria: 'ATENCIÓN AL DETALLE  '},
{seleccionado: false, IdCompetencia: 8, Competencia: 'Memoria concreta', Descriptor: 'Acumula y tiene presentes muchos hechos útiles para los demás', Mapa: 87419, L: 8, R: 7, P: 4, A: 1, Me: 9, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 9, Competencia: 'Planeación', Descriptor: 'Programar por anticipado cursos de acción.', Mapa: 84717, L: 8, R: 4, P: 7, A: 1, Me: 7, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 10, Competencia: 'Seguimiento de esquemas', Descriptor: 'Partir de fórmulas y modelos estrictamente obligatorios', Mapa: 32694, L: 3, R: 2, P: 6, A: 9, Me: 4, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 11, Competencia: 'Apertura en las relaciones', Descriptor: 'Ser franco y flexible en su trato', Mapa: 49256, L: 4, R: 9, P: 2, A: 5, Me: 6, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 12, Competencia: 'Ventas a alto nivel', Descriptor: 'Vender a altos niveles jerárquicos', Mapa: 96327, L: 9, R: 6, P: 3, A: 2, Me: 7, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 13, Competencia: 'Vigilancia', Descriptor: 'Estar siempre muy alerta para captar todo lo que ocurre alrededor', Mapa: 71669, L: 7, R: 1, P: 6, A: 6, Me: 9, IdCategoria: 3, Categoria: 'ATENCIÓN AL DETALLE  '},
{seleccionado: false, IdCompetencia: 14, Competencia: 'Verificación', Descriptor: 'Revisar reiteradamente el propio trabajo', Mapa: 14781, L: 1, R: 4, P: 7, A: 8, Me: 1, IdCategoria: 3, Categoria: 'ATENCIÓN AL DETALLE  '},
{seleccionado: false, IdCompetencia: 15, Competencia: 'Capacidad de decisión', Descriptor: 'Decidir con efectividad sin quedarse o perderse en el análisis.', Mapa: 97221, L: 9, R: 7, P: 2, A: 2, Me: 1, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 16, Competencia: 'Capacidad de trabajar solo', Descriptor: 'Trabajar sin necesidad de compañía', Mapa: 81292, L: 8, R: 1, P: 2, A: 9, Me: 2, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 17, Competencia: 'Retroalimentación positiva', Descriptor: 'Retroalimentar a los subordinados sobre fallas y aciertos en el trabajo.', Mapa: 94526, L: 9, R: 4, P: 5, A: 2, Me: 6, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 18, Competencia: 'Solución de problemas de personal', Descriptor: 'Solucionar crisis del personal atendiendo reclamos específicos.', Mapa: 86518, L: 8, R: 6, P: 5, A: 1, Me: 8, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 19, Competencia: 'Congruencia', Descriptor: 'Ser coherente en los hechos con las conclusiones a las que se llegó por medios lógicos.', Mapa: 95518, L: 9, R: 5, P: 5, A: 1, Me: 8, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 20, Competencia: 'Escucha efectiva', Descriptor: 'Saber escuchar opiniones, dudas y sugerencias y tomarlas en cuenta', Mapa: 46918, L: 4, R: 6, P: 9, A: 1, Me: 8, IdCategoria: 8, Categoria: 'COMUNICACIÓN  '},
{seleccionado: false, IdCompetencia: 21, Competencia: 'Perfección en el trabajo', Descriptor: 'Asegurar que todos los detalles sean correctos', Mapa: 13793, L: 1, R: 3, P: 7, A: 9, Me: 3, IdCategoria: 3, Categoria: 'ATENCIÓN AL DETALLE  '},
{seleccionado: false, IdCompetencia: 22, Competencia: 'Empuje', Descriptor: 'Manifestar esfuerzo incansable, manteniendo alta calidad en su trabajo', Mapa: 61947, L: 6, R: 1, P: 9, A: 4, Me: 7, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 23, Competencia: 'Enfoque', Descriptor: 'Lograr mantener la atención en ciertos detalles, por largos periodos de tiempo', Mapa: 31884, L: 3, R: 1, P: 8, A: 8, Me: 4, IdCategoria: 3, Categoria: 'ATENCIÓN AL DETALLE  '},
{seleccionado: false, IdCompetencia: 24, Competencia: 'Cumplimiento de órdenes', Descriptor: 'Seguir todas las órdenes con precisión', Mapa: 12983, L: 1, R: 2, P: 9, A: 8, Me: 3, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 25, Competencia: 'Exploración del mercado', Descriptor: 'Penetrar en nuevos territorios de ventas.', Mapa: 96325, L: 9, R: 6, P: 3, A: 2, Me: 5, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 26, Competencia: 'Venta entusiasta', Descriptor: 'Ofrecer servicios mostrando entusiasmo.', Mapa: 39625, L: 3, R: 9, P: 6, A: 2, Me: 5, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 27, Competencia: 'Relaciones de alto nivel', Descriptor: 'Cuidar y fortalecer las relaciones con personas influyentes.', Mapa: 98218, L: 9, R: 8, P: 2, A: 1, Me: 8, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 28, Competencia: 'Innovación', Descriptor: 'Buscar continuamente mejores formas de realizar el trabajo', Mapa: 57717, L: 5, R: 7, P: 7, A: 1, Me: 7, IdCategoria: 5, Categoria: 'CAPACIDAD CREATIVA  '},
{seleccionado: false, IdCompetencia: 29, Competencia: 'Verificación minuciosa', Descriptor: 'Verificar hasta el más mínimo detalle', Mapa: 13793, L: 1, R: 3, P: 7, A: 9, Me: 3, IdCategoria: 3, Categoria: 'ATENCIÓN AL DETALLE  '},
{seleccionado: false, IdCompetencia: 30, Competencia: 'Simpatía', Descriptor: 'Ser agradable a los demás.', Mapa: 64916, L: 6, R: 4, P: 9, A: 1, Me: 6, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 31, Competencia: 'Síntesis', Descriptor: 'Exponer, de forma oral o escrita, las ideas principales de cierto tema.', Mapa: 95156, L: 9, R: 5, P: 1, A: 5, Me: 6, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 32, Competencia: 'Sociabilidad', Descriptor: 'Presumir del gran número de personas con las que trata', Mapa: 59245, L: 5, R: 9, P: 2, A: 4, Me: 5, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 33, Competencia: 'Aprovechamiento de oportunidades', Descriptor: 'Lograr vender al primer intento, sin preparación previa.', Mapa: 95426, L: 9, R: 5, P: 4, A: 2, Me: 6, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 34, Competencia: 'Espíritu de lucha', Descriptor: 'Luchar a brazo partido para arrebatar clientes a la competencia.', Mapa: 95335, L: 9, R: 5, P: 3, A: 3, Me: 5, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 35, Competencia: 'Cumplimiento', Descriptor: 'Cumplir en la práctica lo prometido.', Mapa: 95427, L: 9, R: 5, P: 4, A: 2, Me: 7, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 36, Competencia: 'Proselitismo', Descriptor: 'Ser cautivante, de manera que los demás tomen como suyas sus propuestas', Mapa: 29454, L: 2, R: 9, P: 4, A: 5, Me: 4, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 37, Competencia: 'Cuidado de la imagen', Descriptor: 'Proyectar ante la clientela una imagen pulcra y educada.', Mapa: 47816, L: 4, R: 7, P: 8, A: 1, Me: 6, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 38, Competencia: 'Detección de información', Descriptor: 'Encontrar información revisando documentos diversos', Mapa: 16581, L: 1, R: 6, P: 5, A: 8, Me: 1, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 39, Competencia: 'Popularidad', Descriptor: 'Ser conocido por muchos.', Mapa: 79226, L: 7, R: 9, P: 2, A: 2, Me: 6, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 40, Competencia: 'Respetabilidad', Descriptor: 'Inspirar respeto ante cualquier circunstancia o persona.', Mapa: 58616, L: 5, R: 8, P: 6, A: 1, Me: 6, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 41, Competencia: 'Resistencia', Descriptor: 'Mostrarse siempre incansable', Mapa: 95155, L: 9, R: 5, P: 1, A: 5, Me: 5, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 42, Competencia: 'Tranquilidad', Descriptor: 'Moverse muy poco de su lugar de trabajo', Mapa: 13791, L: 1, R: 3, P: 7, A: 9, Me: 1, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 43, Competencia: 'Versatilidad', Descriptor: 'Estar dispuesto a afrontar actividades muy variadas', Mapa: 95519, L: 9, R: 5, P: 5, A: 1, Me: 9, IdCategoria: 1, Categoria: 'ADAPTABILIDAD / FLEXIBILIDAD  '},
{seleccionado: false, IdCompetencia: 44, Competencia: 'Estímulo al pensamiento', Descriptor: 'Estimular a los subordinados para que analicen y generen ideas.', Mapa: 96418, L: 9, R: 6, P: 4, A: 1, Me: 8, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 45, Competencia: 'Metodología', Descriptor: 'Seguir con exactitud procedimientos para surtir órdenes de venta.', Mapa: 57714, L: 5, R: 7, P: 7, A: 1, Me: 4, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 46, Competencia: 'Consistencia lógica', Descriptor: 'Razonar lógicamente y mantenerse consistente en sus conclusiones', Mapa: 95518, L: 9, R: 5, P: 5, A: 1, Me: 8, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 47, Competencia: 'Optimismo', Descriptor: 'Mostrar siempre actitud mental positiva.', Mapa: 76618, L: 7, R: 6, P: 6, A: 1, Me: 8, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 48, Competencia: 'Actualización de datos', Descriptor: 'Actualizar permanentemente listas de conjuntos de datos o artículos', Mapa: 33954, L: 3, R: 3, P: 9, A: 5, Me: 4, IdCategoria: 4, Categoria: 'AUXILIARES / ADMINISTRATIVAS  '},
{seleccionado: false, IdCompetencia: 49, Competencia: 'Efectividad social', Descriptor: 'Aumentar ventas acudiendo a las relaciones.', Mapa: 69416, L: 6, R: 9, P: 4, A: 1, Me: 6, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 50, Competencia: 'Entrega al trabajo', Descriptor: 'Aceptar y realizar actividades adicionales a las estipuladas.', Mapa: 95429, L: 9, R: 5, P: 4, A: 2, Me: 9, IdCategoria: 1, Categoria: 'ADAPTABILIDAD / FLEXIBILIDAD  '},
{seleccionado: false, IdCompetencia: 51, Competencia: 'Consultoría', Descriptor: 'Ejercer como consultor o asesor de uno o varios clientes.', Mapa: 58615, L: 5, R: 8, P: 6, A: 1, Me: 5, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 52, Competencia: 'Anticipación', Descriptor: 'Prever circunstancias que permitan adelantar el trabajo.', Mapa: 94529, L: 9, R: 4, P: 5, A: 2, Me: 9, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 53, Competencia: 'Compromiso', Descriptor: 'Mostrar entrega plena ante cualquier situación de trabajo', Mapa: 64916, L: 6, R: 4, P: 9, A: 1, Me: 6, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 54, Competencia: 'Análisis lógico', Descriptor: 'Aplicar en su trabajo la lógica y el análisis', Mapa: 92369, L: 9, R: 2, P: 3, A: 6, Me: 9, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 55, Competencia: 'Orientar a otros', Descriptor: 'Ofrecer guías claras y determinantes', Mapa: 95246, L: 9, R: 5, P: 2, A: 4, Me: 6, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 56, Competencia: 'Actualización profesional', Descriptor: 'Servirse de las tecnologías de vanguardia para enriquecer sus presentaciones.', Mapa: 98216, L: 9, R: 8, P: 2, A: 1, Me: 6, IdCategoria: 1, Categoria: 'ADAPTABILIDAD / FLEXIBILIDAD  '},
{seleccionado: false, IdCompetencia: 57, Competencia: 'Sentido del humor', Descriptor: 'Romper la tensión mediante el sentido del humor', Mapa: 49435, L: 4, R: 9, P: 4, A: 3, Me: 5, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 58, Competencia: 'Comunicación directa', Descriptor: 'Ser más efectivo en la comunicación cara a cara que por otro medio.', Mapa: 59514, L: 5, R: 9, P: 5, A: 1, Me: 4, IdCategoria: 8, Categoria: 'COMUNICACIÓN  '},
{seleccionado: false, IdCompetencia: 59, Competencia: 'Amabilidad en el trato con clientes', Descriptor: 'Proporcionar servicios y realizar ventas en forma cálida y amigable.', Mapa: 34946, L: 3, R: 4, P: 9, A: 4, Me: 6, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 60, Competencia: 'Análisis de información', Descriptor: 'Analizar e integrar datos originados en situaciones distintas', Mapa: 91829, L: 9, R: 1, P: 8, A: 2, Me: 9, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 61, Competencia: 'Lealtad a políticas', Descriptor: 'Seguir políticas establecidas', Mapa: 15591, L: 1, R: 5, P: 5, A: 9, Me: 1, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 62, Competencia: 'Relaciones humanas', Descriptor: 'Ser hábil para multiplicar las relaciones personales día a día.', Mapa: 79226, L: 7, R: 9, P: 2, A: 2, Me: 6, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 63, Competencia: 'Transmitir seguridad', Descriptor: 'Inspirar confianza en el trato con los demás debido a la seguridad en sí mismo que proyecta', Mapa: 87418, L: 8, R: 7, P: 4, A: 1, Me: 8, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 64, Competencia: 'Relaciones pacíficas', Descriptor: 'En las relaciones con los demás, conceder prioridad a la paz', Mapa: 15593, L: 1, R: 5, P: 5, A: 9, Me: 3, IdCategoria: 12, Categoria: 'INTELIGENCIA EMOCIONAL  '},
{seleccionado: false, IdCompetencia: 65, Competencia: 'Motivar a otros', Descriptor: 'Transmitir todo el entusiasmo requerido para la plena efectividad del grupo.', Mapa: 86517, L: 8, R: 6, P: 5, A: 1, Me: 7, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 66, Competencia: 'Apertura al cambio', Descriptor: 'Capacidad de aceptar y adaptarse a las situaciones cambiantes con entusiasmo', Mapa: 94166, L: 9, R: 4, P: 1, A: 6, Me: 6, IdCategoria: 1, Categoria: 'ADAPTABILIDAD / FLEXIBILIDAD  '},
{seleccionado: false, IdCompetencia: 67, Competencia: 'Buen criterio', Descriptor: 'Mostrar siempre criterios sencillos y positivos', Mapa: 66719, L: 6, R: 6, P: 7, A: 1, Me: 9, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 68, Competencia: 'Comunicación verbal', Descriptor: 'Demostrar fácil comunicación verbal', Mapa: 48718, L: 4, R: 8, P: 7, A: 1, Me: 8, IdCategoria: 8, Categoria: 'COMUNICACIÓN  '},
{seleccionado: false, IdCompetencia: 69, Competencia: 'Atención a lo general', Descriptor: 'Trabajar sin preocuparse por los detalles', Mapa: 68155, L: 6, R: 8, P: 1, A: 5, Me: 5, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 70, Competencia: 'Influencia', Descriptor: 'Manejar tan bien las relaciones con los demás, que pueda influir positivamente en su autoestima.', Mapa: 97136, L: 9, R: 7, P: 1, A: 3, Me: 6, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 71, Competencia: 'Servicio', Descriptor: 'Dar servicio a clientes internos o externos', Mapa: 13972, L: 1, R: 3, P: 9, A: 7, Me: 2, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 72, Competencia: 'Implementación', Descriptor: 'Poner todo lo necesario para que las acciones avancen ágilmente.', Mapa: 87416, L: 8, R: 7, P: 4, A: 1, Me: 6, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 73, Competencia: 'Habilidad mecánica', Descriptor: 'Armar objetos o máquinas', Mapa: 53393, L: 5, R: 3, P: 3, A: 9, Me: 3, IdCategoria: 11, Categoria: 'HABILIDADES MANUALES / OPERATIVAS  '},
{seleccionado: false, IdCompetencia: 74, Competencia: 'Exigencia de precisión', Descriptor: 'Exigir mucha precisión al tratar con los demás', Mapa: 94528, L: 9, R: 4, P: 5, A: 2, Me: 8, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 75, Competencia: 'Adecuación de criterio', Descriptor: 'Seguimiento de manuales', Mapa: 15681, L: 1, R: 5, P: 6, A: 8, Me: 1, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 76, Competencia: 'Minuciosidad', Descriptor: 'Ser extremadamente detallista en su trabajo', Mapa: 25493, L: 2, R: 5, P: 4, A: 9, Me: 3, IdCategoria: 3, Categoria: 'ATENCIÓN AL DETALLE  '},
{seleccionado: false, IdCompetencia: 77, Competencia: 'Moldeabilidad', Descriptor: 'Sentirse cómodo bajo supervisión al realizar su trabajo', Mapa: 15954, L: 1, R: 5, P: 9, A: 5, Me: 4, IdCategoria: 1, Categoria: 'ADAPTABILIDAD / FLEXIBILIDAD  '},
{seleccionado: false, IdCompetencia: 78, Competencia: 'Proporción de ayuda', Descriptor: 'Ofrecer ayuda efectiva a los que le rodean', Mapa: 16945, L: 1, R: 6, P: 9, A: 4, Me: 5, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 79, Competencia: 'Apego a procedimientos', Descriptor: 'Trabajar a partir de esquemas e instrucciones invariables', Mapa: 42594, L: 4, R: 2, P: 5, A: 9, Me: 4, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 80, Competencia: 'Pensamiento crítico', Descriptor: 'Aplicar juicio crítico en situaciones específicas', Mapa: 73195, L: 7, R: 3, P: 1, A: 9, Me: 5, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 81, Competencia: 'Colaboración', Descriptor: 'Auxiliar a los demás a sacar su trabajo, siempre que le sea posible', Mapa: 15955, L: 1, R: 5, P: 9, A: 5, Me: 5, IdCategoria: 15, Categoria: 'TRABAJO EN EQUIPO  '},
{seleccionado: false, IdCompetencia: 82, Competencia: 'Asertividad', Descriptor: 'Capacidad de expresar opiniones, necesidades y sentimientos propios, sin herir a los demás.', Mapa: 97318, L: 9, R: 7, P: 3, A: 1, Me: 8, IdCategoria: 8, Categoria: 'COMUNICACIÓN  '},
{seleccionado: false, IdCompetencia: 83, Competencia: 'Prudencia', Descriptor: 'Actuar apegándose a lo conocido y probado', Mapa: 15771, L: 1, R: 5, P: 7, A: 7, Me: 1, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 84, Competencia: 'Comunicación a distancia', Descriptor: 'Ser más efectivo en su comunicación por otro medio que cara a cara', Mapa: 51595, L: 5, R: 1, P: 5, A: 9, Me: 5, IdCategoria: 1, Categoria: 'ADAPTABILIDAD / FLEXIBILIDAD  '},
{seleccionado: false, IdCompetencia: 85, Competencia: 'Adaptación a rutinas', Descriptor: 'Realizar actividades repetitivas', Mapa: 12891, L: 1, R: 2, P: 8, A: 9, Me: 1, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 86, Competencia: 'Tenacidad para cerrar ventas', Descriptor: 'Insistir una y otra vez, en forma tenaz, para cerrar ventas.', Mapa: 92546, L: 9, R: 2, P: 5, A: 4, Me: 6, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 87, Competencia: 'Cautela', Descriptor: 'Mostrar cautela ante nuevas ideas o procedimientos', Mapa: 52494, L: 5, R: 2, P: 4, A: 9, Me: 4, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 88, Competencia: 'Manejo de su imagen', Descriptor: 'Cuidar la imagen que proyecta', Mapa: 58614, L: 5, R: 8, P: 6, A: 1, Me: 4, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 89, Competencia: 'Automotivación', Descriptor: 'Emprender todas sus actividades con buen ánimo y alta motivación', Mapa: 66717, L: 6, R: 6, P: 7, A: 1, Me: 7, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 90, Competencia: 'Reacción ante la rutina', Descriptor: 'Actuar con rapidez para contrarrestar la rutina', Mapa: 95156, L: 9, R: 5, P: 1, A: 5, Me: 6, IdCategoria: 5, Categoria: 'CAPACIDAD CREATIVA  '},
{seleccionado: false, IdCompetencia: 91, Competencia: 'Intrepidez', Descriptor: 'Ser proactivo en aspectos y áreas de riesgo considerable', Mapa: 94349, L: 9, R: 4, P: 3, A: 4, Me: 9, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 92, Competencia: 'Habilidad manual', Descriptor: 'Efectuar operaciones manuales no complicadas', Mapa: 13791, L: 1, R: 3, P: 7, A: 9, Me: 1, IdCategoria: 11, Categoria: 'HABILIDADES MANUALES / OPERATIVAS  '},
{seleccionado: false, IdCompetencia: 93, Competencia: 'Seguridad al decidir', Descriptor: 'Defender sus decisiones en forma enérgica', Mapa: 95427, L: 9, R: 5, P: 4, A: 2, Me: 7, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 94, Competencia: 'Pasión por el cliente', Descriptor: 'Tener a los clientes como primera prioridad, antes que cualquier otra cosa.', Mapa: 31791, L: 3, R: 1, P: 7, A: 9, Me: 1, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 95, Competencia: 'Paciencia con el cliente', Descriptor: 'Esperar hasta que sea tiempo de que el cliente tome su decisión.', Mapa: 94525, L: 9, R: 4, P: 5, A: 2, Me: 5, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 96, Competencia: 'Previsión del mercado', Descriptor: 'Preparar el campo futuro de las ventas, construyendo una amplia plataforma de prospectos.', Mapa: 34946, L: 3, R: 4, P: 9, A: 4, Me: 6, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 97, Competencia: 'Confianza', Descriptor: 'Darle confianza al cliente', Mapa: 48718, L: 4, R: 8, P: 7, A: 1, Me: 8, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 98, Competencia: 'Coordinación con la autoridad', Descriptor: 'Preguntar a su supervisor ante cualquier duda que se presente', Mapa: 16499, L: 1, R: 6, P: 4, A: 9, Me: 9, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 99, Competencia: 'Organización de personas', Descriptor: 'Organizar óptimamente al personal.', Mapa: 97319, L: 9, R: 7, P: 3, A: 1, Me: 9, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 100, Competencia: 'Dedicación', Descriptor: 'Dedicarse por completo a las tareas que se le asignaron', Mapa: 52945, L: 5, R: 2, P: 9, A: 4, Me: 5, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 101, Competencia: 'Previsión', Descriptor: 'Prever acciones que le permitan evitar que los proyectos se detengan', Mapa: 94529, L: 9, R: 4, P: 5, A: 2, Me: 9, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 102, Competencia: 'Orden', Descriptor: 'Mantener orden estricto en archivos, inventarios, bases de datos o similares', Mapa: 42955, L: 4, R: 2, P: 9, A: 5, Me: 5, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 103, Competencia: 'Persistencia creativa', Descriptor: 'Persistir en resolver problemas manteniéndose congruente y mostrando ingenio', Mapa: 93629, L: 9, R: 3, P: 6, A: 2, Me: 9, IdCategoria: 5, Categoria: 'CAPACIDAD CREATIVA  '},
{seleccionado: false, IdCompetencia: 104, Competencia: 'Cooperación', Descriptor: 'Mostrar siempre actitud cooperativa', Mapa: 24953, L: 2, R: 4, P: 9, A: 5, Me: 3, IdCategoria: 15, Categoria: 'TRABAJO EN EQUIPO  '},
{seleccionado: false, IdCompetencia: 105, Competencia: 'Trato directo efectivo', Descriptor: 'Aumentar su efectividad si su trato con la gente es cara a cara', Mapa: 59516, L: 5, R: 9, P: 5, A: 1, Me: 6, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 106, Competencia: 'Sensatez', Descriptor: 'Demostrar sentido común y buen criterio en cualquier situación.', Mapa: 66719, L: 6, R: 6, P: 7, A: 1, Me: 9, IdCategoria: 12, Categoria: 'INTELIGENCIA EMOCIONAL  '},
{seleccionado: false, IdCompetencia: 107, Competencia: 'Paciencia', Descriptor: 'Estar habituado a mantener la calma con los demás.', Mapa: 35936, L: 3, R: 5, P: 9, A: 3, Me: 6, IdCategoria: 12, Categoria: 'INTELIGENCIA EMOCIONAL  '},
{seleccionado: false, IdCompetencia: 108, Competencia: 'Calma en su ritmo de trabajo', Descriptor: 'Mostrarse controlado y calmado en su forma de trabajar', Mapa: 61945, L: 6, R: 1, P: 9, A: 4, Me: 5, IdCategoria: 12, Categoria: 'INTELIGENCIA EMOCIONAL  '},
{seleccionado: false, IdCompetencia: 109, Competencia: 'Integración de información', Descriptor: 'Integrar y aprovechar piezas de información originadas en diversos lugares.', Mapa: 91829, L: 9, R: 1, P: 8, A: 2, Me: 9, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 110, Competencia: 'Responsabilidad', Descriptor: 'Manejar adecuadamente responsabilidades.', Mapa: 84719, L: 8, R: 4, P: 7, A: 1, Me: 9, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 111, Competencia: 'Ventas por correo', Descriptor: 'Vender por correo, siguiendo una secuencia establecida.', Mapa: 67615, L: 6, R: 7, P: 6, A: 1, Me: 5, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 112, Competencia: 'Atención a clientes', Descriptor: 'Dar servicio a clientes que conocen al detalle sus necesidades.', Mapa: 35933, L: 3, R: 5, P: 9, A: 3, Me: 3, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 113, Competencia: 'Aprovechamiento del tiempo', Descriptor: 'Siempre aprovechar al máximo el tiempo', Mapa: 59514, L: 5, R: 9, P: 5, A: 1, Me: 4, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 114, Competencia: 'Realismo', Descriptor: 'Mantener una perspectiva clara de los hechos, sin dar vuelo a la imaginación', Mapa: 15771, L: 1, R: 5, P: 7, A: 7, Me: 1, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 115, Competencia: 'Empatía', Descriptor: 'Capacidad de reconocer los sentimientos y emociones de otras personas', Mapa: 59512, L: 5, R: 9, P: 5, A: 1, Me: 2, IdCategoria: 12, Categoria: 'INTELIGENCIA EMOCIONAL  '},
{seleccionado: false, IdCompetencia: 116, Competencia: 'Aplicación de normas', Descriptor: 'Apegarse con exactitud a todas las normas y procedimientos', Mapa: 54295, L: 5, R: 4, P: 2, A: 9, Me: 5, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 117, Competencia: 'Tenacidad', Descriptor: 'Ser tenaz en realizar su trabajo lo mejor posible', Mapa: 61947, L: 6, R: 1, P: 9, A: 4, Me: 7, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 118, Competencia: 'Buen juicio', Descriptor: 'Ejercer habitualmente el sentido común', Mapa: 66719, L: 6, R: 6, P: 7, A: 1, Me: 9, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 119, Competencia: 'Mantenimiento de inventarios', Descriptor: 'Mantener actualizados conjuntos de objetos o datos', Mapa: 33954, L: 3, R: 3, P: 9, A: 5, Me: 4, IdCategoria: 4, Categoria: 'AUXILIARES / ADMINISTRATIVAS  '},
{seleccionado: false, IdCompetencia: 120, Competencia: 'Uso constructivo de presión', Descriptor: 'Presionar razonablemente cuando es necesario sacar el trabajo adelante', Mapa: 96326, L: 9, R: 6, P: 3, A: 2, Me: 6, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 121, Competencia: 'Efectividad de gestión', Descriptor: 'Saber administrar con eficiencia el esfuerzo del grupo ante fechas límite.', Mapa: 96327, L: 9, R: 6, P: 3, A: 2, Me: 7, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 122, Competencia: 'Negociación positiva', Descriptor: 'Lograr hábilmente acuerdos con los demás.', Mapa: 79226, L: 7, R: 9, P: 2, A: 2, Me: 6, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 123, Competencia: 'Competitividad', Descriptor: 'Convertir la venta en un juego en que gana el vendedor o el cliente.', Mapa: 96236, L: 9, R: 6, P: 2, A: 3, Me: 6, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 124, Competencia: 'Trabajo en equipo', Descriptor: 'Colaborar con efectividad en un grupo de trabajo', Mapa: 68516, L: 6, R: 8, P: 5, A: 1, Me: 6, IdCategoria: 15, Categoria: 'TRABAJO EN EQUIPO  '},
{seleccionado: false, IdCompetencia: 125, Competencia: 'Interés por la gente', Descriptor: 'Dedicar mucha de su energía a actividad social', Mapa: 59151, L: 5, R: 9, P: 1, A: 5, Me: 1, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 126, Competencia: 'Delegación', Descriptor: 'Delegar responsabilidades a los subordinados.', Mapa: 95428, L: 9, R: 5, P: 4, A: 2, Me: 8, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 127, Competencia: 'Recabar información documental', Descriptor: 'Llegar a descubrir piezas de información en sus materiales de origen', Mapa: 16581, L: 1, R: 6, P: 5, A: 8, Me: 1, IdCategoria: 4, Categoria: 'AUXILIARES / ADMINISTRATIVAS  '},
{seleccionado: false, IdCompetencia: 128, Competencia: 'Instrucción', Descriptor: 'Dar instrucción a personas o grupos', Mapa: 53936, L: 5, R: 3, P: 9, A: 3, Me: 6, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 129, Competencia: 'Manejo de crisis', Descriptor: 'Poder manejar situaciones críticas', Mapa: 96415, L: 9, R: 6, P: 4, A: 1, Me: 5, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 130, Competencia: 'Sensibilidad interpersonal', Descriptor: 'Mostrar sensibilidad y diplomacia al tratar con las personas', Mapa: 46917, L: 4, R: 6, P: 9, A: 1, Me: 7, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 131, Competencia: 'Hospitalidad', Descriptor: 'Ser un buen anfitrión de clientes internos o externos.', Mapa: 29545, L: 2, R: 9, P: 5, A: 4, Me: 5, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 132, Competencia: 'Autosuficiencia', Descriptor: 'Valerse a sí mismo para cumplir con éxito sus tareas', Mapa: 95517, L: 9, R: 5, P: 5, A: 1, Me: 7, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 133, Competencia: 'Uso positivo de la presión', Descriptor: 'Generar urgencia para que los demás avancen en su trabajo.', Mapa: 96326, L: 9, R: 6, P: 3, A: 2, Me: 6, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 134, Competencia: 'Pensamiento radical', Descriptor: 'Eliminar a la competencia.', Mapa: 96326, L: 9, R: 6, P: 3, A: 2, Me: 6, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 135, Competencia: 'Buen humor', Descriptor: 'Mostrarse generalmente de buen humor', Mapa: 29543, L: 2, R: 9, P: 5, A: 4, Me: 3, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 136, Competencia: 'Aplicación de experiencia a las ventas', Descriptor: 'Enriquecer lo aprendido sobre las ventas con ideas y acciones surgidas de su experiencia.', Mapa: 94347, L: 9, R: 4, P: 3, A: 4, Me: 7, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 137, Competencia: 'Hablar en público', Descriptor: 'Dar pláticas en público', Mapa: 59516, L: 5, R: 9, P: 5, A: 1, Me: 6, IdCategoria: 8, Categoria: 'COMUNICACIÓN  '},
{seleccionado: false, IdCompetencia: 138, Competencia: 'Perfeccionismo', Descriptor: 'Manejar su trabajo y el de sus colaboradores con gran minuciosidad', Mapa: 51777, L: 5, R: 1, P: 7, A: 7, Me: 7, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 139, Competencia: 'Movilidad', Descriptor: 'Poder movilizarse con rapidez', Mapa: 76166, L: 7, R: 6, P: 1, A: 6, Me: 6, IdCategoria: 11, Categoria: 'HABILIDADES MANUALES / OPERATIVAS  '},
{seleccionado: false, IdCompetencia: 140, Competencia: 'Altruismo', Descriptor: 'Entregarse por completo a resolver las necesidades de otra persona', Mapa: 16499, L: 1, R: 6, P: 4, A: 9, Me: 9, IdCategoria: 12, Categoria: 'INTELIGENCIA EMOCIONAL  '},
{seleccionado: false, IdCompetencia: 141, Competencia: 'Disponibilidad', Descriptor: 'Estar siempre disponible para realizar diversas tareas', Mapa: 77516, L: 7, R: 7, P: 5, A: 1, Me: 6, IdCategoria: 1, Categoria: 'ADAPTABILIDAD / FLEXIBILIDAD  '},
{seleccionado: false, IdCompetencia: 142, Competencia: 'Autoafirmación', Descriptor: 'Triunfar en discusiones y careos con los competidores', Mapa: 95428, L: 9, R: 5, P: 4, A: 2, Me: 8, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 143, Competencia: 'Búsqueda efectiva de guías', Descriptor: 'Buscar patrones de acción en situaciones inciertas o ambiguas', Mapa: 15593, L: 1, R: 5, P: 5, A: 9, Me: 3, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 144, Competencia: 'Habilidad visomotora', Descriptor: 'Realizar actividades que requieran coordinar la vista con los movimientos manuales', Mapa: 22792, L: 2, R: 2, P: 7, A: 9, Me: 2, IdCategoria: 11, Categoria: 'HABILIDADES MANUALES / OPERATIVAS  '},
{seleccionado: false, IdCompetencia: 145, Competencia: 'Concentración', Descriptor: 'Fijar su atención sobre pocos detalles durante largas horas', Mapa: 31884, L: 3, R: 1, P: 8, A: 8, Me: 4, IdCategoria: 3, Categoria: 'ATENCIÓN AL DETALLE  '},
{seleccionado: false, IdCompetencia: 146, Competencia: 'Comunicación clara', Descriptor: 'Se da a entender con toda claridad', Mapa: 87418, L: 8, R: 7, P: 4, A: 1, Me: 8, IdCategoria: 8, Categoria: 'COMUNICACIÓN  '},
{seleccionado: false, IdCompetencia: 147, Competencia: 'Seguimiento de reglas', Descriptor: 'Hacer su trabajo bajo reglas rígidamente establecidas', Mapa: 32694, L: 3, R: 2, P: 6, A: 9, Me: 4, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 148, Competencia: 'Entusiasmo', Descriptor: 'Dedicar todo su entusiasmo al cumplimiento de cada actividad', Mapa: 66717, L: 6, R: 6, P: 7, A: 1, Me: 7, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 149, Competencia: 'Manejo de información', Descriptor: 'Responder con información bien estructurada cuando el cliente pregunte.', Mapa: 81658, L: 8, R: 1, P: 6, A: 5, Me: 8, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 150, Competencia: 'Buenas relaciones', Descriptor: 'Trabajar armónicamente en un equipo de pocas personas', Mapa: 25945, L: 2, R: 5, P: 9, A: 4, Me: 5, IdCategoria: 15, Categoria: 'TRABAJO EN EQUIPO  '},
{seleccionado: false, IdCompetencia: 151, Competencia: 'Manejo de la presión en ventas', Descriptor: 'Luchar en forma ruda con otros proveedores de la competencia.', Mapa: 95244, L: 9, R: 5, P: 2, A: 4, Me: 4, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 152, Competencia: 'Sentido práctico', Descriptor: 'Ser muy concreto al sugerir aplicaciones del producto para cubrir necesidades de los clientes.', Mapa: 92547, L: 9, R: 2, P: 5, A: 4, Me: 7, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 153, Competencia: 'Promoción', Descriptor: 'Promover productos populares', Mapa: 88318, L: 8, R: 8, P: 3, A: 1, Me: 8, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 154, Competencia: 'Inspección de calidad', Descriptor: 'Inspeccionar calidad de trabajo administrativo o de producción', Mapa: 42956, L: 4, R: 2, P: 9, A: 5, Me: 6, IdCategoria: 3, Categoria: 'ATENCIÓN AL DETALLE  '},
{seleccionado: false, IdCompetencia: 155, Competencia: 'Fortaleza', Descriptor: 'Mostrar alto grado de ánimo ante cualquier eventualidad', Mapa: 76166, L: 7, R: 6, P: 1, A: 6, Me: 6, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 156, Competencia: 'Orientación a resultados', Descriptor: 'Vender servicios o productos recalcando los beneficios.', Mapa: 94527, L: 9, R: 4, P: 5, A: 2, Me: 7, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 157, Competencia: 'Efectividad en relaciones', Descriptor: 'Aplicar enérgicamente las habilidades interpersonales para vender.', Mapa: 59335, L: 5, R: 9, P: 3, A: 3, Me: 5, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 158, Competencia: 'Movilidad y adaptabilidad', Descriptor: 'Viajar con frecuencia', Mapa: 97137, L: 9, R: 7, P: 1, A: 3, Me: 7, IdCategoria: 1, Categoria: 'ADAPTABILIDAD / FLEXIBILIDAD  '},
{seleccionado: false, IdCompetencia: 159, Competencia: 'Investigación aplicada', Descriptor: 'Resolver problemas mediante nueva investigación y análisis', Mapa: 94529, L: 9, R: 4, P: 5, A: 2, Me: 9, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 160, Competencia: 'Presencia', Descriptor: 'Poder estar siempre presente en la acción cuando se le requiere', Mapa: 67164, L: 6, R: 7, P: 1, A: 6, Me: 4, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 161, Competencia: 'Pensamiento concreto', Descriptor: 'Orientar la entrevista de venta a resaltar datos concretos.', Mapa: 95428, L: 9, R: 5, P: 4, A: 2, Me: 8, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 162, Competencia: 'Asesoría a clientes', Descriptor: 'Asesorar a los clientes cuando ellos lo soliciten', Mapa: 24953, L: 2, R: 4, P: 9, A: 5, Me: 3, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 163, Competencia: 'Seguimiento de normas', Descriptor: 'Someter las relaciones sociales, aún con clientes, a normas establecidas.', Mapa: 36925, L: 3, R: 6, P: 9, A: 2, Me: 5, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 164, Competencia: 'Inventiva', Descriptor: 'Descubrir y crear objetos e ideas de gran novedad', Mapa: 95339, L: 9, R: 5, P: 3, A: 3, Me: 9, IdCategoria: 5, Categoria: 'CAPACIDAD CREATIVA  '},
{seleccionado: false, IdCompetencia: 165, Competencia: 'Manejo productivo del tiempo', Descriptor: 'Apoyar al cliente para que adquiera productos o servicios sin tener que apresurarse.', Mapa: 16943, L: 1, R: 6, P: 9, A: 4, Me: 3, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 166, Competencia: 'Coaching en ventas', Descriptor: 'Proporcionar asesoría y apoyo al cliente para que tome la mejor decisión de compra', Mapa: 33592, L: 3, R: 3, P: 5, A: 9, Me: 2, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 167, Competencia: 'Amabilidad y simpatía', Descriptor: 'Ser agradable y amable', Mapa: 16761, L: 1, R: 6, P: 7, A: 6, Me: 1, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 168, Competencia: 'Control', Descriptor: 'Reservar tiempo para revisar órdenes de venta.', Mapa: 48716, L: 4, R: 8, P: 7, A: 1, Me: 6, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 169, Competencia: 'Busqueda consistente de resultados', Descriptor: 'Perseguir consistentemente el cumplimiento de objetivos, actualizando planes de acción.', Mapa: 93629, L: 9, R: 3, P: 6, A: 2, Me: 9, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 170, Competencia: 'Objetividad', Descriptor: 'Ponderar con realismo y exactitud ventajas y desventajas de los clientes y prospectos.', Mapa: 94347, L: 9, R: 4, P: 3, A: 4, Me: 7, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 171, Competencia: 'Imagen de efectividad', Descriptor: 'Ser visto por la clientela como alguien bueno para resolver problemas.', Mapa: 87416, L: 8, R: 7, P: 4, A: 1, Me: 6, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 172, Competencia: 'Persistencia en ventas', Descriptor: 'Contactar en forma sistemática y constante con cada prospecto.', Mapa: 27924, L: 2, R: 7, P: 9, A: 2, Me: 4, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 173, Competencia: 'Seguimiento de rutinas', Descriptor: 'Apegarse a rutinas prediseñadas.', Mapa: 15771, L: 1, R: 5, P: 7, A: 7, Me: 1, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},
{seleccionado: false, IdCompetencia: 174, Competencia: 'Seguimiento a clientes', Descriptor: 'Mantener al día un catálogo de necesidades y cambios de los clientes.', Mapa: 93628, L: 9, R: 3, P: 6, A: 2, Me: 8, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 175, Competencia: 'Ambición y energía', Descriptor: 'Tener la fuerza necesaria para lograr vender en cualquier territorio.', Mapa: 94345, L: 9, R: 4, P: 3, A: 4, Me: 5, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 176, Competencia: 'Comunicación vertical', Descriptor: 'Comunicarse con eficacia hacia arriba y hacia abajo dentro de la organización.', Mapa: 85618, L: 8, R: 5, P: 6, A: 1, Me: 8, IdCategoria: 8, Categoria: 'COMUNICACIÓN  '},
{seleccionado: false, IdCompetencia: 177, Competencia: 'Venta de confianza', Descriptor: 'Enfatizar el impacto de su producto sobre la seguridad del cliente.', Mapa: 73919, L: 7, R: 3, P: 9, A: 1, Me: 9, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 178, Competencia: 'Cercanía con el cliente', Descriptor: 'Tomar las decisiones de compra junto con del cliente.', Mapa: 97313, L: 9, R: 7, P: 3, A: 1, Me: 3, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 179, Competencia: 'Seguridad en sí mismo', Descriptor: 'Mostrarse relajado y sereno en el momento de hacer nuevos contactos.', Mapa: 75717, L: 7, R: 5, P: 7, A: 1, Me: 7, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 180, Competencia: 'Manejo de emergencias', Descriptor: 'Manejar emergencias adecuadamente.', Mapa: 96419, L: 9, R: 6, P: 4, A: 1, Me: 9, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 181, Competencia: 'Efectividad verbal', Descriptor: 'Ser un gran conversador que ejemplifique y narre con impacto en quien le escucha', Mapa: 79137, L: 7, R: 9, P: 1, A: 3, Me: 7, IdCategoria: 8, Categoria: 'COMUNICACIÓN  '},
{seleccionado: false, IdCompetencia: 182, Competencia: 'Solución de problemas', Descriptor: 'Solucionar problemas críticos o repetitivos atendiendo pedidos específicos.', Mapa: 86518, L: 8, R: 6, P: 5, A: 1, Me: 8, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 183, Competencia: 'Tenacidad por el logro', Descriptor: 'Esforzarse incansablemente hasta el final, por lograr un objetivo.', Mapa: 52947, L: 5, R: 2, P: 9, A: 4, Me: 7, IdCategoria: 10, Categoria: 'FUERZA / ENERGÍA  '},
{seleccionado: false, IdCompetencia: 184, Competencia: 'Disciplina en el seguimiento', Descriptor: 'Dejarse llevar cómodamente por los planes de ventas.', Mapa: 71485, L: 7, R: 1, P: 4, A: 8, Me: 5, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 185, Competencia: 'Iniciativa y perseverancia', Descriptor: 'Hacer numerosas presentaciones de ventas para asegurar un buen volumen', Mapa: 87416, L: 8, R: 7, P: 4, A: 1, Me: 6, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 186, Competencia: 'Creatividad para ventas', Descriptor: 'Buscar tenazmente nuevas utilidades del producto que cubran las necesidades del cliente.', Mapa: 95336, L: 9, R: 5, P: 3, A: 3, Me: 6, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 187, Competencia: 'Lógica aplicada', Descriptor: 'Desempeñar funciones esencialmente lógicas', Mapa: 92459, L: 9, R: 2, P: 4, A: 5, Me: 9, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 188, Competencia: 'Aplicación de relaciones', Descriptor: 'Tender a que sus relaciones contribuyan a la calidad del trabajo', Mapa: 49346, L: 4, R: 9, P: 3, A: 4, Me: 6, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 189, Competencia: 'Reconocimiento del éxito', Descriptor: 'Reconocer con orgullo los logros de su grupo.', Mapa: 44937, L: 4, R: 4, P: 9, A: 3, Me: 7, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 190, Competencia: 'Reforzamiento de lo positivo', Descriptor: 'Felicitar a los demás por sus aciertos.', Mapa: 19735, L: 1, R: 9, P: 7, A: 3, Me: 5, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 191, Competencia: 'Promoción del cambio', Descriptor: 'Mostrar inconformidad ante situaciones poco cambiantes.', Mapa: 96146, L: 9, R: 6, P: 1, A: 4, Me: 6, IdCategoria: 1, Categoria: 'ADAPTABILIDAD / FLEXIBILIDAD  '},
{seleccionado: false, IdCompetencia: 192, Competencia: 'Control de procesos', Descriptor: 'No dar cabida a precipitaciones en acciones de servicio ni de ventas.', Mapa: 32965, L: 3, R: 2, P: 9, A: 6, Me: 5, IdCategoria: 16, Categoria: 'VENTA CONSULTIVA  '},
{seleccionado: false, IdCompetencia: 193, Competencia: 'Argumentación creativa', Descriptor: 'Saber apoyar sus puntos de vista con buenos ejemplos y anécdotas brillantes', Mapa: 49257, L: 4, R: 9, P: 2, A: 5, Me: 7, IdCategoria: 5, Categoria: 'CAPACIDAD CREATIVA  '},
{seleccionado: false, IdCompetencia: 194, Competencia: 'Operación mecánica', Descriptor: 'Operar equipo mecánico', Mapa: 22792, L: 2, R: 2, P: 7, A: 9, Me: 2, IdCategoria: 11, Categoria: 'HABILIDADES MANUALES / OPERATIVAS  '},
{seleccionado: false, IdCompetencia: 195, Competencia: 'Adecuación al ritmo del cliente', Descriptor: 'Permitir a los clientes el tiempo necesario para decidir, sin presionar.', Mapa: 16854, L: 1, R: 6, P: 8, A: 5, Me: 4, IdCategoria: 2, Categoria: 'ATENCIÓN A CLIENTE  '},
{seleccionado: false, IdCompetencia: 196, Competencia: 'Precisión', Descriptor: 'Lograr exactitud en trabajo que exige mucho detalle', Mapa: 52499, L: 5, R: 2, P: 4, A: 9, Me: 9, IdCategoria: 3, Categoria: 'ATENCIÓN AL DETALLE  '},
{seleccionado: false, IdCompetencia: 197, Competencia: 'Ecuanimidad', Descriptor: 'Mostrar tranquilidad y paciencia aún en emergencias', Mapa: 34944, L: 3, R: 4, P: 9, A: 4, Me: 4, IdCategoria: 12, Categoria: 'INTELIGENCIA EMOCIONAL  '},
{seleccionado: false, IdCompetencia: 198, Competencia: 'Atención visual y auditiva', Descriptor: 'Poner mucha atención al obtener información telefónica o por medios electrónicos', Mapa: 51595, L: 5, R: 1, P: 5, A: 9, Me: 5, IdCategoria: 3, Categoria: 'ATENCIÓN AL DETALLE  '},
{seleccionado: false, IdCompetencia: 199, Competencia: 'Retroalimentación', Descriptor: 'Preocuparse por informar a los demás sobre fallas y aciertos', Mapa: 94526, L: 9, R: 4, P: 5, A: 2, Me: 6, IdCategoria: 6, Categoria: 'COMPETENCIAS DEL LÍDER  '},
{seleccionado: false, IdCompetencia: 200, Competencia: 'Uso de medios de promoción', Descriptor: 'Utilizar folletería y catálogos para generar negocio.', Mapa: 67614, L: 6, R: 7, P: 6, A: 1, Me: 4, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 201, Competencia: 'Diligencia', Descriptor: 'Lograr que la acción fluya con rapidez', Mapa: 95516, L: 9, R: 5, P: 5, A: 1, Me: 6, IdCategoria: 9, Categoria: 'EFICACIA  '},
{seleccionado: false, IdCompetencia: 202, Competencia: 'Facilidad para relacionarse', Descriptor: 'Contactar y relacionarse fácilmente con la gente', Mapa: 68516, L: 6, R: 8, P: 5, A: 1, Me: 6, IdCategoria: 7, Categoria: 'COMPETENCIAS SOCIALES  '},
{seleccionado: false, IdCompetencia: 203, Competencia: 'Competencia numérica', Descriptor: 'Habilidad para manejar números y relaciones matemáticas', Mapa: 65817, L: 6, R: 5, P: 8, A: 1, Me: 7, IdCategoria: 13, Categoria: 'RAZONAMIENTO  '},
{seleccionado: false, IdCompetencia: 204, Competencia: 'Resistencia a la presión', Descriptor: 'Realizar presentaciones efectivas de venta bajo fuerte presión.', Mapa: 95424, L: 9, R: 5, P: 4, A: 2, Me: 4, IdCategoria: 17, Categoria: 'VENTA DIRECTA  '},
{seleccionado: false, IdCompetencia: 205, Competencia: 'Seguimiento de formatos', Descriptor: 'Elaborar reportes en formatos establecidos.', Mapa: 34944, L: 3, R: 4, P: 9, A: 4, Me: 4, IdCategoria: 14, Categoria: 'RESPONSABILIDAD / DISCIPLINA  '},

  ];

@Component({
    selector: 'app-config-jobprofiles',
    templateUrl: './config-jobprofiles.component.html',
    styleUrls: ['./config-jobprofiles.component.css']
})

export class ConfigJobProfilesComponent implements OnInit 
{
    constructor(breakpointObserver: BreakpointObserver) {
        breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
          this.displayedColumns = result.matches ? 
            ['seleccionado', 'IdCompetencia', 'Categoria', 'Competencia', 'Descriptor'] : 
            ['seleccionado', 'IdCompetencia', 'Categoria', 'Competencia', 'Descriptor'];
        });
      }

    displayedColumns: string[] = ['seleccionado', 'IdCompetencia', 'Categoria', 'Competencia', 'Descriptor'];
    dataSource = ELEMENT_DATA;
    chartMapa : Chart;
    selectedData = this.dataSource.filter(f => f.seleccionado === true);

    ngAfterViewInit(): void {
        
        this.chartMapa = new Chart({
            chart: { polar: true, type: 'area' },
            credits: { enabled: false },
            exporting: { enabled: false },
            // leyend: { enabled: false },
            pane: { size: '100%' },
            title: { text: '' },
            xAxis: { categories:['Espíritu de Lucha', 'Relación', 'Pausa', 'Adaptabilidad'], tickmarkPlacement: 'on', gridLineWidth: 1, gridLineColor: '#123FFF', lineColor : '#123FFF' },
            yAxis: { gridLineInterpolation: 'circle', lineWidth: 1, min: 0, max: 9, tickInterval: 1, gridLineColor: '#123FFF' },
            series: [{ name: 'MAPA DEL PUESTO', type: undefined, data:[ 8,4,5,3 ], color: '#a395bb' }]
          });
        
          

    }

    ngOnInit(): void 
    {
        
    }

    applyFilter(filterValue: string) {

        console.log(filterValue);

        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource = filterValue == "" ? ELEMENT_DATA : ELEMENT_DATA.filter(f => f.Categoria.toLowerCase().indexOf(filterValue) > -1 || f.Competencia.toLowerCase().indexOf(filterValue) > -1 || f.Descriptor.toLowerCase().indexOf(filterValue) > -1);

        

        //this.dataSource = filterValue == "" ? ELEMENT_DATA : (ELEMENT_DATA.filter(f => f.Categoria.toLowerCase().indexOf(filterValue) > -1).concat(ELEMENT_DATA.filter(f => f.Competencia.toLowerCase().indexOf(filterValue) > 0)).concat(ELEMENT_DATA.filter(f => f.Descriptor.toLowerCase().indexOf(filterValue) > 0)));
      }
    
      NumSeleccionados(row? : CompetenceElement, evento? : any) {

        var minSelected = 7;
        var maxSelected = 15;

        if (this.selectedData.length < maxSelected)
            row.seleccionado = evento.checked;
        else
        {
            row.seleccionado = false;
            evento.source.checked = false;
        }

        this.selectedData = this.dataSource.filter(f => f.seleccionado === true);

        console.log(this.selectedData);
    }
}