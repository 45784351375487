import { GroupQuestionsComponent } from './../modals/group-questions/group-questions.component';
import { GroupHandlersComponent } from './../modals/group-handlers/group-handlers.component';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-group-addons',
  templateUrl: './group-addons.component.html',
  styleUrls: ['./group-addons.component.css']
})
export class GroupAddonsComponent implements OnInit {

  masterGroups = [];
  length =  0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 15];

  constructor(private conector: ConectorJsonService, public dialog: MatDialog) {

  }

  async ngOnInit() {
    this.conector.groups_mix_get().subscribe( (res: any) => {
      const { data, msg }  = res;
      this.length = msg;
      this.masterGroups = data;
    });
  }

  openHandlers(row): void {

    localStorage.setItem('handlers', JSON.stringify(row));

    const dialogRef = this.dialog.open(GroupHandlersComponent , {
      data: { row }
    });

    dialogRef.afterClosed().subscribe(res => {
      console.info('&modal&', res);
    });
  }

  openQuestions(row): void {

    localStorage.setItem('questions', JSON.stringify(row));

    const dialogRef = this.dialog.open(GroupQuestionsComponent , {
      data: { row }
    });

    dialogRef.afterClosed().subscribe(res => {
      console.info('&modal&', res);
    });
  }

}
