import Swal from 'sweetalert2';
import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ConnectorJobProfileService } from "../../../../services/job-profile.service";
import { FormBuilder, FormArray, FormControl, FormGroup } from "@angular/forms";
import { Options } from "@angular-slider/ngx-slider";

@Component({
    selector: 'app-configurate-jobprofile',
    templateUrl: './configurate-jobprofile.component.html',
    styleUrls: ['./configurate-jobprofile.component.scss']
})

export class ConfigurateJobProfileComponent implements OnInit {

    isLinear = false;
    configureJobProfileGroup: FormGroup;
    displayedColumns: string[] = [ 'seleccionado', 'dimension', 'peso', 'limites' ];
    weigthList = [ 0, 1, 2, 3, 4, 5, 6 ];
    dataSource : Dimension[];
    puesto : string;
    descripcion : string;
    idJobProfile : number;


    value: number = 40;
    highValue: number = 60;
    options: Options = {
      floor: 0,
      ceil: 100,
      step: 1,
      showTicks: true,
      showSelectionBar: false,
      //getSelectionBarColor: (value: number): string => { return 'grey' },
      getTickColor: (value: number): string => {
        if (value < 30) {
          return 'red';
        }
        if (value < 60) {
          return 'orange';
        }
        if (value < 90) {
          return 'yellow';
        }
        return '#2AE02A';
      }
    };


    constructor(
        private conector: ConnectorJobProfileService, 
        private form: FormBuilder, 
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) {

            this.puesto = data.puesto;
            this.descripcion = data.descripcion;
            this.idJobProfile = data.id;

            this.configureJobProfileGroup = this.form.group({
                dimentions : this.dataSource,
                idJobProfile : data.id
            });

            console.log(this.configureJobProfileGroup["dimentions"]);
         }

    createFormArray(): FormArray {
        return new FormArray(this.dataSource.map(item => new FormGroup({
             seleccionado: new FormControl(item.seleccionado),
             peso: new FormControl(item.peso),
             linferior: new FormControl(item.linferior),
             lsuperior: new FormControl(item.lsuperior)
        }))); 
    }

    ngOnInit(): void {
        
        this.conector.dimentions_get(this.idJobProfile).subscribe( (response: any) => {
            const {data, msg} = response;
            // this.configureJobProfileGroup = data;
            this.dataSource = data as Dimension[];

            console.log(this.dataSource);
          });
    }

    NumSeleccionados(row? : Dimension, evento? : any) {

        var dimensionesHonestidad = 5;
        var dimensionesIncidencia = 4;

        var maxSelected = row.dimensionH === 1 ? dimensionesHonestidad : dimensionesIncidencia;

        var seleccionados = this.dataSource.filter(dimention => dimention.dimensionH === row.dimensionH && dimention.seleccionado).length;

        if (seleccionados < maxSelected)
            row.seleccionado = evento.checked;
        else
        {
            row.seleccionado = false;
            evento.source.checked = false;
        }
    }
    
    submit(formdata) {

        formdata.dimentions = this.dataSource.filter(f=> f.seleccionado);
        console.log(formdata);

        this.conector.dimentions_post(formdata).subscribe( (d: any) => {
            const { ok, msg, data} = d;
            if (ok === true) {
              Swal.fire({
                title: 'Puesto configurado correctamente.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                reverseButtons: true
              }).then((result) => {
                this.dialog.closeAll();
              });
            } else {
              Swal.fire('Error', msg, 'error');
            }
          });
      }
    
}

export interface Dimension {
    id: number;
    dimension: string;
    orden: number;
    editable: boolean;
    dimensionH: number;
    seleccionado: boolean;
    peso: number;
    linferior: number;
    lsuperior: number;
}