import { ConectorJsonService } from './../../../services/conector-json.service';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../../services/authentication.service';
import { SharedCommunicationService } from './../../../services/shared-communication.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../manager/manager.component.css']
})

export class AppHeaderComponent {

  public config: PerfectScrollbarConfigInterface = {};

  @Input() username: any;
  @Input() lang:string = 'en-US';

  constructor(private sharedService: SharedCommunicationService,  private auth: AuthenticationService, private router: Router, private conector: ConectorJsonService) { }

  setEnterprise($event) {
    this.sharedService.isVisibleSource.next($event);
  }

  // This is for Notifications
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Launch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];


  async setLang(defLng: string) {

    localStorage.setItem('user_lng', defLng);

    await this.conector.postLanguage({ 'language': defLng }).toPromise();

    this.sharedService.reloadSite.next({ "status": true, "route": this.router.url });

  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl("/");
  }

}
