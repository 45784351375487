import {HttpClient} from '@angular/common/http';
import {environment} from './../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CoppelApiService {

  constructor(private http: HttpClient) {
  }

  /* Auth */

  login() {
    return this.http.post(environment.coppel_auth, { "appId": environment.coppel_appId, "appKey": environment.coppel_appKey });
  }

  empresas(token) {
    return fetch(environment.coppel_api + "/empresas", {
      method: 'GET',
      headers: new Headers({"Authorization":  token}),
      redirect: 'follow'
    }).then(response => response.json());
  }

  paises(token) {
    return fetch(environment.coppel_api + "/paises", {
      method: 'GET',
      headers: new Headers({"Authorization": token}),
      redirect: 'follow'
    }).then(response => response.json());
  }

  regiones(token) {
    return fetch(environment.coppel_api + "/regiones", {
      method: 'GET',
      headers: new Headers({"Authorization": token}),
      redirect: 'follow'
    }).then(response => response.json());
  }

  estados(token, idPais) {
    return fetch(environment.coppel_api + "/estados", {
      method: 'POST',
      headers: new Headers({"Authorization": token, 'Content-Type': 'application/json'}),
      body: JSON.stringify({idPais})
    }).then(response => response.json());
  }

  municipios(token, idEstado) {
    return fetch(environment.coppel_api + "/municipios", {
      method: 'POST',
      headers: new Headers({"Authorization":  token, 'Content-Type': 'application/json'}),
      body: JSON.stringify({idEstado})
    }).then(response => response.json());
  }

  categorias(token){
    console.info(environment.coppel_api +"/categorias");

    return fetch(environment.coppel_api +"/categorias", {
      method: 'GET',
      headers: new Headers({"Authorization":  token}),
      redirect: 'follow'
    }).then(response => response.json());
  }

  subcategorias(token, idCategoria) {
    return fetch(environment.coppel_api +"/subcategorias", {
      method: 'POST',
      headers: new Headers({"Authorization":  token, 'Content-Type': 'application/json'}),
      body: JSON.stringify({idCategoria})
    }).then(response => response.json());
  }

  submit_report(data, token) {
    return fetch(environment.coppel_api +"/detalleDenuncia", {
      method: 'POST',
      headers: new Headers({"Authorization":  token, 'Content-Type': 'application/json'}),
      body: JSON.stringify(data)
    }).then(response => response.json());
  }

  send_files(data, token) {
    return fetch(environment.coppel_api +"/anexosDenuncia", {
      method: 'POST',
      headers: new Headers({"Authorization":  token, 'Content-Type': 'application/json'}),
      body: JSON.stringify(data)
    }).then(response => response.json());
  }

  send_report_file(data, token) {
    return fetch(environment.coppel_api +"/anexosDenuncia", {
      method: 'POST',
      headers: new Headers({"Authorization":  token, 'Content-Type': 'application/json'}),
      body: JSON.stringify(data)
    }).then(response => response.json());
  }

}
