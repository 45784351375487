import { OnInit, Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConnectorJobProfileService } from "../../../../services/job-profile.service";
import { GenericCatalogsService } from "../../../../services/GenericCatalogs.service";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import Swal from "sweetalert2";

@Component({
  selector: 'app-create-jobprofile',
  templateUrl: './create-jobprofile.component.html',
  styleUrls: ['./create-jobprofile.component.css']
})
export class CreateJobProfileComponent implements OnInit {

  jobProfileForm: FormGroup;
  jobProfileList = [];
  newForm = false;

  constructor(
    private conector: ConnectorJobProfileService,
    private form: FormBuilder,
    private generic: GenericCatalogsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    this.jobProfileForm = this.form.group({
      id: [this.data.id],
      puesto: [this.data.puesto, Validators.required],
      level: [this.data.level, Validators.required],
      descripcion: [this.data.descripcion],
      analisisriesgo: [this.data.analisisRiesgo],
      token:[this.data.token]
    });

    this.newForm = (this.data.id == null) ? true : false;

    this.generic.ct_NivelPuestos().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.jobProfileList = data;
    });

  }

  submit(formdata) {
    if (this.data.id) {
      this.conector.jobprofile_put(formdata).subscribe((d: any) => {
        const { ok, msg, data } = d;
        if (ok == true) {
          Swal.fire({
            title: 'Puesto actualizado.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: ' Aceptar '
          }).then((result) => this.dialog.closeAll());
        } else {
          Swal.fire('Error', msg, 'error');
        }
      });
    }
    else {
      formdata.id = 0;
      formdata.analisisriesgo = false;
      this.conector.jobprofile_post(formdata).subscribe((d: any) => {
        const { ok, msg, data } = d;
        if (ok == true) {
          Swal.fire({
            title: 'Puesto registrado.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: ' Aceptar '
          }).then((result) => this.dialog.closeAll());
        } else {
          Swal.fire('Error', msg, 'error');
        }
      });
    }

  }

}