import Swal from 'sweetalert2';
import { ResponseHandler } from './../../../interfaces/ResponseHandler';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ConectorJsonService } from './../../../services/conector-json.service';
import { Component, OnInit } from '@angular/core';
import { GenericCatalogsService } from '../../../services/GenericCatalogs.service';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {

  profileFrm: FormGroup;
  webdata: any = {};
  countries = [];
  genres = [];
  languages = [];
  seniorityRangeList = [];
  typeContractRange = [];
  ageRangeList = [];

  constructor(
    private conector: ConectorJsonService,
    private conectorgeneric: GenericCatalogsService,
    private router: Router,
    private fb: FormBuilder) {

    this.profileFrm = this.fb.group({
      birthday: [null],
      country: [null],
      email: [null],
      firstname: [null, Validators.required],
      genre: [null, Validators.required],
      language: [null],
      lastname: [null],
      name: [null, Validators.required],
      nickname: [null, Validators.required],
      phone: [null],
      seniorityRange: [null, Validators.required],
      typeContract: [null, Validators.required],
      ageRange: [null, Validators.required],
    })
  }

  ngOnInit() {

    const data = localStorage.getItem('webdata');

    this.conectorgeneric.ct_SeniorityRange().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.seniorityRangeList = data;
    });

    this.conectorgeneric.ct_TypeContract().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.typeContractRange = data;
    });

    this.conectorgeneric.ct_AgeRange().subscribe((response: any) => {
      const { ok, data, msg } = response;
      this.ageRangeList = data;
    });

    this.conector.getEvaluation_profile().subscribe((res: ResponseHandler) => {

      this.webdata = res.data;

      this.countries = this.webdata.complements.countries;
      this.genres = this.webdata.complements.genres;
      this.languages = this.webdata.complements.languages;

      console.log(this.webdata);

      Object.keys(this.profileFrm.value).forEach(item => {
        if (this.webdata[item]) {
          this.profileFrm.get(item).setValue(this.webdata[item]);
        }
      });

      if (this.profileFrm.get("birthday").value == "0001-01-01T00:00:00") {

        const today = new Date();
        const month = today.getMonth();
        const year = today.getFullYear();
        const day = today.getDay();

        this.profileFrm.get("birthday").setValue(new Date(year, month, day));
      }

      this.profileFrm.get("nickname").disable();

      this.profileFrm.updateValueAndValidity();

    });

  }

  update(formdata) {

    Swal.fire({
      icon: 'warning',
      title: 'Confirmación',
      text: '¿Los datos ingresados son correctos?',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Quiero verificar'
    }).then((result: any) => {

      if (result.isConfirmed == false) { return }

      this.conector.putEvaluation_profile(formdata).subscribe((res: ResponseHandler) => {

        if (res.ok == false) {
          return Swal.fire('Error', res.msg, 'error');
        };

        this.router.navigateByUrl('/evaluations/survey');

      })

    });

  }

}
