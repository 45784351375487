import { EvaInstructionsGeneralComponent } from './../../views/evaluations/survey/eva-instructions-general/eva-instructions-general.component';
import { EvaInstructionsComponent } from './../../views/evaluations/survey/eva-instructions/eva-instructions.component';
import { ApplyComponent } from './../../views/evaluations/survey/apply/apply.component';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WebcamModule} from 'ngx-webcam';
import { EvaluationsRoutingModule } from './evaluations-routing.module';
import { FlexModule } from '@angular/flex-layout';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { PickersModule } from '../../views/shared/pickerLoader.module';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MatTreeModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CKEditorModule } from 'ckeditor4-angular';
import { IconPickerModule } from 'ngx-icon-picker';
import { DemoMaterialModule } from '../../material-module';
import { TycosComponent } from '../../views/evaluations/tycos/tycos.component';
import { ProfileComponent } from '../../views/evaluations/profile/profile.component';
import { SurveyComponent } from '../../views/evaluations/survey/survey.component';
import { QuestionsComponent } from '../../views/evaluations/survey/questions/questions.component';
import { AngularCountdownTimerModule } from 'angular8-countdown-timer';

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-blue',
    dateInputFormat: 'YYYY-MM-DD'
  });
}

@NgModule({

  declarations: [
    TycosComponent,
    ProfileComponent,
    SurveyComponent,
    ApplyComponent,
    QuestionsComponent,
    EvaInstructionsComponent,
    EvaInstructionsGeneralComponent,
  ],

  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    EvaluationsRoutingModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    DemoMaterialModule,
    MatTreeModule,
    SharedPipesModule,
    CKEditorModule,
    IconPickerModule,
    HttpModule,
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    }),
    FlexModule,
    FlexModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MaterialFileInputModule,
    PickersModule,
    SharedPipesModule,
    NgxDropzoneModule,
    AngularCountdownTimerModule,
    WebcamModule
  ],

  providers: [{ provide: BsDatepickerConfig, useFactory: getDatepickerConfig }]

})

export class EvaluationsModule { }
