import { SharedCommunicationService } from './../../../services/shared-communication.service';
import { ResponseKnow } from './../../../interfaces/ResponseHandler';
import { ConectorKnowService } from './../../../services/conector-know.service';
import { ConectorJsonService } from '../../../services/conector-json.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configuracion-categorias',
  templateUrl: './configuracion-categorias.component.html',
  styleUrls: ['./configuracion-categorias.component.css']
})


export class ConfiguracionCategoriasComponent implements OnInit {

  catForm: FormGroup;
  isEditable = false;
  isNewRequest = false;
  showRecords = true;
  isTitle = 'Crear nueva ';
  areas = [];

  categoryForm: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private know: ConectorKnowService,
    private conector: ConectorJsonService,
    private snap: ActivatedRoute,
    private shared: SharedCommunicationService) {
    this.catForm = this.fb.group({
      "type_id": [null, Validators.required] ,
      "parent_id": [0],
      "name" : [null, Validators.required],
      "sort" : [0, Validators.required],
      "iconCss": ['fa fa-edit', [Validators.required]]
    });

  }

  ngOnInit() {

    this.snap.params.subscribe( (ks: Params) => {

      if (ks.id == null) {

        this.showRecords = true;

        this.know.areas_structure().subscribe( (res: ResponseKnow) => {

          this.areas = res.structure;

          const parseNode = [];

          res.structure.forEach( x => {

            const model = {
              name: x.name,
              icon: x.icon,
              subs: []
            };

            x.categories.forEach( i => {

              const alter = {
                name: i.name,
                icon: i.icon,
                subs: []
              };

              model.subs.push(alter);

            });

            parseNode.push(model);

          });

          this.areas = parseNode;

        });

      } else {

        this.know.areas_list().subscribe( (res: any) => {

          console.info('ASD', res);

          this.areas = res.structure;

          res.structure.forEach( x => {

            console.info(x);

          })

        });

        this.showRecords = false;

        this.isNewRequest = true;

        if ( Number.isInteger(ks.id) ) {

          this.isEditable = true;

          this.isTitle = 'Editar';


        }

      }

    })

  }

  onIconPickerSelect(icon: string): void {
    console.info('pci', icon);
    this.catForm.get('iconCss').setValue(icon);
  }

  create(ev) {

    const formdata = new FormData();

    Object.keys(ev).forEach( item => {
      formdata.append( item, ev[item]);
    })

    formdata.append("company_id", this.shared.KnowSesion());

    formdata.append("icon", (ev.iconCss).replace("fa ", "") );

    this.know.areas_post(formdata).subscribe( (res: ResponseKnow) => {

      if(res.success == true) {

        Swal.fire('Completado', res.message, 'success').then( () => this.router.navigateByUrl('/manager/configuracion-categorias') );

      } else {
        Swal.fire('Error', res.message, 'error');
      }

    });

  }
}
