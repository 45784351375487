import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GenericCatalogsService {

    constructor(private http: HttpClient) { }

    ct_SiNo() {
      return this.http.get(`${environment.server}/genericcatalogs/getcatsino`); 
    }

    ct_LengTest() { 
      return this.http.get(`${environment.server}/genericcatalogs/getlengtest`); 
    }

    ct_Countries() { 
      return this.http.get(`${environment.server}/genericcatalogs/getcountries`); 
    }

    ct_Nationalities() { 
      return this.http.get(`${environment.server}/genericcatalogs/getnationalities`); 
    }

    ct_Genres() { 
      return this.http.get(`${environment.server}/genericcatalogs/getgenres`); 
    }

    ct_NivelPuestos() { 
      return this.http.get(`${environment.server}/genericcatalogs/getjoblevels`); 
    }

    ct_SeniorityRange() { 
      return this.http.get(`${environment.server}/genericcatalogs/getseniorityrange`); 
    }

    ct_TypeContract() { 
      return this.http.get(`${environment.server}/genericcatalogs/gettypecontract`); 
    }

    ct_AgeRange() { 
      return this.http.get(`${environment.server}/genericcatalogs/getagerange`); 
    }
    
    ct_Groups() {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': localStorage.getItem('token')
      });
      return this.http.get(`${environment.server}/groups/all`, { headers });
    }
}

export class Catalog  {

  Id : number;
  Description : string;
  Value : boolean;
}