import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-survey-builder',
  templateUrl: './survey-builder.component.html',
  styleUrls: ['./survey-builder.component.css']
})
export class SurveyBuilderComponent implements OnInit {

  @Input() base = { };
  @Input() sections = [];
  @Input() questions = [];
  @Input() answers = [];
  @Input() questionTypes = [];

  @Output() getQuestions: EventEmitter<any> = new EventEmitter();

  @Output() createSection: EventEmitter<any> = new EventEmitter();
  @Output() createQuestion : EventEmitter<any> = new EventEmitter();

  @Output() openAnswers  : EventEmitter<any> = new EventEmitter();
  @Output() destroyQuestion: EventEmitter<any> = new EventEmitter();
  @Output() deleteSection: EventEmitter<any> = new EventEmitter();
  @Output() updateQuestion: EventEmitter<any> = new EventEmitter();
  @Output() updateCurrentSection: EventEmitter<any> = new EventEmitter();

  basection:any = {};
  sectionFrm: FormGroup;
  questionFrm: FormGroup;

  constructor(private fb: FormBuilder) {

    this.sectionFrm = this.fb.group({
      "Name": [null, Validators.required],
      "Instructions": [null, Validators.required]
    });

    this.questionFrm = this.fb.group({
      "Name": [null, Validators.required],
      "Type": [null, Validators.required],
      "IdSrs":[null]
    })

  }

  ngOnInit() {

  }

  toQuestions(row) {
    this.basection = row;
    this.getQuestions.emit(row);
  }


  addSection(formdata) {
    this.createSection.emit(formdata);
    this.sectionFrm.reset();
  }

  addQuestion(formdata) {

    formdata.IdSrs = this.basection.id;
    formdata.Directionality = "Neutral";

    this.createQuestion.emit(formdata);
    this.questionFrm.reset();

  }

  deleteQuestion(row) {
    this.destroyQuestion.emit(row);
  }

  dSection(row) {
    this.deleteSection.emit(row);
  }

  checkAnswers(row) {
    this.openAnswers.emit(row);
  }

  updateFields(row) {
    this.updateQuestion.emit(row);
  }

  updateSection(row){
    this.updateCurrentSection.emit(row);
  }

}
