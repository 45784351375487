import { SharedCommunicationService } from './../../../services/shared-communication.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './../../../services/authentication.service';

import { ConectorJsonService } from '../../../services/conector-json.service';
import { Pagination } from '../../../models/pagination';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatTreeNestedDataSource } from '@angular/material';
import { NestedTreeControl } from '@angular/cdk/tree';
import { sortAscendingPriority } from '@angular/flex-layout';

interface PerNode {
  id: number;
  node: string;
  checked: boolean;
  items?: PerNode[];
}

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent implements OnInit {

  user = { id: 0, firstname: '' };
  rows: any = [];
  showLoader = true;
  pagination = new Pagination();

  master = new MatTreeNestedDataSource<PerNode>();
  treeControl = new NestedTreeControl<PerNode>(node => node.items);

  usermodules: any = [];

  gridPermissions = {
    Integriline: [],
    Honestidad: [],
    Survey: [],
    Learning: [],
    Background: [],
    Comunika: []
  };

  constructor(
    private shared: SharedCommunicationService,
    private conector: ConectorJsonService,
    private auth: AuthenticationService,
    public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.auth.setLanguage().then(k => {
      this.translate.setDefaultLang(k);
    });
  }

  async ngOnInit() {

    this.user = JSON.parse( localStorage.getItem('userPermission') );

    const { ok, data }: any = await this.conector.modules_by_user().toPromise();

    this.usermodules = data;

    this.loadPermissions(this.usermodules[0].nickname);

  }

  async loadPermissions(modulename) {

    this.showLoader = true;

    this.conector.modules_user({id: this.user.id, module: modulename }).subscribe( (k: any) => {

      let { ok, data, msg} = k;

      this.showLoader = false;

      if  ( ok === true) {

        this.gridPermissions[modulename] = [];

        if( modulename == 'Integriline' ) {

          const filterdata = [];

          data.forEach( item => {
            if( item.application !== 'Tickets') {
              filterdata.push(item);
            }
          });

          data = filterdata;

        }

        this.gridPermissions[modulename] = data;

      }

    });
  }

  setPermision(row, $event) {

    const model = {
      id: this.user.id,
      application: row.id,
      enabled: row.enabled
    };

    console.info('Send', model);

    this.conector.modules_update(model).subscribe( (k: any) => {

      console.info('AU', k);

    });

  }

  check_integrity(origin, destiny) {

    origin.forEach( (row: any) => {

      const fx = destiny.find( x => x.node === row.node);

      if (fx === undefined) {

        destiny.push(row);

      } else {

        if ( fx.hasOwnProperty('items') ) {

          this.check_integrity(row.items, fx.items);

        } else {

          fx.push(row.items);

        }

      }

    });

  }

  async todoItemSelectionToggle(row: any, $event: any) {

    const { checked, source } = $event;

    this.nestedStatus(row, checked);

    const model = {
      userid: this.user.id,
      application: JSON.stringify(this.master)
    };

    await this.conector.modules_update(model).toPromise();
  }

  private nestedStatus( row: PerNode, value: boolean) {
    row.checked = value;
    row.items.forEach( x => this.nestedStatus(x, value));
  }

  onTabChange(evento) {

    console.info("@permission.ontabchange", evento);

    const { index, tab} = evento;

    const labels = [
      'Honestidad',
      'Survey',
      'Integriline',
      'Learning',
      'Background',
      'Comunika'
    ];

    this.loadPermissions(labels[index]);

  }

}
